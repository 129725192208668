import React, {useEffect, useState} from 'react';
import {
    chatonlyActions,
} from "../../appRedux/Actions";
import {connect} from "react-redux";
import moment from "moment";
import {uuid} from "uuidv4";
import {Image} from 'antd';
import {config} from "../../appRedux/Config";

function ChatOnlyComponent(props) {
    let [messageList, setMessageList] = useState([])
    const {roomId} = props
    const roomChanges = props.roomMessages.get(roomId)
    let messages = [...(props.roomMessages.get(roomId) ?? [])]

    useEffect(() => {
        if (isValidRoom(roomId)) {
            scrollChatToBottom()
        }
    }, [])

    useEffect(scrollChatToBottom, [messages]);

    useEffect(() => {
        setMessageList([...(props.roomMessages.get(roomId) ?? [])])
    }, [roomChanges]);


    useEffect(() => {
        if (messageList.length === 0) {
            if (isValidRoom(roomId)) {
                props.startGetRoomMessages(roomId)
            }
        }
    }, [roomId])

    function scrollChatToBottom() {
        if (document.getElementById(roomId) !== null) {
            document.getElementById(roomId).scrollTop = document.getElementById(roomId)?.scrollHeight ?? 0
        }
    }

    function isValidRoom(roomId) {
        return (roomId !== "NO_ROOM" && roomId.length > 7)
    }

    console.log("Rendering " + messageList.length + "chat messages")
    return (
        <div>
            <div className={"chat-only-message-container"}>
                <div className={"chat-only-messages gx-chat-main-content"} id={roomId}>
                    {
                        messageList?.map((message, index) => {
                            const className = process.env.REACT_APP_CHAT_ID === message.u._id ? "gx-chat-item gx-flex-row-reverse" : "gx-chat-item"
                            return <div key={uuid()} className={className}>
                                <div className="gx-bubble-block">
                                    <div className="gx-bubble">
                                        {message.attachments && message.attachments?.length > 0 &&
                                            <div className="gx-message">
                                                <Image
                                                    width={200}
                                                    src={config.chatBaseUrl + message.attachments[0].title_link}
                                                />
                                            </div>}
                                        {message.msg && <div className="gx-message">{message.msg}</div>}

                                    </div>
                                    <div className="gx-name">{(message.u?.username == 'openteam' || message.u?.username == 'openadmin') ? '' : message.u?.name}</div>
                                    <div className={"gx-time gx-text-muted" + ((message.u?.username == 'openteam' || message.u?.username == 'openadmin') ? ' gx-text-right' : '')}>
                                        {message.ts ? moment.utc(message.ts).local().fromNow() : ""}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );


}

const actionCreators = {
    startGetRoomMessages: chatonlyActions.getRoomMessages,
    postChatMessage: chatonlyActions.sendChatMessage,
    createChat: chatonlyActions.createChatAction,
    markMessagesAsRead: chatonlyActions.markAllRoomMessageAsReadAction
};

function mapStateToProps(state) {
    const {updatedRooms, deletedRooms, roomMessages} = state.chatonlyReducer;
    const {helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros} = state.macroReducer;
    return {updatedRooms, deletedRooms, roomMessages, helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros};
}

export default connect(mapStateToProps, actionCreators)(ChatOnlyComponent);
