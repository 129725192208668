import _, {capitalize} from "lodash";
import {storageService} from "../../appRedux/Storage";
import {storageConstants} from "../../appRedux/Constants/storage.constants";
import React, {useEffect, useState} from "react";
import {Button, Drawer, Form, Input, Popconfirm, Select, Switch, Table, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import moment, {now} from "moment";
import NoteSwitch from "./NoteSwitch";

function Notes(props) {

    const {profile, expandedProfile} = props
    const {members} = profile

    const accountID = expandedProfile.members.length > 1 ? expandedProfile.members[1]?.account?.id : expandedProfile.members[0]?.account?.id;


    const storedModerationTags = "moderation,tech,billing,android";
    let notesData = props.profileNotesMap.get(profile.id);

    let options = []
    storedModerationTags.split(",").forEach((tag) =>{
        options.push( {
            value: tag,
            label: capitalize(tag),
        })
    })
    const [drawerVisibility, setDrawerVisibility] = useState(false);

    useEffect(()=> {
        props.getNotes(profile)
        // console.log("props.profileNotesMap",props.profileNotesMap.get(profile.id))
    }, [])

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const [form] = Form.useForm();

    function showDrawer () {
        setDrawerVisibility(true)
    }

    function onClose () {
        setDrawerVisibility(false)
    }

    function showMoreActions(note) {
        if(accountID !== 0) {
            props.deleteNote(profile, accountID, note.id);
            props.getNotes(profile);
        }
    }

    function formatDate(date) {
        const daysAgo = moment().diff( moment.utc(date).local(), 'days')
        if(daysAgo === 0) {
            return (<center>Today<br/>{moment.utc(date).local().format('h:mm a')}</center>)
        } else if(daysAgo === 1) {
            return (<center>Yesterday<br />{moment.utc(date).local().format('h:mm a')}</center>)
        } else if(daysAgo > 7 && daysAgo <= 14) {
            return (<center>{moment.utc(date).local().format('dddd')}<br/><small>{moment.utc(date).local().format('MMM d, y')}</small><br/>{moment.utc(date).local().format('h:mm a')}</center>)
        } else if(daysAgo > 14 && daysAgo < 365) {
            return (<center>{moment.utc(date).local().format('dddd')}<br/><small>{moment.utc(date).local().format('MMM d, y')}</small><br/>{moment.utc(date).local().format('h:mm a')}</center>)
        } else {
            return (<center>{moment.utc(date).local().format('dddd')}<br/><small>{moment.utc(date).local().format('MMM d, y')}</small><br/>{moment.utc(date).local().format('h:mm a')}</center>)
        }
    }

    const columns = [
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: tags => (
                <span>
                {tags?.map(tag => {
                    return (
                        <Tag color={'red'} key={tag}>
                            {tag}
                        </Tag>
                    );
                })}
            </span>
            )
        },
        {
            title: 'Date',
            dataIndex: 'created_ts',
            key: 'created_ts',
            render: date => formatDate(date)
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => {
                return <NoteSwitch profile={profile} record={record} />
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) =>
                <Popconfirm placement="top" title={"Sure you want to delete"} onConfirm={()=> showMoreActions(record)} okText="Yes" cancelText="No">
                    <Button  size='small' type="link" >Delete</Button>
                </Popconfirm>
        }
    ];

    const onFinish = (values) => {
        props.createNote(profile, accountID, values.content, values.tags)

        if(!props.creatingNoteInProgress) {
            form.resetFields()
            onClose()
        }
    };


    return <div style={{height: "455px", padding:"10px", backgroundColor:"#fff"}}
                className="site-drawer-render-in-current-wrapper-2">
        <Button
            onClick={showDrawer}
            type="primary">
            <PlusOutlined /> New Note
        </Button>
        <Drawer
            title="Add Notes"
            placement="right"
            width={500}
            closable={false}
            onClose={onClose}
            visible={drawerVisibility}
            getContainer={false}
            style={{ position: 'absolute' }}>

            <Form
                form={form}
                {...layout}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{
                    content: '',
                    tags: [],
                }}>
                <Form.Item label="Tags" name='tags'
                           rules={[
                               () => ({
                                   validator(rules, value) {
                                       if(_.isEmpty(value)) {
                                           return Promise.reject(new Error('Select at least one tag'));
                                       }
                                       return Promise.resolve();
                                   },
                               }),
                           ]}>
                    <Select
                        options={options}
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select tags"
                        optionLabelProp="label"/>
                </Form.Item>
                <Form.Item label="Notes" name='content' help="Enter note here" rules={[{ required: true, message: 'Please enter note.' }]}>
                    <Input.TextArea allowClear={true} rows={5} />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                    <Button type="primary" htmlType="submit" loading={props.creatingNoteInProgress}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
        <Table
            dataSource={notesData}
            pagination={{ pageSize: 9, showSizeChanger:false}}
            rowKey={record => record.id}
            columns={columns}
            size="small"/>
    </div>

}

const actionCreators = {
    createNote: profileActions.createNoteAction,
    getNotes: profileActions.getNotesFromProfileAction,
    deleteNote: profileActions.deleteNotesAction,
}

function mapStateToProps(state) {
    const  { creatingNoteInProgress, profileNotesMap, expandedProfile } = state.profileReducer
    return { creatingNoteInProgress, profileNotesMap, expandedProfile }
}

export default connect(mapStateToProps, actionCreators)(Notes)