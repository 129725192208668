import { uuid } from "uuidv4";

export class UserProfile {

    constructor() {
        this.id = uuid();
        this.username = {
            value: '',
            visible: false
        };
        this.about = {
            value: '',
            visible: false
        };
        this.relationship_status = {
            value: '',
            visible: false
        };
        this.relationship_type = {
            value: '',
            visible: false
        };
        this.fmx = [];
        this.available_badges = [];
        this.active_badges = [];
        this.finished = false;
        this.complete = false;
        this.visible = false;
        this.last_seen_ts = new Date();
        this.created_ts = new Date();
        this.modified_ts = new Date();
        this.about_ml_flagged = [];
        this.about_ml_flagged_friendly = [];
        this.about_ml_results = {};
        this.liked_by_profiles = [];
        this.passed_by_profiles = [];
        this.reviewed = false;
        this.reviewed_by = '';
        this.reviewed_ts = new Date();
        this.automatic_review_required = false;
        this.active_auto_review_flags = [];
        this.members = [];
        this.photos = [];
        this.social_media_accounts = [];
        this.preference_lists = [];
        this.social_media_accounts = [];
        this.preference_lists = [];
        this.reports = [];
    }
}