import {connect, useDispatch, useSelector} from "react-redux";
import {ChatOnlyComponent, ChatOnlyInput} from "../../components";
import React, {useEffect, useMemo, useState} from "react";
import { Col, Row, Typography, Avatar, Type } from 'antd';
import {
    alertActions,
    macroActions,
    profileActions,
    findSupportRoom,
} from "../../appRedux/Actions";
import {chatConstants} from "../../appRedux/Constants";
import emptyImage from "../../assets/img.png";
const {Text} = Typography;

function Chats(props) {
    let [roomId, setRoomId] = useState("")
    const {expandedProfile} = props
    const dispatcher = useDispatch()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const profile_id = urlParams.get('id')

    const avatar_photo = expandedProfile.photos[0]?.avatar_url ?? emptyImage
    const profile_username = expandedProfile.username?.value ?? ""

    useEffect(()=> {
        props.getAllMacros();
    }, []);

    useEffect(()=> {
        props.getProfile(profile_id);
    }, []);

    function isValidRoom(roomId) {
        return (roomId.length > 7)
    }

    useEffect(() => {
        if (profile_username.length > 1) {
            document.title = '@' + profile_username + ' - #open Support Portal';
        }
    }, [profile_username]);


    findSupportRoom(profile_id)
    .then(response => {
        setRoomId(response.roomID)
        console.log("Chat container room id: " + roomId)
        if (isValidRoom(roomId)) {
            dispatcher(successFindRoom(roomId))
        }
    }).catch((error) => {
    dispatcher(alertActions.error(error.toString()))
    })

    function successFindRoom(roomId) {
        return {type: chatConstants.FIND_SUPPORT_ROOM_SUCCESS, roomId}
    }

    return <>
        <Row justify="left" align="top" className={"chat-header"}>
            <Col>
                <Avatar size={64} shape="square" src={avatar_photo} className={"chat-avatar"}/>
            </Col>
            <Col>
                <Typography.Title level={4} className={"chat-header-text"}>
                    @{profile_username}
                </Typography.Title>
                <Text type="secondary" className={"chat-header-text"}>
                    (id {profile_id})
                </Text>

            </Col>
        </Row>
        <Row justify="center" align="top" className={"chat-row"}>
            <Col span={24}>
                <ChatOnlyComponent profile={{"id": {profile_id}}} roomId={roomId}/>
            </Col>
        </Row>
        <ChatOnlyInput profile={{"id": {profile_id}}} roomId={roomId}></ChatOnlyInput>
    </>
}

const actionCreators = {
    getAllMacros: macroActions.getAllMacros,
    getProfile: profileActions.getExpandedProfile,
}

function mapStateToProps(state) {
    const {expandedProfile} = state.profileReducer
    return {expandedProfile}
}
export default connect(mapStateToProps, actionCreators) (Chats);


