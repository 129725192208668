import {connect} from "react-redux";
import {Button, Card, Checkbox, Col, Form, Input, Row, Table, Tooltip} from "antd";
import React, {useState} from "react";
import {hashtagsActions} from "../../appRedux/Actions/hashtags.action";
import {authenticationActions, profileActions} from "../../appRedux/Actions";

function Hashtags(props) {

    const [hashtag, setHashtag] = useState("")
    const [banned, setBanned] = useState(false)
    const [bannedUnBanned, setBannedUnBanned] = useState(false)
    const [partial, setPartial] = useState(false)
    const [include_preferences, setPreference] = useState(true)
    const [include_boundaries, setBoundaries] = useState(true)

    const [selectedRowKeysPreference, setSelectedRowKeysPreference] = useState([]);
    const [selectedRowKeysBoundary, setSelectedRowKeysBoundary] = useState([]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 },
    };

    const [form] = Form.useForm();

    function getColumns(prefSearch, boundarySearch) {
        return [
            {
                title: 'Tag',
                dataIndex: 'tag',
                key: 'tag',
                width: '30%',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 150,
                        }
                    }
                },
                render: (text, record) => (
                    <Tooltip title={text}>
                        <Button type={"link"} onClick={() => searchProfilesWithHashTag(record.tag, prefSearch, boundarySearch)}>
                            <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</div>
                        </Button>
                    </Tooltip>
                )
            },
            {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
                render: (text, record) => (
                    <Button type={"link"} onClick={() => searchProfilesWithHashTag(record.tag, prefSearch, boundarySearch)}>
                        {text === null ? 0 : text}
                    </Button>
                )
            },
            {
                title: 'Banned',
                dataIndex: 'banned',
                key: 'banned',
                render: (text, record) => <>
                    { record.banned ?
                        "True" : "False"}
                </>
            }
        ]
    }

    const onSelectChangePreference = (selectedRowKeys) => {
        setSelectedRowKeysPreference(selectedRowKeys)
    };

    const rowSelectionPreference =  {
        setSelectedRowKeysPreference,
        onChange: onSelectChangePreference,
    }

    const onSelectChangeBoundary = (selectedRowKeys) => {
        setSelectedRowKeysBoundary(selectedRowKeys)
    };

    const rowSelectionBoundary =  {
        onSelectChangeBoundary,
        onChange: onSelectChangeBoundary,
    }

    const onFinish = (values) => {
        const searchOptions = {
            "hashtag": "",
            "bannedUnBanned": bannedUnBanned,
            "banned": banned,
            "partial": partial,
            "include_preferences": include_preferences,
            "include_boundaries": include_boundaries
        }
        searchOptions.hashtag = hashtag
        props.triggerSearchHashtagAction(searchOptions)
    }

    const searchProfilesWithHashTag = (hashtag, prefSearch, boundarySearch) => {
        props.triggerSearchWithHashtag(`#${hashtag}`, prefSearch, boundarySearch)
    }

    const updateBannedStatus = (status) => {
        props.triggerUpdateHashtagBanStatusAction(selectedRowKeysPreference, selectedRowKeysBoundary, status)
    }

    return <>
        <Row gutter={[16, 16]} style={{height : '100%'}}>
            <Col span={6}>
               <Card title="Search Hashtags" >
                   <Form
                       form={form}
                       {...layout}
                       labelCol={{
                           span: 6,
                       }}
                       wrapperCol={{
                           span: 16,
                       }}
                       onFinish={onFinish}
                       scrollToFirstError>
                       <Input allowClear={true} onChange={event => setHashtag(event.target.value)} prefix="#" />
                       <br/>
                       <br/>

                       <Row>
                           <Col span={12}>
                               <Checkbox onChange={event => setBannedUnBanned(event.target.checked)}/> Include banned & unbanned
                           </Col>
                       </Row>
                       <br/>
                       <Row>
                           { bannedUnBanned ? <div/> :   <Col span={12}>
                               <Checkbox onChange={event => setBanned(event.target.checked)}/> Banned
                           </Col>}
                           <Col span={12}>
                               <Checkbox onChange={event => setPartial(event.target.checked)}/> Partial

                           </Col>
                       </Row>
                       <Row>
                           <Col span={12}>
                               <Checkbox checked={include_preferences} onChange={event => setPreference(event.target.checked)}/> Preferences

                           </Col>
                           <Col span={12}>
                               <Checkbox checked={include_boundaries} onChange={event => setBoundaries(event.target.checked)}/> Boundaries

                           </Col>
                       </Row>
                       <br/>
                       <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                           <Button type="primary" htmlType="submit" loading={props.fetching}>
                               Search hashtag
                           </Button>
                       </Form.Item>
                   </Form>
               </Card>
            </Col>
            <Col span={18}>
                <Row>
                    <Col span={12}><h5>Search Result</h5></Col>
                    { (props.hashtagsPreferenceList.length === 0 && props.hashtagsBoundaryList.length === 0) ? <div></div> :
                        <Col span={12} style={{float: 'right'}}>
                            <Button type="primary" htmlType="button" loading={props.updating}
                            onClick={() => updateBannedStatus(true)}>
                            Ban
                            </Button>
                            &nbsp;
                            <Button type="ghost" htmlType="button" loading={props.updating}
                            onClick={() => updateBannedStatus(false)}>
                            UnBan
                            </Button>
                        </Col>
                    }
                </Row>
                <br/>
                <Row>
                    { props.hashtagsPreferenceList.length === 0 ? <div/> :
                        <Col span={12}>
                            <Card title="Preferences">
                                <Table
                                    rowSelection={rowSelectionPreference}
                                    dataSource={props.hashtagsPreferenceList}
                                    pagination={{ pageSize: 50, showSizeChanger:false}}
                                    rowKey={record => record.tag}
                                    columns={getColumns(true, false)}
                                    size="small"/>
                            </Card>
                        </Col>
                    }


                    { props.hashtagsBoundaryList.length === 0 ? <div/> :
                        <Col span={12}>
                            <Card title="Boundaries">
                                <Table
                                    rowSelection={rowSelectionBoundary}
                                    dataSource={props.hashtagsBoundaryList}
                                    pagination={{ pageSize: 50, showSizeChanger:false}}
                                    rowKey={record => record.tag}
                                    columns={getColumns(false, true)}
                                    size="small"/>
                            </Card>
                        </Col>
                    }

                </Row>
            </Col>
        </Row>
        </>
}
const actionCreators = {
    triggerSearchHashtagAction: hashtagsActions.searchHashtagAction,
    triggerUpdateHashtagBanStatusAction :hashtagsActions.updateHashtagBanStatusAction,
    triggerSearchWithHashtag: profileActions.searchProfileAction
}


function mapStateToProps(state) {
    return { ...state.hashtagsReducer };
}

export default connect(mapStateToProps, actionCreators)(Hashtags);
