import {chatConstants, profileConstants} from "../Constants";
import {storageService} from "../Storage";
import {storageConstants} from "../Constants/storage.constants";
import {chatService} from "../Services/chat.service";
import {alertActions} from "./alert.actions";
import {profileService} from "../Services";
import moment, {now} from "moment";
import _ from 'lodash'

const CONNECTED = "connected"
const PING = "ping"
const PONG = "pong"
const RESULT = "result"
const CHANGED = "changed"
const ERROR = "error"
// const REMOVED = "removed"
// const UPDATED = "updated"
// const ADDED = "added"
// const NOSUB = "nosub"
const webSocket = new WebSocket(process.env.REACT_APP_CHAT_WEBSOCKET);

export const chatActions = {
    loginToWebSocket,
    streamRoomMessages,
    sendChatMessage,
    fetchAllRooms,
    getRoomMessages,
    createChatAction,
    clearCreatedRoom,
    markAllRoomMessageAsReadAction,
    createNewRoomAndSendChat,
    dispatchSubscribeToMessageCountsAction
};

function loginToWebSocket() {
    return dispatch => {
        console.log("Logging in to web socket...")
        webSocket.onmessage = e => {
            const data = JSON.parse(e.data);
            const msg = data.msg;

            switch (msg) {
                case CONNECTED: {
                    console.log("Web socket connected")
                    webSocket.send(JSON.stringify({
                        "msg": "method",
                        "method": "login",
                        "id": "loginRequestId",
                        "params":[
                            { "resume": process.env.REACT_APP_CHAT_TOKEN }
                        ]
                    }))
                    break
                }
                case PING: {
                    console.log("Websocket ping received")
                    dispatch(chatServerStatus(true))
                    webSocket.send(JSON.stringify({
                        "msg": "pong"
                    }))
                    break
                }
                case PONG: {
                    console.log("Sending websocket pong")
                    dispatch(chatServerStatus(true))
                    webSocket.send(JSON.stringify({
                        "msg": "ping"
                    }))
                    break
                }
                case RESULT: {
                    //console.log("onResult-->" + data.result)
                    //console.log("onResult-->" + data.id)
                    if (data.id === "loginRequestId") {
                        console.log("Websocket authentication ", "successful")
                        dispatch(chatServerStatus(true))
                        storageService.saveToStore(storageConstants.CHAT_SESSION_DATA, JSON.stringify(data.result))
                        listenToRoomChanges()
                    } else {
                        console.log("Else", data)
                    }
                    break
                }
                case CHANGED: {
                    if(data.collection === "stream-notify-user") {
                        const updatedRoom = data.fields?.args[1]

                        dispatch(roomChanged(updatedRoom))
                        // subscribeToMessageCounts(dispatch)
                        if(updatedRoom.lastMessage?.u?._id !== process.env.REACT_APP_CHAT_ID
                            && updatedRoom.lastMessage !== null
                            && updatedRoom.lastMessage?.msg !== null
                        ) {
                            if(`${updatedRoom.lastMessage?.msg}` !== "" && updatedRoom.lastMessage?.msg !== undefined) {
                                dispatch(alertActions.notificationAlert(`${updatedRoom.lastMessage?.msg}`))
                            }
                        }
                    } else if(data.collection === "stream-room-messages") {
                        const newMessage = data.fields?.args[0]
                        dispatch(newMessageReceived(newMessage, newMessage.rid))
                    }
                    break
                }
                case ERROR: {
                    //console.log("onResult-->Default")
                    dispatch(chatServerStatus(false))
                    loginToWebSocket()
                    break
                }
                default: {
                    //console.log("onResult-->Default")
                }
            }
        };

        webSocket.onerror = function (event) {
            console.log("There was an error with your websocket.", event);
            dispatch(chatServerStatus(false))
        };
        webSocket.onclose = function (event) {
            console.log("---------------------------------")
            console.log("OnClose: Error Code" + getStatusCodeString(event.code))
            console.log("OnClose: Error Event")
            console.log(event)
            console.log("---------------------------------")
            dispatch(chatServerStatus(false))
            loginToWebSocket()
        };
        connectToWebSocket()
    }

    function newMessageReceived(newMessage, messageRoomId) { return { type: chatConstants.NEW_CHAT_MESSAGE_RECEIVED, newMessage, messageRoomId } }
    function roomChanged(updatedRoom) { return { type: chatConstants.ROOM_CHANGE_SUCCESSFUL, updatedRoom } }
    function chatServerStatus(status) { return { type: chatConstants.CHAT_SERVER_STATUS, status } }
}

let specificStatusCodeMappings = {
    '1000': 'Normal Closure',
    '1001': 'Going Away',
    '1002': 'Protocol Error',
    '1003': 'Unsupported Data',
    '1004': '(For future)',
    '1005': 'No Status Received',
    '1006': 'Abnormal Closure',
    '1007': 'Invalid frame payload data',
    '1008': 'Policy Violation',
    '1009': 'Message too big',
    '1010': 'Missing Extension',
    '1011': 'Internal Error',
    '1012': 'Service Restart',
    '1013': 'Try Again Later',
    '1014': 'Bad Gateway',
    '1015': 'TLS Handshake'
};

function getStatusCodeString(code) {
    if (code >= 0 && code <= 999) {
        return '(Unused)';
    } else if (code >= 1016) {
        if (code <= 1999) {
            return '(For WebSocket standard)';
        } else if (code <= 2999) {
            return '(For WebSocket extensions)';
        } else if (code <= 3999) {
            return '(For libraries and frameworks)';
        } else if (code <= 4999) {
            return '(For applications)';
        }
    }
    if (typeof(specificStatusCodeMappings[code]) !== 'undefined') {
        return specificStatusCodeMappings[code];
    }
    return '(Unknown)';
}

function dispatchSubscribeToMessageCountsAction() {
    return dispatch => {
        subscribeToMessageCounts(dispatch, "dispatchSubscribeToMessageCountsAction")
    }
}

function subscribeToMessageCounts(dispatch, source = "") {
    dispatch(request());
    chatService.getRoomSubscription().then(data => {
        let unReadMessageCount = 0
        const updateRooms = data.update
        let profileIdsWithChat = []
        console.log("Update Rooms (from " + source + "): " + JSON.stringify(updateRooms))


        updateRooms.sort(function(a, b) { return moment.utc(a._updatedAt).local().unix() - moment.utc(b._updatedAt).local().unix()})

        updateRooms.forEach(room => {
            if(room.unread > 0 && room?.t !== "d") {
                unReadMessageCount += 1
                const match = /_support_(\d+)_/
                const profiles = room.name?.match(match)
                profileIdsWithChat.push(profiles[1]);
            }
        })

        console.log("Checking local cache for profile")
        let cachedProfileList = []
            profileIdsWithChat.forEach((profileIds) => {
                //console.log(profileIds)
                const profileExist = storageService.getFromStore(storageConstants.CHAT_PROFILE_CACHE + "_" + profileIds)
                // only one profile at a time, then pushes and filters out the ID
                    if(!_.isEmpty(JSON.parse(profileExist))) {
                        cachedProfileList.push(JSON.parse(profileExist))
                        profileIdsWithChat = profileIdsWithChat.filter((profileId) => profileId !== profileIds)
                    }
                })

        console.log(`${cachedProfileList.length} cached profile`)
        console.log(`${profileIdsWithChat.length} profiles missing`)

        if(profileIdsWithChat.length > 0) {
            console.log(`Fetching ${profileIdsWithChat.length} missing local profiles from server`)
            profileService.getProfilesByIdsOnServer(profileIdsWithChat).then((profiles) => {

                console.log(`Updating local cache with new ${profiles.length} profiles downloaded`)
                //console.log(profiles)
                if(profiles.length > 0){
                profiles?.forEach((profile) => {
                    //console.log('each object in array')
                    storageService.saveToStore(storageConstants.CHAT_PROFILE_CACHE + "_" +profile.id, JSON.stringify(profile))
                });
            };
                if(!_.isEmpty(cachedProfileList)) {
                    //console.log('line 235, cachedprofilelist')
                    console.log(cachedProfileList);
                    console.log(`Add new profiles of size (${profiles.length}) to local cache of size ${cachedProfileList?.length}`)
                    if(!_.isEmpty(profiles)) {
                        cachedProfileList.push(profiles);
                    }
                    dispatch(profileWithChatSuccess(cachedProfileList, unReadMessageCount))
                } else {
                    console.log(`All requested profiles are downloaded. Using online cache.`)
                    //console.log(profiles)
                    dispatch(profileWithChatSuccess(profiles, unReadMessageCount))
                }
            }).catch(error => {
                console.log('caught an error here')
                console.log(error);
                dispatch(alertActions.error(error.toString()));
            });
        } else {
            console.log(`All requested profiles are available in storage. Using local cache.`)
            console.log(cachedProfileList)
            dispatch(profileWithChatSuccess(cachedProfileList, unReadMessageCount))
        }
    })
    function request() { return { type: profileConstants.PROFILE_WITH_CHATS_REQUEST} }
    function profileWithChatSuccess(profiles, unReadMessageCount) { return { type: profileConstants.PROFILE_WITH_CHATS, profiles, unReadMessageCount } }
}

function fetchAllRooms(clearCache) {
    console.log("Fetching all rooms")
    return dispatch => {
        console.log("No Cached Room Fetching From Server")
        chatService.getAllRooms().then(data => {
            storageService.saveToStore(storageConstants.CHAT_ROOMS_DATA, data)
            storageService.saveToStore(storageConstants.UPDATE_CHAT_ROOM, now());
            dispatch(roomFetchedSuccess(data.remove, data.update))
            subscribeToMessageCounts(dispatch, "fetchAllRooms")
        }, error => {
            console.log("fetchAllRooms-error", error)
            console.log(error)
            dispatch(alertActions.error(error.toString()));
        })
        function roomFetchedSuccess(deletedRooms, updatedRooms) { return { type: chatConstants.FETCH_ROOM_SUCCESSFUL, deletedRooms, updatedRooms } }
    }
}

function sendChatMessage(roomId, message) {
    console.log("sendChatMessage", "Sending chat message...")
    return dispatch => {
        dispatch(request())

        chatService.sendChatMessage(roomId, message)
            .then(messageResponse => {
            dispatch(successNew(messageResponse.message, roomId))
        },
        error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        })
    }

    function successNew(message, roomId) { return { type: chatConstants.SEND_CHAT_MESSAGE_SUCCESSFUL, message, roomId } }
    function request() { return { type: chatConstants.SEND_CHAT_MESSAGE_REQUEST} }
    function failure(error) { return { type: chatConstants.SEND_CHAT_MESSAGE_ERROR, error } }
}

function markAllRoomMessageAsReadAction(profile, roomId) {
    console.log(`markAllRoomMessageAsReadAction ${roomId} message.....`)
    return dispatch => {
        dispatch(removeProfiles([profile.id]))
        console.log(`markAllRoomMessageAsReadAction dispatch(removeProfiles([profile.id]))`)
        chatService.markAllRoomMessageAsReadService(roomId)
            .then(data => {
                    subscribeToMessageCounts(dispatch, "markAllRoomMessagesAsReadAction")
                },
                error => {
                    dispatch(removeProfiles([profile.id]))
                    dispatch(failure(error.toString()));
                })
    }

    function removeProfiles(profilesToRemove) { return { type: profileConstants.REMOVE_PROFILES_FROM_WITH_CHATS, profilesToRemove } }
    function failure(error) { return { type: chatConstants.FETCH_ROOM_MESSAGE_ERROR, error } }
}

function getRoomMessages(roomId) {
    console.log("streaming: ", `Streaming room ${roomId} message.....`)
    return dispatch => {
        chatService.fetchRoomMessages(roomId)
            .then(data => {
                    dispatch(success(roomId, data.messages))
                },
                error => {
                    dispatch(failure(error.toString()));
                })
    }
    function success(roomId, messages) { return { type: chatConstants.FETCH_ROOM_MESSAGE_SUCCESSFUL, messages, roomId } }
    function failure(error) { return { type: chatConstants.FETCH_ROOM_MESSAGE_ERROR, error } }
}

function clearCreatedRoom() {
    return { type: chatConstants.CLEAR_CREATED_ROOM };
}

export async function createNewRoomAndSendChat(profile, message) {
    const response = await chatService.createChatService(profile.id)
    const roomId = response.group_id
    const messageResponse = await chatService.sendChatMessage(roomId, message)
    return {message: messageResponse.message, roomId: roomId}
}

// FixMe Not working yet.
function createChatAction(profile, message) {
    return dispatch => {
        console.log("Creating room [createChatAction]")

        chatService.createChatService(profile.id)
            .then(response => {
                console.log("Room created")
                console.log(`Chat room created RoomID returned: ${response.group_id}`)
                const roomId = response.group_id
                    console.log(`Sending message to room ${roomId}`)
                    chatService.sendChatMessage(roomId, message)
                        .then(messageResponse => {
                                console.log("Message sent.")
                                console.log("Sending dispatch events to selected room")
                                dispatch(successNew(messageResponse.message, roomId))
                                dispatch(success(roomId))
                            },
                            error => {
                                dispatch(alertActions.error(error.toString()));
                            })
        },
        error => {
            dispatch(alertActions.error(error.toString()));
        })
    }
    function successNew(message, roomId) { return { type: chatConstants.SEND_CHAT_MESSAGE_SUCCESSFUL, message, roomId } }
    function success(roomId) { return { type: chatConstants.CREATE_CHAT_SUCCESSFUL, roomId } }
}

function connectToWebSocket() {
    console.log("Websocket", "Starting websocket")
    webSocket.onopen = () => (
        webSocket.send(JSON.stringify({
            "msg": "connect",
            "version": "1",
            "support": ["1"]
        })))
}

function listenToRoomChanges() {
    console.log("Websocket", "Start listening to room changes")
    webSocket.send(JSON.stringify({
        "msg": "sub",
        "id": "stream-room-changes",
        "name": "stream-notify-user",
        "params":[
            `${process.env.REACT_APP_CHAT_ID}/rooms-changed`,
            false
        ]
    }))
}

export function streamRoomMessages(roomId) {
    console.log("Websocket", `Start listening to room ${roomId} messages`)
    if(webSocket.readyState !== webSocket.OPEN) {
        loginToWebSocket()
    } else {
        const data = JSON.stringify({
            "msg": "sub",
            "id": "streaming-room-messages",
            "name": "stream-room-messages",
            "params": [
                roomId,
                false
            ]
        })
        webSocket.send(data)
    }
}