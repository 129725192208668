import { authHeader } from '../Helpers';
import {config} from "../Config";
import {handleResponse} from "./authentication.service";

export const mlLabelService = {
    fetchLabels,
    addLabel
};

function fetchLabels() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/ml-label`, requestOptions).then(handleResponse);
}

function addLabel(params) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/ml-label`, requestOptions).then(handleResponse);
}