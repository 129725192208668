import React, {useEffect} from 'react';
import {Button, Col, Row, Slider, Switch, Checkbox, Tag, Tooltip} from "antd";
import {FormGroup, Input} from "reactstrap";
import {uuid} from "uuidv4";
import {getAccountOwner} from "../../Utilities";
import {profileActions} from "../../appRedux/Actions";
import {useDispatch, useSelector} from "react-redux";

function TabBoundaries(props) {
    const dispatcher = useDispatch()
    const searchedText = useSelector(state => state.profileReducer.searchedText) ?? ""

    const { profile, expandedProfile } = props
    const { boundary_lists } = expandedProfile

    const ageRangeMarks = {
        18: {
            style : {top: '-34px'},
            label: '18'
        },
        69: {
            style : {marginLeft: '-10px', top: '-34px'},
            label: '69+'
        }
    }

    const distanceFilterMarksMiles = {
        25: {
            style : {top: '-34px'},
            label: '25'
        },
        500: {
            style : {marginLeft: '-16px', top: '-34px'},
            label: '500+'
        }
    }

    const distanceFilterMarksKilometers = {
        40: {
            style : {top: '-34px'},
            label: '40'
        },
        800: {
            style : {marginLeft: '-16px', top: '-34px'},
            label: '800+'
        }
    }


    const solo = ['F', 'M', 'X'];
    const partnered1 = ['FM', 'FX', 'MX'];
    const partnered2 = ['FF', 'MM', 'XX'];
    const allOptionsList = ['All', 'Solo', 'Partnered'];

    const member1 = expandedProfile.members[0] ?? null;
    const member2 = expandedProfile.members[1] ?? null;

    const seeAndSeenBy1 = member1?.see_and_seen_by_filter ?? []
    const seeAndSeenBy2 = member2?.see_and_seen_by_filter ?? []

    const m1selectedSolo = []
    const m1selectedPartnered1 = []
    const m1selectedPartnered2 = []
    const m1allOptions = [];

    const m2selectedSolo = []
    const m2selectedPartnered1 = []
    const m2selectedPartnered2 = []
    const m2allOptions = [];

    if (member1) {
        m1selectedSolo.push(...getSelected(solo, seeAndSeenBy1))
        m1selectedPartnered1.push(...getSelected(partnered1, seeAndSeenBy1))
        m1selectedPartnered2.push(...getSelected(partnered2, seeAndSeenBy1))
        if(m1selectedSolo.length === 3) {
            m1allOptions.push("Solo")
        }
        if(m1selectedPartnered1.length === 3 && m1selectedPartnered2.length === 3) {
            m1allOptions.push("Partnered")
        }
        if(m1selectedPartnered1.length === 3 && m1selectedPartnered2.length === 3 && m1selectedSolo.length === 3) {
            m1allOptions.push("All")
        }
    }

    if (member2) {
        m2selectedSolo.push(...getSelected(solo, seeAndSeenBy2))
        m2selectedPartnered1.push(...getSelected(partnered1, seeAndSeenBy2))
        m2selectedPartnered2.push(...getSelected(partnered2, seeAndSeenBy2))
        if(m2selectedSolo.length === 3) {
            m2allOptions.push("Solo")
        }
        if(m2selectedPartnered1.length === 3 && m2selectedPartnered2.length === 3) {
            m2allOptions.push("Partnered")
        }
        if(m2selectedPartnered1.length === 3 && m2selectedPartnered2.length === 3 && m2selectedSolo.length === 3) {
            m2allOptions.push("All")
        }
    }

    function convertAgeFilter(filter) {
        if(filter[1] > 69) {
            filter[1] = 69
        }
        return filter
    }

    const m1ageRangeFilter = member1?.age_range_filter ? convertAgeFilter(member1.age_range_filter) : [18, 69]
    const m2ageRangeFilter = member2?.age_range_filter ? convertAgeFilter(member2.age_range_filter) : [18, 69]

    function setAgeRangeMarks(marks, filter) {
        let returnMarks = {...marks}
        if (filter) {
            if (filter[1]) {
                if (filter[0] >= 19) {
                    returnMarks[filter[0]] = filter[0].toString()
                }
                if(filter[1] < 69) {
                    returnMarks[filter[1]] = filter[1].toString()
                }
            }
        }
        return returnMarks
    }

    const m1ageRangeMarks = setAgeRangeMarks(ageRangeMarks, m1ageRangeFilter)
    const m2ageRangeMarks = setAgeRangeMarks(ageRangeMarks, m2ageRangeFilter)

    function convertDistance(distance, useKilometers) {
        if (useKilometers === true) {
            const result = Math.round(distance / 1000)
            if (result > 800) {
                return 800
            } else if (result < 40) {
                return 40
            } else {
                return result
            }
        } else {
            const result = Math.round(distance / 1609.34)
            if (result > 500) {
                return 500
            } else if (result < 25) {
                return 25
            } else {
                return result
            }
        }
    }

    const m1useKilometers = member1?.account?.use_kilometers ?? false
    const m1distanceFilter =  member1?.distance_filter ? convertDistance(member1?.distance_filter, m1useKilometers) : 0
    const m2useKilometers = member2?.account?.use_kilometers ?? false
    const m2distanceFilter =  member2?.distance_filter ? convertDistance(member2?.distance_filter, m2useKilometers) : 0

    function setDistanceFilterMarks(filter, useKilometers) {
        if (useKilometers === true) {
            let returnMarks = {...distanceFilterMarksKilometers}
            if (filter) {
                if (filter < 800 && filter > 40) {
                    returnMarks[filter] = filter.toString()
                }
            }
            return returnMarks
        } else {
            let returnMarks = {...distanceFilterMarksMiles}
            if (filter) {
                if (filter < 500 && filter > 25) {
                    returnMarks[filter] = filter.toString()
                }
            }
            return returnMarks
        }
    }

    const m1distanceFilterMarks = setDistanceFilterMarks(m1distanceFilter, m1useKilometers)
    const m2distanceFilterMarks = setDistanceFilterMarks(m2distanceFilter, m2useKilometers)

    function getSelected(children, parent) {
        const included = []
        children.forEach((child) => {
            if(parent.includes(child)) {
                included.push(child)
            }
        })
        return included
    }

    function formatPhoneNumber(number) {
        let match = number.match(/\+(1|)?(\d{3})(\d{3})(\d{4})/);
        if (match) {
            let intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', 'xxx', ') ', 'xxx', '-', match[4]].join('');
        } else {
            let format_number = number.replace('+1', '')
            format_number = format_number.replace('+', '')
            return format_number
        }
    }

    function getPhoneNumber(member) {
        return <span>{member.account?.phone_number ? formatPhoneNumber(member.account?.phone_number) : "N/A"}</span>
    }

    function getUnit(useKilometers) {
        if (useKilometers === true) {
            return "km"
        } else {
            return "mi"
        }
    }

    function getUnitTooltip(useKilometers) {
        if (useKilometers === true) {
            return "User is viewing distances in kilometers"
        } else {
            return "User is viewing distances in miles"
        }
    }
    return ([
        member1?.account ?
            <div style={{overflowX: 'hidden', scrollbarWidth: 'none'}}>

                <div>
                    <h5>{getPhoneNumber(member1)}</h5>
                    <h6>See/Seen By</h6>
                    <Row className="checkboxes">
                        <Checkbox.Group options={solo} defaultValue={m1selectedSolo} /><br/>
                        <Checkbox.Group options={partnered1} defaultValue={m1selectedPartnered1} /><br/>
                        <Checkbox.Group options={partnered2} defaultValue={m1selectedPartnered2} /><br/>
                        <Checkbox.Group options={allOptionsList} defaultValue={m1allOptions} />
                    </Row>

                </div>
                <hr/>
                <div>
                <h6>Age Range</h6>
                <Slider style={{marginRight: '10px', marginLeft:'10px', marginTop:'24px'}} range min={18} max={69} defaultValue={m1ageRangeFilter} marks={m1ageRangeMarks}/>
                </div>
                <hr/>
                <div>
                    <h6><Tooltip placement="top" title={getUnitTooltip(m1useKilometers)}>
                        Distance ({getUnit(m1useKilometers)}
                        </Tooltip>)</h6>
                    <Slider style={{marginRight: '10px', marginLeft:'10px', marginTop:'24px'}} min={m1useKilometers ? 40 : 25} max={m1useKilometers ? 800 : 500} defaultValue={m1distanceFilter} marks={m1distanceFilterMarks}/>
            </div>
            </div>
            : '',
        member2?.account ?
            <div style={{overflowX: 'hidden', scrollbarWidth: 'none'}}>

                <div>
                    <h5>{getPhoneNumber(member2)}</h5>
                    <h6>See/Seen By</h6>
                    <Row className="checkboxes">
                        <Checkbox.Group options={solo} defaultValue={m2selectedSolo} /><br/>
                        <Checkbox.Group options={partnered1} defaultValue={m2selectedPartnered1} /><br/>
                        <Checkbox.Group options={partnered2} defaultValue={m2selectedPartnered2} /><br/>
                        <Checkbox.Group options={allOptionsList} defaultValue={m2allOptions} />
                    </Row>

                </div>
                <hr/>
                <div>
                    <h6>Age Range</h6>
                    <Slider style={{marginRight: '10px', marginLeft:'10px', marginTop:'24px'}} range min={18} max={69} defaultValue={m2ageRangeFilter} marks={m2ageRangeMarks}/>
                </div>
                <hr/>
                <div>
                    <h6><Tooltip placement="top" title={getUnitTooltip(m2useKilometers)}>
                        Distance ({getUnit(m2useKilometers)}
                    </Tooltip>)</h6>
                    <Slider style={{marginRight: '10px', marginLeft:'10px', marginTop:'24px'}}  min={m2useKilometers ? 40 : 25} max={m2useKilometers ? 800 : 500}  defaultValue={m2distanceFilter} marks={m2distanceFilterMarks}/>

                </div>
            </div>
            : '',
    ]);
}

export default TabBoundaries;
