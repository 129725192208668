import React, {useEffect} from 'react';
import {message} from "antd";
import {ProfileTableRenderer} from "../../components";
import {chatActions, moderationActions, profileActions} from "../../appRedux/Actions";
import {connect} from "react-redux";


function AutoModeratedProfiles(props) {

    useEffect(() => {
        props.getNewAutoModeratedProfiles()
        props.loadRooms(false)
        props.getModerationTags()
    }, [])

    showProgress(props.markingNewAutoProfileAsReviewed, 'Marking profile as reviewed', 'clearingNewAutoProfiles')

    function showProgress(status, content, key) {
        if(status){
            message.loading({ content, key }).then(r => "")
        } else {
            message.destroy(key)
        }
    }

    const profileSelected = (profileId) => {
        props.clearAIModeratedProfiles(profileId)
    }

    return (
        <ProfileTableRenderer profiles={props.newAutoProfiles}
                              fetched={props.newAutoProfilesFetched}
                              profileSelectedAction={profileSelected}
                              enableRowSelection={true}/>
    );
}

const actionCreators = {
    getNewAutoModeratedProfiles: profileActions.getNewAutoProfilesFromServerAction,
    clearAIModeratedProfiles: profileActions.clearAIModeratedProfilesAction,
    loadRooms: chatActions.fetchAllRooms,
    getModerationTags: moderationActions.getModerationTagAction
};

function mapStateToProps(state) {
    const { newAutoProfiles, newAutoProfilesFetched, markingNewAutoProfileAsReviewed } = state.profileReducer
    return { newAutoProfiles, newAutoProfilesFetched, markingNewAutoProfileAsReviewed };
}

export default connect(mapStateToProps, actionCreators)(AutoModeratedProfiles);
