import React from 'react';
import {Col, Row, Select, Input, Button, Form, Cascader} from "antd";
import {
    chatonlyActions,
} from "../../appRedux/Actions";
import {connect, useDispatch} from "react-redux";
import _ from "lodash";
import {SendOutlined} from '@ant-design/icons';

const {TextArea} = Input;

function ChatOnlyInput(props) {
    const {roomId} = props
    const {profile} = props
    const [form] = Form.useForm();

    console.log("Chat input room id: " + roomId)

    const markMessageAsRead = () => {
        if (isValidRoom(roomId)) {
            props.markMessagesAsRead(profile, roomId)
        }
    }

    function isValidRoom(roomId) {
        return (roomId !== "NO_ROOM" && roomId.length > 7)
    }

    const onSubmitChat = (values) => {
        if (!_.isEmpty(values.chatMessage)) {
            console.log(`onSubmitChatUpdated`)
            console.log(`roomId`, roomId)

            if (isValidRoom(roomId)) {
                console.log(`RoomId ${roomId} exist Sending chat`)
                const message = values.chatMessage
                props.postChatMessage(roomId, message)
                form.resetFields(['chatMessage'])
            } else {
            }
        }
    }

    function onSupportOptionChanged(value) {
        let currentVal = document.getElementById('chatMessage').value;
        if (currentVal === "" || currentVal === " ") {
            form.setFieldsValue({
                'chatMessage': value[1]
            });
        } else if (currentVal !== "" || currentVal !== " ") {
            form.setFieldsValue({
                'chatMessage': document.getElementById('chatMessage').value + "\n\n" + value[1]
            });
        }

        form.resetFields(['macrosCascader'])

        form.resetFields(props.helpMacros.map(option => option.title));
        form.resetFields(props.bugMacros.map(option => option.title));
        form.resetFields(props.moderationMacros.map(option => option.title));
        form.resetFields(props.outreachMacros.map(option => option.title));
        form.resetFields(props.reportMacros.map(option => option.title));
    }

    function getCascaderOptions() {
        return [
            {
                "value": "Help",
                "label": "Help",
                "children":
                    props.helpMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Bug",
                "label": "Bug",
                "children":
                    props.bugMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Moderation",
                "label": "Moderation",
                "children":
                    props.moderationMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Report",
                "label": "Report",
                "children":
                    props.reportMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Outreach",
                "label": "Outreach",
                "children":
                    props.outreachMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            }]
    }

    const cascader_options = getCascaderOptions()

    return (
        <div>
                <div className={'chat-only-input'}>
                    <Form
                        form={form}
                        onFinish={onSubmitChat}
                        initialValues={{
                            chatMessage: ''
                        }}>
                        <Row className={'dd'}>
                            <Col span={24}>
                                <Form.Item name="macrosCascader">
                                    <Cascader options={cascader_options} onChange={onSupportOptionChanged} showSearch={{
                                        filter: (inputValue, path) => {
                                            return (path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1))
                                        }
                                    }} placeholder="Select Macro"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Form.Item name='chatMessage'>
                                <TextArea autoSize={{ minRows: 8, maxRows: 20 }} value={""}/>
                            </Form.Item>
                            <Button type="primary" htmlType="button" onClick={markMessageAsRead}>Mark Messages as
                                Read</Button>
                            <Button className={"chat-send-button"} type="primary" style={{float: "right"}}
                                    htmlType="submit" icon={<SendOutlined />}>Send</Button>
                        </div>
                    </Form>
                </div>
            </div>
    );


}

const actionCreators = {
    startGetRoomMessages: chatonlyActions.getRoomMessages,
    postChatMessage: chatonlyActions.sendChatMessage,
    createChat: chatonlyActions.createChatAction,
    markMessagesAsRead: chatonlyActions.markAllRoomMessageAsReadAction
};

function mapStateToProps(state) {
    const {updatedRooms, deletedRooms, roomMessages} = state.chatonlyReducer;
    const {helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros} = state.macroReducer;
    return {updatedRooms, deletedRooms, roomMessages, helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros};
}

export default connect(mapStateToProps, actionCreators)(ChatOnlyInput);
