import {authHeader, chatAuthHeader} from '../Helpers';
import {config} from "../Config";
import {handleResponse} from "./authentication.service"

export const profileService = {
    getProfileWithUnreviewedPhoto,
    markProfilePhotosAsReviewedOnServer,
    deleteProfilePhotoOnServer,
    fetchReportedProfilesFromTheServer,
    getNewProfilesFromServer,
    getReviewedProfilesFromServer,
    getNewAutoProfilesFromServer,
    markNewProfileAsReviewedOnServer,
    searchProfilesOnServer,
    searchByPhoneNumberOnServer,
    searchByUsernameOnServer,
    getAccountMemberOnServer,
    getProfileByIdOnServer,
    getProfilesByIdsOnServer,
    createNoteOnServer,
    patchNoteOnServer,
    deleteNoteOnServer,
    markReportAsReviewedOnServer,
    getProfileWithAccountIdOnServer,
    searchByHashtagOnServer,
    deleteAboutMe,
    deleteUsername,
    getTechIssues,
    getBillingIssues,
    getModIssues,
    getAndroidIssues,
    getExpandedProfile,
    addSparks,
    addMonths,
    toggleBugsy,
    resetInteractions,
    getBugsyProfiles,
    getAllSubscribers,
    getAllMSubscribers,
    getAllFSubscribers,
    getAllXSubscribers,
    getAllMMSubscribers,
    getAllMFSubscribers,
    getAllMXSubscribers,
    getAllFFSubscribers,
    getAllFMSubscribers,
    getAllFXSubscribers,
    getAllXXSubscribers,
    getAllFSubscribersMore,
    toggleSubscription,
    reportMoreInfo
};

function getProfileWithAccountIdOnServer(accountID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountID}`, requestOptions).then(handleResponse);
}


function getProfileWithUnreviewedPhoto() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/photos`, requestOptions).then(handleResponse);
}

function getNewProfilesFromServer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profiles/new`, requestOptions).then(handleResponse);
}

function getReviewedProfilesFromServer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profiles/last-reviewed`, requestOptions).then(handleResponse);
}

function getNewAutoProfilesFromServer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profiles/new-auto`, requestOptions).then(handleResponse);
}

function getBugsyProfiles() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profiles/by-debug`, requestOptions).then(handleResponse);
}

function markNewProfileAsReviewedOnServer(profileId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}/review`, requestOptions).then(handleResponse);
}

function markProfilePhotosAsReviewedOnServer(profileId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}/photo/review`, requestOptions).then(handleResponse);
}

function deleteProfilePhotoOnServer(profileId, photoId, params) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}/photo/${photoId}`, requestOptions).then(handleResponse);
}

function fetchReportedProfilesFromTheServer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/reports`, requestOptions).then(handleResponse);
}

function searchProfilesOnServer(searchBody) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(searchBody)
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function searchByPhoneNumberOnServer(phoneNumber) {
    const cleanPhoneNumber = phoneNumber.trim()
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            phone_number: cleanPhoneNumber
        })
    };
    return fetch(`${config.baseURL}/admin/accounts`, requestOptions);
}

function searchByHashtagOnServer(hashtag, preferenceSearch, boundarySearch) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            hashtag: {
                hashtag: hashtag,
                include_preferences: preferenceSearch,
                include_boundaries: boundarySearch
            }
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function searchByUsernameOnServer(username) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            username: username
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}


function getAllSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: ""
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}


function getAllMSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "M"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllFSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "F"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllFSubscribersMore(skippedIds){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            skip: skippedIds,
            subscribers: "F"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllXSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "X"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllFXSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "M"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllXXSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "M"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}
function getAllMMSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "M"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllMFSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "MF"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllMXSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "MX"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllFFSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "FF"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAllFMSubscribers() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            subscribers: "FM"
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions);
}

function getAccountMemberOnServer(memberId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/member/${memberId}`, requestOptions).then(handleResponse);
}

function getProfileByIdOnServer(profileIds) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            ids: [profileIds]
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions).then(handleResponse);
}

function getProfilesByIdsOnServer(profileIds) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            ids: profileIds
        })
    };
    return fetch(`${config.baseURL}/admin/profiles`, requestOptions).then(handleResponse);
}

function createNoteOnServer(accountId, content, tags) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            content: content,
            tags: tags
        })
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/note`, requestOptions).then(handleResponse);
}

function patchNoteOnServer(accountId, noteId, content, tags, active) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({
            content: content,
            tags: tags,
            active: active
        })
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/note/${noteId}`, requestOptions).then(handleResponse);
}

function deleteNoteOnServer(accountId, noteId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/note/${noteId}`, requestOptions).then(handleResponse);
}

function markReportAsReviewedOnServer(reportId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/report/${reportId}/review`, requestOptions).then(handleResponse);
}

function deleteAboutMe(profileId, params) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}`, requestOptions).then(handleResponse);
}

function deleteUsername(profileId, params) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}`, requestOptions).then(handleResponse);
}

function getTechIssues() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            tags: ['tech']
        })
    };
    return fetch(`${config.baseURL}/admin/profiles/by-tag`, requestOptions).then(handleResponse);
}

function getModIssues() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            tags: ['moderation']
        })
    };
    return fetch(`${config.baseURL}/admin/profiles/by-tag`, requestOptions).then(handleResponse);
}

function getBillingIssues() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            tags: ['billing']
        })
    };
    return fetch(`${config.baseURL}/admin/profiles/by-tag`, requestOptions).then(handleResponse);
}

function getAndroidIssues() {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            tags: ['android']
        })
    };
    return fetch(`${config.baseURL}/admin/profiles/by-tag`, requestOptions).then(handleResponse);
}

function getExpandedProfile(profileId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}`, requestOptions).then(handleResponse);
}

function addSparks(accountId, sprks){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            number: sprks
        })
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/sparks`, requestOptions).then(handleResponse);
}

function toggleSubscription(accountId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/toggle-subscription`, requestOptions).then(handleResponse);
}

function addMonths(accountId, mnths){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            months: mnths
        })
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/subscription`, requestOptions).then(handleResponse);
}

function toggleBugsy(accountId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/toggle-debug`, requestOptions).then(handleResponse);
};

function resetInteractions(accountId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/reset-interactions`, requestOptions).then(handleResponse);
};

function reportMoreInfo(reportId, macroId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            macro_id: macroId
        })
    };
    return fetch(`${config.baseURL}/admin/report/${reportId}/more-info`, requestOptions).then(handleResponse);
}