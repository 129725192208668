import React, {useState} from 'react';
import {Button, Input, Tooltip, Select, Popover, Switch, Image, Typography} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import _ from "lodash"
import ChatServerInfo from "../ChatServerInfo";
import {QuestionCircleFilled, QuestionCircleOutlined, RightCircleOutlined} from "@ant-design/icons";
import appsflyerIcon from "../../assets/appsflyer.png";
import subscribedIcon from "../../assets/subscribed_active.png";
import facebookIcon from "../../assets/facebook.png";
import uuid from "react-uuid";

const {Search} = Input;
const {Text} = Typography;
const {Option} = Select;

function SearchProfile(props) {
    const profileDispatch = useDispatch()
    const searchingProfile = useSelector(state => state.profileReducer.searchingProfile)
    const [open, setOpen] = useState(false);
    const [hideAdvSearch, setHideAdvSearch] = useState(true);

    const [subscribedState, setSubscribed] = useState(false);
    const [appsflyerState, setAppsflyer] = useState(false);
    const [facebookState, setFacebook] = useState(false);
    const [fmxState, setFmx] = useState("");

    const toggleSubscribed = () => {
        setSubscribed(!subscribedState)
    }

    const toggleAppsflyer = () => {
        setAppsflyer(!appsflyerState)
    }

    const toggleFacebook = () => {
        setFacebook(!facebookState)
    }

    const setFMX = (value) => {
        setFmx(value)
    }

    function onTextSearched(text) {
        profileDispatch(profileActions.searchProfileAction(text, true, true, fmxState, subscribedState, facebookState, appsflyerState));
    }

    const appsflyer = <Image src={appsflyerIcon} alt={"appsflyerIcon"} className="search-toggle-icon" preview={false}/>
    const facebook = <Image src={facebookIcon} alt={"facebookIcon"} className="search-toggle-icon" preview={false}/>
    const subscribed = <Image src={subscribedIcon} alt={"subscribedIcon"} className="search-toggle-icon"
                              preview={false}/>

    const searchHelp = (<div>
        <p>Enter a phone number or a username to perform a search by either.</p>
        <p>Prefix a search with # to perform a hashtag search (e.g. "#friends")</p>

        <p>
            <Text strong>Wildcards</Text><br/>
            <b>%</b> matches any sequence of 0 or more characters<br/>
            <b>!</b> matches any single character
        </p>

        <p>
            <Text strong>Additional options</Text><br/>
            Click <RightCircleOutlined/> for additional search options.
        <br/><br/>
            <Text type="secondary">Note: Additional options are not available for phone number searches.</Text>
        </p>
    </div>)

    const clickDrawer = () => {
        setOpen(!open);
        setHideAdvSearch(open);
    };

    return <>
        <Tooltip placement={"right"} title={"Portal Guide"}>
            <Button key={uuid()} className={"help-button"} type={"primary"} shape="circle" disabled={false}
                    href={"https://docs.google.com/document/d/1V18GJNatc7NBxMiTtemLRXvNCLzV59kMlZXelplZ2OE/edit"}
                    target={"_blank"} icon={<QuestionCircleFilled/>}></Button>
        </Tooltip>

        <Search
            className={"search-input"}
            style={{width: '35%'}}
            placeholder="Search with username or phone number"
            enterButton="Search"
            allowClear={true}
            loading={searchingProfile}
            onSearch={onTextSearched}
        />

        <Popover placement="bottomRight" title={"Search Help"} content={searchHelp} trigger="click">
            <Tooltip placement={"bottom"} title={"Search help"}>
                <Button key={uuid()} className={"search-help-button"} icon={<QuestionCircleOutlined/>}></Button>
            </Tooltip>
        </Popover>

        <div className="search-drawer">
            <Tooltip placement={"bottom"} title={"Search options"}>
                <Button key={uuid()} className={"adv-search-button" + (open ? " adv-search-open" : " adv-search-closed")}
                        icon={<RightCircleOutlined/>} onClick={clickDrawer}>
                </Button>
            </Tooltip>
            <span className={"search-advanced-controls"} hidden={hideAdvSearch}>
                <Tooltip placement={"bottom"} title={"Subscribers"}>
                <Switch key={uuid()} checkedChildren={subscribed} unCheckedChildren={subscribed} className={"search-toggle-switch"} onClick={toggleSubscribed}/>
                </Tooltip>
                <Tooltip placement={"bottom"} title={"From Appsflyer"}>
                <Switch key={uuid()} checkedChildren={appsflyer} unCheckedChildren={appsflyer} className={"search-toggle-switch"} onClick={toggleAppsflyer}/>
                </Tooltip>
                <Tooltip placement={"bottom"} title={"From Facebook"}>
                <Switch key={uuid()} checkedChildren={facebook} unCheckedChildren={facebook} className={"search-toggle-switch"} onClick={toggleFacebook}/>
                </Tooltip>
                <Select listHeight={320} defaultValue="" size="small" className="search-select-fmx" onChange={setFMX}>
                    <Option value="">FMX</Option>
                    <Option value="F">F </Option>
                    <Option value="M">M </Option>
                    <Option value="X">X </Option>
                    <Option value="FM">FM</Option>
                    <Option value="FF">FF</Option>
                    <Option value="FX">FX</Option>
                    <Option value="MM">MM</Option>
                    <Option value="MX">MX</Option>
                    <Option value="XX">XX</Option>
                </Select>
            </span>
        </div>

        <span className={"nav-right-buttons"}><ChatServerInfo/></span>
    </>
}

export default SearchProfile