import React from 'react';
import {Layout} from 'antd';
import ProfileMenu from "../ProfileMenu";
import SearchProfile from "../SearchProfile";
import Alert from "../Alert/Alert";

const {Header, Content} = Layout;

const Landing = (Component) => (props) => {

    return <>
        <div id={"components-layout-demo-responsive"}>
            <Layout className="site-layout" style={{marginLeft: 0}}>
                <Alert {...props}/>
                <Header className="site-layout-sub-header-background search-header">
                    <SearchProfile {...props}/>
                </Header>
                <ProfileMenu {...props}/>
                {/*<Sider*/}
                {/*    style={{*/}
                {/*        overflow: 'auto',*/}
                {/*        height: '100vh',*/}
                {/*        position: 'fixed',*/}
                {/*        backgroundColor: '#ffffff',*/}
                {/*        left: 0,*/}
                {/*    }}*/}
                {/*    collapsed={true}*/}
                {/*    breakpoint="lg"*/}
                {/*    onBreakpoint={broken => {*/}
                {/*        console.log(broken);*/}
                {/*    }}*/}
                {/*    onCollapse={(collapsed, type) => {*/}
                {/*        console.log(collapsed, type);*/}
                {/*    }}>*/}
                {/*    <div className="logo"> <Image src={logoIcon} preview={false} height={45} width={45}/> </div>*/}
                {/*    <Menu theme="light" mode="inline" defaultSelectedKeys={['11']}>*/}
                {/*        <Menu.Item key="11" icon={<UserOutlined /> }>*/}
                {/*            <LinkComponent to={"/unreviewed"}> Profiles </LinkComponent>*/}
                {/*        </Menu.Item>*/}
                {/*    </Menu>*/}
                {/*</Sider>*/}
                <Layout style={{paddingTop: 96}}>
                    <Content style={{margin: '8px 8px 0', overflow: 'initial'}}>
                        <div className="site-layout-background" style={{padding: 8, minHeight: '100vh'}}>
                            <Component {...props}/>
                        </div>
                    </Content>
                    {/*<Footer style={{textAlign: 'center'}}> ©2021 Created by Dev</Footer>*/}
                </Layout>
            </Layout>
        </div>
    </>

}

// const actionCreators = {
// };
//
// function mapStateToProps(state) {
//     const { profiles } = state.profileReducer
//     return { profiles };
// }
//
//
// export default connect(mapStateToProps, {})(Landing);

export default Landing;
