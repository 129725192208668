import { authenticationConstants} from "../Constants";
import { authenticationService } from "../Services";
import {alertActions} from "../Actions";
import {redirectToNewProfile} from "../../route/Redirect";


export const authenticationActions = {
    login,
    logout
};

function login(idToken) {
    return dispatch => {
        dispatch(request({ }));

        authenticationService.login(idToken)
            .then(_ => {
                    redirectToNewProfile().then(r => "")
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(sessionData) { return { type: authenticationConstants.LOGIN_REQUEST, sessionData } }
    function failure(error) { return { type: authenticationConstants.LOGIN_FAILURE, error } }
}

function logout() {
    //authenticationService.logout();
    //return { type: authenticationService.LOGOUT };
}