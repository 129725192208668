import { mlLabelConstants } from '../Constants'

const INIT_STATE = {
    labels: [],
    fetchingLabels: false,
    addingLabel: false
}

export function mlLabelReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case mlLabelConstants.GET_LABELS_REQUEST:
            return {
                ...state,
                fetchingLabels: true,
            }
        case mlLabelConstants.GET_LABELS_SUCCESS:
        
        const fetchedLabels = action.labels;

        return {
            ...state,
            fetchingLabels: false,
            labels: fetchedLabels
        }
        case mlLabelConstants.GET_LABELS_FAILURE:
        return {
            ...state,
            fetchingLabels: false
        }
        case mlLabelConstants.ADD_LABEL_REQUEST:
        return {
            ...state,
            addingLabel: true
        }
        case mlLabelConstants.ADD_LABEL_SUCCESS:
        
        const labelArray = [...state.labels];
        labelArray.push(action.newLabel);

        return {
            ...state,
            addingLabel: false,
            labels: labelArray
        }
        case mlLabelConstants.ADD_LABEL_FAILURE:
        return {
            ...state,
            addingLabel: false
        }
        default:
            return state;
    }
}