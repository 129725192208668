import React, {useState} from 'react';
import {Formik} from "formik";
import {
    Button,
    Switch,
    Tag,
    Image,
    InputNumber,
    Avatar,
    Card,
    Divider,
    Typography,
    Tooltip,
    Input,
    Col,
    Row
} from "antd";
import {FormGroup} from "reactstrap";
import * as Yup from "yup";
import {uuid} from "uuidv4";
import {useDispatch, useSelector} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import {ForwardOutlined} from "@ant-design/icons";
import Container from 'react-bootstrap/Container';
import androidIcon from '../../assets/android.png';
import appleIcon from "../../assets/apple.png";
import unknown from '../../assets/device_unknown.svg';
import _, {capitalize} from "lodash";
import {connect} from "react-redux";
import moment from "moment";
import appStoreIcon from "../../assets/app-store.png";
import googlePlayIcon from "../../assets/google-play.png";
import subscribedIcon from "../../assets/subscribed_active.png";
import unsubscribedIcon from "../../assets/subscribed_inactive.png";
import sparksIcon from "../../assets/sparks.png";
import openLogo from "../../assets/logo.svg";


function TabPremium(props) {
    const {Text} = Typography;
    const {profile, expandedProfile} = props
    const {Paragraph, Title} = Typography;
    const {Meta} = Card;

    const apple = <Image src={appleIcon} alt={"appleIcon"} className="table-icon" preview={false}/>
    const android = <Image src={androidIcon} alt={"androidIcon"} className="table-icon" preview={false}/>
    const deviceUnknown = <Image src={unknown} alt={"unknown"} className="table-icon" preview={false}/>
    const subscription_active = <Image src={subscribedIcon} alt={"subscribedIcon"}
                                       preview={false}/>
    const unsubscribed_active = <Image src={unsubscribedIcon} alt={"unsubscribedIcon"}
                                       preview={false}/>

    const open = <Tooltip title={"Comped"}><Image src={openLogo} alt={"openLogo"} preview={false}/></Tooltip>
    const google_play = <Tooltip title={"Google Play"}><Image src={googlePlayIcon} alt={"Google Play"}
                                                              className="premium-tab-store-icon"
                                                              preview={false}/></Tooltip>

    const app_store = <Tooltip title={"App Store"}><Image src={appStoreIcon} alt={"App Store"}
                                                          className="premium-tab-store-icon"
                                                          preview={false}/></Tooltip>
    const sparks = <Tooltip title={"Sparks"}><Image src={sparksIcon} alt={"sparksIcon"} preview={false}/></Tooltip>

    function formatSubDate(date, icon) {
        if (moment.utc().local().diff(moment.utc(date)) <= 0) {
            return <Text type="success">{icon} {"Expires " + moment.utc(date).local().format('MMM Do, y')} </Text>
        } else {
            return <Text type="danger">{icon} {"Expired " + moment.utc(date).local().format('MMM Do, y')} </Text>
        }
    }


    function getPlatformIcon(member) {
        if (member?.account?.operating_system === "iOS") {
            return apple
        } else if (member.account?.operating_system === "Android") {
            return android
        } else {
            return deviceUnknown
        }
    }

    function getSubStatusIcon(member) {
        if (member.account?.subscribed) {
            return subscription_active
        } else {
            return unsubscribed_active
        }
    }

    function getGoogleSubExpiration(member) {
        if (member.account?.google_play_subscription_expiration) {
            return <div className={"member-premium-section"}>
                {formatSubDate(member.account?.google_play_subscription_expiration, google_play)}
            </div>
        } else {
            return ""
        }
    }

    function getAppStoreSubExpiration(member) {
        if (member.account?.ios_subscription_expiration) {
            return <div className={"member-premium-section"}>
                {formatSubDate(member.account?.ios_subscription_expiration, app_store)}
            </div>
        } else {
            return ""
        }
    }

    function getCompedSubExpiration(member) {
        if (member.account?.comped_subscription_expiration) {
            return <div className={"member-premium-section"}>
                {formatSubDate(member.account?.comped_subscription_expiration, open)}
            </div>
        } else {
            return null
        }
    }

    function getSubStatusText(member) {
        if (member.account?.subscribed) {
            return "Subscribed"
        } else {
            return "Not Subscribed"
        }
    }

    const member1 = profile.members[0] ?? [];
    const member2 = profile.members[1] ?? [];

    const [mem1Months, setMem1Months] = useState(0);
    const [mem1Sparks, setMem1Sparks] = useState(0);
    const [mem2Months, setMem2Months] = useState(0);
    const [mem2Sparks, setMem2Sparks] = useState(0);

    function addSparks(aId, event) {
        const {value: inputValue} = event.target;
        props.addSparks(aId, inputValue);
    }

    function addMonths(aId, event) {
        const {value: inputValue} = event.target;
        props.addMonths(aId, inputValue);
    }

    function toggleSub(aId) {
        props.toggleSubscription(aId);
    }

    return (
        <div style={{height: 474, overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none', width: "100%"}}>
            {member1.account ? <Card className="member-premium-card">
                <Meta
                    avatar={getPlatformIcon(member1)}
                    title={member1.account?.phone_number}
                />
                <Divider orientation="left"
                         orientationMargin="0.5em">{getSubStatusIcon(member1)} {getSubStatusText(member1)}</Divider>
                <Paragraph><Button onClick={() => toggleSub(member1.account?.id)} type="primary"
                                   size="small">Toggle</Button></Paragraph>
                {getAppStoreSubExpiration(member1)}
                {getGoogleSubExpiration(member1)}
                {getCompedSubExpiration(member1)}
                <Paragraph style={{marginTop: "0.5em"}}>
                    <Input.Group compact>
                        <Input size="small"
                               style={{
                                   width: '12em',
                               }}
                               className="member-premium-input"
                               onChange={(n) => setMem1Months(n)}
                               defaultValue={member1.account?.comped_subscription_months}
                               addonAfter="comped months"
                        />
                        <Button onClick={() => addMonths(member1.account?.id, mem1Months)} type="primary" size="small"
                                icon={<ForwardOutlined/>}/>
                    </Input.Group>
                </Paragraph>

                <Divider orientation="left" orientationMargin="0.5em">{sparks} {member1.account?.sparks ?? 0}</Divider>
                <Paragraph>
                    {member1.account?.sparks_subscription + " subscription"} <br/>
                    {member1.account?.sparks_purchased + " purchased"} <br/>
                    <Input.Group compact>
                        <Input size="small"
                               style={{
                                   width: '8em',
                               }}
                               onChange={(n) => setMem1Sparks(n)}
                               defaultValue={member1.account?.sparks_comped}
                               addonAfter="comped"
                        />
                        <Button onClick={() => addSparks(member1.account?.id, mem1Sparks)} type="primary" size="small"
                                icon={<ForwardOutlined/>}/>
                    </Input.Group>
                </Paragraph>
            </Card> : null}
            {profile.members.length > 1 && member2.account ?
                <Card className="member-premium-card">
                    <Meta
                        avatar={getPlatformIcon(member2)}
                        title={member2.account?.phone_number}
                    />
                    <Divider orientation="left"
                             orientationMargin="0.5em">{getSubStatusIcon(member2)} {getSubStatusText(member2)}</Divider>
                    <Paragraph><Button onClick={() => toggleSub(member2.account?.id)} type="primary"
                                       size="small">Toggle</Button></Paragraph>
                    {getAppStoreSubExpiration(member2)}
                    {getGoogleSubExpiration(member2)}
                    {getCompedSubExpiration(member2)}
                    <Paragraph style={{marginTop: "0.5em"}}>
                        <Input.Group compact>
                            <Input size="small"
                                   style={{
                                       width: '12em',
                                   }}
                                   onChange={(n) => setMem2Months(n)}
                                   defaultValue={member1.account?.comped_subscription_months}
                                   addonAfter="months comped"
                            />
                            <Button onClick={() => addMonths(member2.account?.id, mem2Months)} type="primary"
                                    size="small" icon={<ForwardOutlined/>}/>
                        </Input.Group>
                    </Paragraph>


                    <Divider orientation="left"
                             orientationMargin="0.5em">{sparks} {member2.account?.sparks ?? 0}</Divider>
                    <Paragraph>
                        {member2.account?.sparks_subscription + " subscription"} <br/>
                        {member2.account?.sparks_purchased + " purchased"} <br/>
                        <Input.Group compact>
                            <Input size="small"
                                   style={{
                                       width: '8em',
                                   }}
                                   onChange={(n) => setMem2Sparks(n)}
                                   defaultValue={member2.account?.sparks_comped}
                                   addonAfter="comped"
                            />
                            <Button onClick={() => addSparks(member2.account?.id, mem2Sparks)} type="primary"
                                    size="small" icon={<ForwardOutlined/>}/>
                        </Input.Group>
                    </Paragraph>
                </Card> : null
            }

            <br/>

        </div>
    );
}

const actionCreators = {
    addSparks: profileActions.addSparks,
    addMonths: profileActions.addMonths,
    toggleSubscription: profileActions.toggleSubscription
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, actionCreators)(TabPremium);

