import React from 'react';
import {Formik} from "formik";
import {Button, Col, Row, Switch, Tag} from "antd";
import {FormGroup, Input} from "reactstrap";
import * as Yup from "yup";
import {uuid} from "uuidv4";
import {useDispatch, useSelector} from "react-redux";
import {profileActions} from "../../appRedux/Actions";

function TabPreferences(props) {
    const searchedText = useSelector(state => state.profileReducer.searchedText) ?? ""
    const dispatcher = useDispatch()

    const validate = Yup.object({
        email: Yup.string().email('Invalid email address').required('User email address is required'),
        password: Yup.string().required('User password required.'),
    });

    const {profile, expandedProfile} = props
    const {preference_lists} = expandedProfile
    const {boundary_lists} = expandedProfile

    const triggerPreferenceSearch = (searchedText) => {
        dispatcher(profileActions.searchProfileAction(`#${searchedText}`, true, false))

    }

    const triggerBoundarySearch = (searchedText) => {
        dispatcher(profileActions.searchProfileAction(`#${searchedText}`, false, true))
    }

    return (
        <div className={"expanded-profile-hashtag-container"}>

            <Formik
                initialValues={{email: '', password: ''}}
                validationSchema={validate}
                onSubmit={(values, {setSubmitting}) => {
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    // }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <div className={"inner inner-sec"}>
                            <h5>Preferences</h5>
                            {preference_lists?.map(preference => (
                                <div key={uuid()}>
                                    <Row>
                                        <Col span={20}>
                                            <div><h6> {preference.name}</h6></div>
                                            <div className={"expanded-tag-list"}>
                                                {preference.preference_instances?.map(item => {
                                                    let tagColor = "";
                                                    if (searchedText === `#${item}`) {
                                                        tagColor = "blue"
                                                    } else if (preference.unreviewed_preference_instances.includes(item)) {
                                                        tagColor = "orange"
                                                    }
                                                    return <Tag onClick={() => triggerPreferenceSearch(item)} key={item}
                                                                size={'small'}
                                                                className="tag-clickable"
                                                                color={tagColor}> #{item} </Tag>
                                                })}
                                            </div>
                                        </Col>
                                        <Col span={2}>
                                            <Switch defaultChecked size="small" checked={preference.visible}/>
                                            {/*<DeleteFilled />*/}
                                        </Col>
                                    </Row>
                                    <hr/>
                                </div>))}
                            <h5>Boundaries</h5>
                            {boundary_lists?.map(boundary => (
                                <div key={uuid()}>
                                    <Row>
                                        <Col span={20}>
                                            <div><h6> {boundary.name} </h6></div>
                                            <div className={"expanded-tag-list"}>
                                                {boundary.boundary_instances?.map(item => {
                                                    let tagColor = "";
                                                    if (searchedText === `#${item}`) {
                                                        tagColor = "blue"
                                                    } else if (boundary.unreviewed_boundary_instances.includes(item)) {
                                                        tagColor = "red"
                                                    }
                                                    return <Tag onClick={() => triggerBoundarySearch(item)} key={item}
                                                                size={'small'}
                                                                className="tag-clickable"
                                                                color={tagColor}> #{item} </Tag>
                                                })}

                                            </div>
                                        </Col>
                                        <Col span={2}>

                                            <Switch defaultChecked size="small" checked={boundary.visible}/>
                                            {/*<DeleteFilled />*/}
                                        </Col>
                                    </Row>
                                    <hr/>
                                </div>))}

                        </div>
                    </form>
                )}
            </Formik>

        </div>
    );
}

export default TabPreferences;
