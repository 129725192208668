export const profileConstants = {
    PROFILE_WITH_UNREVIEWED_PHOTO_REQUEST: 'PROFILE_WITH_UNREVIEWED_PHOTO_REQUEST',
    PROFILE_WITH_UNREVIEWED_PHOTO_SUCCESS: 'PROFILE_WITH_UNREVIEWED_PHOTO_SUCCESS',
    PROFILE_WITH_UNREVIEWED_PHOTO_FAILURE: 'PROFILE_WITH_UNREVIEWED_PHOTO_FAILURE',

    MARK_PROFILE_PHOTO_REVIEWED_REQUEST: 'MARK_PROFILE_PHOTO_REVIEWED_REQUEST',
    MARK_PROFILE_PHOTOS_REVIEWED_SUCCESS: 'MARK_PROFILE_PHOTOS_REVIEWED_SUCCESS',
    MARK_PROFILE_PHOTO_FAILURE: 'MARK_PROFILE_PHOTO_FAILURE',

    CHANGE_SELECTED_PHOTO_SUCCESS: 'CHANGE_SELECTED_PHOTO_SUCCESS',

    DELETE_PROFILE_PHOTO_REQUEST: 'DELETE_PROFILE_PHOTO_REQUEST',
    DELETE_PROFILE_PHOTO_SUCCESS: 'DELETE_PROFILE_PHOTO_SUCCESS',
    DELETE_PROFILE_PHOTO_FAILURE: 'DELETE_PROFILE_PHOTO_FAILURE',

    REPORTED_PROFILE_REQUEST: 'REPORTED_PROFILE_REQUEST',
    REPORTED_PROFILE_SUCCESS: 'REPORTED_PROFILE_SUCCESS',
    REPORTED_PROFILE_FAILURE: 'REPORTED_PROFILE_FAILURE',

    NEW_PROFILE_REQUEST: 'NEW_PROFILE_REQUEST',
    NEW_PROFILE_SUCCESS: 'NEW_PROFILE_SUCCESS',
    NEW_PROFILE_ERROR: 'NEW_PROFILE_ERROR',

    REVIEWED_PROFILE_REQUEST: 'REVIEWED_PROFILE_REQUEST',
    REVIEWED_PROFILE_SUCCESS: 'REVIEWED_PROFILE_SUCCESS',
    REVIEWED_PROFILE_ERROR: 'REVIEWED_PROFILE_ERROR',

    MARK_NEW_PROFILE_REQUEST: 'MARK_NEW_PROFILE_REQUEST',
    MARK_NEW_PROFILE_SUCCESS: 'MARK_NEW_PROFILE_SUCCESS',
    MARK_NEW_PROFILE_ERROR: 'MARK_NEW_PROFILE_ERROR',

    SEARCH_PROFILE_REQUEST: 'SEARCH_PROFILE_REQUEST',
    SEARCH_PROFILE_SUCCESS: 'SEARCH_PROFILE_SUCCESS',
    SEARCH_PROFILE_ERROR: 'SEARCH_PROFILE_ERROR',
    PROFILE_WITH_CHATS: 'PROFILE_WITH_CHATS',
    PROFILE_WITH_CHATS_REQUEST: 'PROFILE_WITH_CHATS_REQUEST',
    REMOVE_PROFILES_FROM_WITH_CHATS: 'REMOVE_PROFILES_FROM_WITH_CHATS',

    MORE_SEARCH_PROFILE_REQUEST: 'MORE_SEARCH_PROFILE_REQUEST',
    MORE_SEARCH_PROFILE_SUCCESS: 'MORE_SEARCH_PROFILE_SUCCESS',
    MORE_SEARCH_PROFILE_ERROR: 'MORE_SEARCH_PROFILE_ERROR',

    CREATE_NOTE_REQUEST: 'CREATE_NOTE_REQUEST',
    CREATE_NOTE_SUCCESS: 'CREATE_NOTE_SUCCESS',
    CREATE_NOTE_FAILURE: 'CREATE_NOTE_FAILURE',
    GET_NOTES_FROM_PROFILE: 'GET_NOTES_FROM_PROFILE',

    PATCH_NOTE_REQUEST: 'PATCH_NOTE_REQUEST',
    PATCH_NOTE_SUCCESS: 'PATCH_NOTE_SUCCESS',
    PATCH_NOTE_FAILURE: 'PATCH_NOTE_FAILURE',

    DELETE_NOTE_REQUEST: 'DELETE_NOTE_REQUEST',
    DELETE_NOTE_ERROR: 'DELETE_NOTE_ERROR',
    DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',

    MARK_REPORT_AS_REVIEWED: 'MARK_REPORT_AS_REVIEWED',

    NEW_AUTO_PROFILE_REQUEST: 'NEW_AUTO_PROFILE_REQUEST',
    NEW_AUTO_PROFILE_SUCCESS: 'NEW_AUTO_PROFILE_SUCCESS',
    NEW_AUTO_PROFILE_ERROR: 'NEW_AUTO_PROFILE_ERROR',


    CLEAR_AI_MOD_PROFILE_REQUEST: 'CLEAR_AI_MOD_PROFILE_REQUEST',
    CLEAR_AI_MOD_PROFILE_SUCCESS: 'CLEAR_AI_MOD_PROFILE_SUCCESS',
    CLEAR_AI_MOD_PROFILE_ERROR: 'CLEAR_AI_MOD_PROFILE_ERROR',

    TOGGLE_MODERATION_ACTIVE_REQUEST: 'TOGGLE_MODERATION_ACTIVE_REQUEST',
    TOGGLE_MODERATION_ACTIVE_SUCCESS: 'TOGGLE_MODERATION_ACTIVE_SUCCESS',
    TOGGLE_MODERATION_ACTIVE_ERROR: 'TOGGLE_MODERATION_ACTIVE_ERROR',

    DELETE_ABOUT_ME_REQUEST: 'DELETE_ABOUT_ME_REQUEST',
    DELETE_ABOUT_ME_SUCCESS: 'DELETE_ABOUT_ME_SUCCESS',
    DELETE_ABOUT_ME_ERROR: 'DELETE_ABOUT_ME_ERROR',

    DELETE_USERNAME_REQUEST: 'DELETE_USERNAME_REQUEST',
    DELETE_USERNAME_SUCCESS: 'DELETE_USERNAME_SUCCESS',
    DELETE_USERNAME_ERROR: 'DELETE_USERNAME_ERROR',

    GET_TECH_ISSUES_REQUEST: 'GET_TECH_ISSUES_REQUEST',
    GET_TECH_ISSUES_SUCCESS: 'GET_TECH_ISSUES_SUCCESS',
    GET_TECH_ISSUES_ERROR: 'GET_TECH_ISSUES_ERROR',

    GET_BILLING_ISSUES_REQUEST: 'GET_BILLING_ISSUES_REQUEST',
    GET_BILLING_ISSUES_SUCCESS: 'GET_BILLING_ISSUES_SUCCESS',
    GET_BILLING_ISSUES_ERROR: 'GET_BILLING_ISSUES_ERROR',

    GET_MOD_ISSUES_REQUEST: 'GET_MOD_ISSUES_REQUEST',
    GET_MOD_ISSUES_SUCCESS: 'GET_MOD_ISSUES_SUCCESS',
    GET_MOD_ISSUES_ERROR: 'GET_MOD_ISSUES_ERROR',

    GET_ANDROID_ISSUES_REQUEST: 'GET_ANDROID_ISSUES_REQUEST',
    GET_ANDROID_ISSUES_SUCCESS: 'GET_ANDROID_ISSUES_SUCCESS',
    GET_ANDROID_ISSUES_ERROR: 'GET_ANDROID_ISSUES_ERROR',

    GET_EXPANDED_PROFILE_REQUEST: 'GET_EXPANDED_PROFILE_REQUEST',
    GET_EXPANDED_PROFILE_SUCCESS: 'GET_EXPANDED_PROFILE_SUCCESS',
    GET_EXPANDED_PROFILE_ERROR: 'GET_EXPANDED_PROFILE_ERROR',

    ADD_MONTHS_REQUEST: 'ADD_MONTHS_REQUEST',
    ADD_MONTHS_SUCCESS: 'ADD_MONTHS_SUCCESS',
    ADD_MONTHS_ERROR: 'ADD_MONTHS_ERROR',
    
    ADD_SPARKS_REQUEST: 'ADD_SPARKS_REQUEST',
    ADD_SPARKS_SUCCESS: 'ADD_SPARKS_SUCCESS',
    ADD_SPARKS_ERROR: 'ADD_SPARKS_ERROR',

    TOGGLE_SUBSCRIPTION_REQUEST: 'TOGGLE_SUBSCRIPTION_REQUEST',
    TOGGLE_SUBSCRIPTION_SUCCESS: 'TOGGLE_SUBSCRIPTION_SUCCESS',
    TOGGLE_SUBSCRIPTION_ERROR: 'TOGGLE_SUBSCRIPTION_ERROR',

    TOGGLE_BUGSY_REQUEST: 'TOGGLE_BUGSY_REQUEST',
    TOGGLE_BUGSY_SUCCESS: 'TOGGLE_BUGSY_SUCCESS',
    TOGGLE_BUGSY_ERROR: 'TOGGLE_BUGSY_ERROR',

    RESET_INTERACTIONS_REQUEST: 'RESET_INTERACTIONS_REQUEST',
    RESET_INTERACTIONS_SUCCESS: 'RESET_INTERACTIONS_SUCCESS',
    RESET_INTERACTIONS_ERROR: 'RESET_INTERACTIONS_ERROR',

    GET_BUGSY_PROFILES_REQUEST: 'GET_BUGSY_PROFILES_REQUEST',
    GET_BUGSY_PROFILES_SUCCESS: 'GET_BUGSY_PROFILES_SUCCESS',
    GET_BUGSY_PROFILES_ERROR: 'GET_BUGSY_PROFILES_ERROR',
    
    GET_SUBSCRIBERS_REQUEST: 'GET_SUBSCRIBERS_REQUEST',
    GET_SUBSCRIBERS_SUCCESS: 'GET_SUBSCRIBERS_SUCCESS',
    GET_SUBSCRIBERS_ERROR: 'GET_SUBSCRIBERS_ERROR',

    REPORT_MORE_INFO_REQUEST: "REPORT_MORE_INFO_REQUEST",
    REPORT_MORE_INFO_SUCCESS: "REPORT_MORE_INFO_SUCCESS",
    REPORT_MORE_INFO_ERROR: "REPORT_MORE_INFO_ERROR"
};