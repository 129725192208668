import uuid from "react-uuid";
import React, {useEffect, useState} from "react";
import {Image, Col, Row, Typography, Tag} from 'antd';
import {moderationActions} from "../../appRedux/Actions";
import {connect} from "react-redux";

const {Text} = Typography

function ExpandedModeration(props) {

    const {record, profile} = props;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function getCreatorName(name) {
        const clean = record.creator.replace('@hashtagopen.com', '')
        return clean.charAt(0).toUpperCase() + clean.slice(1)
    }

    const fieldModifiedMap = {
        'photo': 'Photo',
        'about_value': 'About',
        'relationship_status_value': 'Relationship status',
        'relationship_type_value': 'Relationship type',
        'username_value': 'Username',
        'preference_tag': 'Preference tag',
        'boundary_tag': 'Boundary tag',
        'display_name': 'Display name',
        'orientation_value': 'Orientation',
        'gender_value': 'Gender',
        'pronouns_value': 'Pronouns'
    }

    return (
        <div style={{margin: '0'}}>
            <Row style={{marginBottom: '12px'}} gutter={16}>
                <Col span={6}>
                    <Text type="secondary">Creator</Text><br/>
                    <Text>{record.creator ? getCreatorName(record.creator) : 'N/A'}</Text>
                </Col>
                <Col span={18}>
                    <Text type="secondary">Reason</Text><br/>
                    <Text>{record.reason}</Text>
                </Col>
            </Row>
            <Row style={{marginBottom: '12px'}} gutter={16}>
                <Col span={24}>
                    <Text type="secondary">Tags</Text><br/>
                    {record.tags?.map(tag => {
                        return (
                            <Tag key={uuid()}>
                                {tag}
                            </Tag>
                        );
                    })}
                </Col>
            </Row>
            {record.field_modified ?
                <div>
                    <Row gutter={16}>
                        {record.original_value ?
                            <Col>
                                <Text
                                    type="secondary">Original {fieldModifiedMap[record.field_modified] ?? record.field_modified}</Text><br/>
                                {record.field_modified === "photo" ?
                                    <Image height="90px" width="auto" src={record.original_value}/>
                                    : <Text>{record.original_value}</Text>}
                            </Col>
                            : null}
                        {record.new_value ?
                            <Col flex={"auto"}>
                                <Text
                                    type="secondary">New {fieldModifiedMap[record.field_modified] ?? record.field_modified}</Text><br/>
                                <Text>{record.new_value}</Text>
                            </Col>
                            : null}
                    </Row>
                </div>
                : null}
        </div>
    )
}

const actionCreators = {
    getModerationByProfile: moderationActions.getModerationOnProfile,
    createModeration: moderationActions.createModerationWithProfileAction,
    patchModification: moderationActions.patchAccountModerationWithId,
    deleteModeration: moderationActions.deleteModerationByAccountId
};

function mapStateToProps(state) {
    const {
        creatingModerationInProgress,
        fetching,
        moderationList,
        profileModerationList,
        moderation
    } = state.moderationReducer
    return {creatingModerationInProgress, fetching, moderationList, profileModerationList, moderation};
}

export default connect(mapStateToProps, actionCreators)(ExpandedModeration);