import React from 'react';

const ChatOnly = (Component) => (props) => (
    <>
        <div>
            <Component {...props}/>
        </div>
    </>
)

export default ChatOnly;
