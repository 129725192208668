export const chatConstants = {
    CHAT_LOGIN_SUCCESS: 'CHAT_LOGIN_SUCCESS',

    UNREAD_MESSAGE_COUNT: 'UNREAD_MESSAGE_COUNT',
    FETCH_ROOM_REQUEST: 'FETCH_ROOM_REQUEST',
    FETCH_ROOM_SUCCESSFUL: 'FETCH_ROOM_SUCCESSFUL',
    FETCH_ROOM_ERROR: 'FETCH_ROOM_ERROR',

    ROOM_CHANGE_SUCCESSFUL: 'ROOM_CHANGE_SUCCESSFUL',

    FETCH_ROOM_MESSAGE_REQUEST: 'FETCH_ROOM_MESSAGE_REQUEST',
    FETCH_ROOM_MESSAGE_SUCCESSFUL: 'FETCH_ROOM_MESSAGE_SUCCESSFUL',
    FETCH_ROOM_MESSAGE_ERROR: 'FETCH_ROOM_MESSAGE_ERROR',

    CREATE_CHAT_REQUEST: 'CREATE_CHAT_REQUEST',
    CREATE_CHAT_SUCCESSFUL: 'CREATE_CHAT_SUCCESSFUL',
    CREATE_CHAT_FAILURE: 'CREATE_CHAT_FAILURE',

    SEND_CHAT_MESSAGE_REQUEST: 'SEND_CHAT_MESSAGE_REQUEST',
    SEND_CHAT_MESSAGE_SUCCESSFUL: 'SEND_CHAT_MESSAGE_SUCCESSFUL',
    SEND_CHAT_MESSAGE_ERROR: 'SEND_CHAT_MESSAGE_ERROR',
    NEW_CHAT_MESSAGE_RECEIVED: 'NEW_CHAT_MESSAGE_RECEIVED',

    CLEAR_CREATED_ROOM: 'CLEAR_CREATED_ROOM',
    CHAT_SERVER_STATUS: 'CHAT_SERVER_STATUS',

    FIND_SUPPORT_ROOM_SUCCESS: "FIND_SUPPORT_ROOM_SUCCESS",
    FIND_SUPPORT_ROOM_ERROR: "FIND_SUPPORT_ROOM_ERROR"


};