import React, {useEffect, useState} from "react";
import {Switch} from 'antd';
import {moderationActions} from "../../appRedux/Actions";
import {connect} from "react-redux";

function ModSwitch(props) {

    const { record, profile } = props;
    const [checked, setChecked] = useState(record.active);
    const toggleActive = (record) => {
        record.active ? 
            props.patchModification(record.account_id, record.id, profile.id, {tags: record.tags, active: false, reason: record.reason}) : 
                props.patchModification(record.account_id, record.id, profile.id, {tags: record.tags, active: true, reason: record.reason});
        // end conditional
        props.getModerationByProfile(profile);
        record.active ? setChecked(false) : setChecked(true);
    }

    return <Switch 
    size="small" 
    checked={checked}
    onClick={() => toggleActive(record)}
    />
}

const actionCreators = {
    getModerationByProfile: moderationActions.getModerationOnProfile,
    createModeration: moderationActions.createModerationWithProfileAction,
    patchModification: moderationActions.patchAccountModerationWithId,
    deleteModeration: moderationActions.deleteModerationByAccountId
};

function mapStateToProps(state) {
    const { creatingModerationInProgress, fetching, moderationList, profileModerationList, moderation } = state.moderationReducer
    return { creatingModerationInProgress, fetching, moderationList, profileModerationList, moderation };
}

export default connect(mapStateToProps, actionCreators)(ModSwitch);