import React, {useState} from 'react';
import {Col, Row, Switch, Popconfirm, Cascader, Select} from "antd";
import {FormGroup, Label, Button} from "reactstrap";
import {profileActions} from "../../appRedux/Actions";
import {storageService} from "../../appRedux/Storage";
import {storageConstants} from "../../appRedux/Constants/storage.constants";
import _, {capitalize} from "lodash";
import {connect} from "react-redux";

import { Input } from 'antd';

function TabProfile(props) {

    const { TextArea } = Input;
    const profile = props.profile;
    const expandedProfile = props.expandedProfile;

    const {Option} = Select;
    
    const [switchReadOnlyAbout, setSwitchReadOnlyAbout] = useState(true);
    const [switchReadOnlyUsername, setSwitchReadOnlyUsername] = useState(true);
    const [aboutValue, setAboutValue] = useState('');
    const [usernameValue, setUsernameValue] = useState('');

    const editAboutMe = () => {
            enableDisabledAbout();
            setSwitchReadOnlyAbout(false);
    }

    const editAboutMe2 = () => {
        disableDisabledAbout();
        setSwitchReadOnlyAbout(true);
    }

    const editUsername = () => {
        setSwitchReadOnlyUsername(false);
    }

    const editUsername2 = () => {
        setSwitchReadOnlyUsername(true);
    }

    const [usernameModerationAction, showUsernameModerationAction] = useState(true);
    const [aboutModerationAction, showAboutModerationAction] = useState(true);
    const storedModerationTags = storageService.getFromStore(storageConstants.MODERATION_TAGS) ?? []
    let options = []
    const types = [
        {
            value: 'Free',
            label: 'Free',
        },
        {
            value: 'Warning',
            label: 'Warning',
        },
        {
            value: 'Strike',
            label: 'Strike',
        },
        {
            value: 'Ban',
            label: 'Ban',
        },
        {
            value: 'Suspension',
            label: 'Suspension',
        }
    ]

    storedModerationTags.split(",").forEach((tag) =>{
        options.push( {
            value: tag,
            label: capitalize(tag),
            children: types,
        })
    })

    const [defaultReason, setDefaultReason] = useState([]);
    const deleteAboutText = 'Are you sure to you want to change this and apply a moderation action?';
    const errorRequireReason = 'Select a reason for deleting the photo!';

    function handleChange(value) {
        if(!_.isEmpty(value)) {
            setDefaultReason(value)
        }
    }

    const [aboutStatus, setAboutStatus] = useState(!expandedProfile.about?.visible);
    
    function enableDisabledAbout(){
            setAboutStatus(false);
    }

    function disableDisabledAbout(){
        let dsbld = expandedProfile.about?.visible;
        if(dsbld === true){
            setAboutStatus(false);
        } else {
            setAboutStatus(true);
        }
    }

    function proceedToDeleteAbout() {
        if(_.isEmpty(defaultReason)) {
            // alert indicating no tags/type (reason) selected
            console.log(errorRequireReason);
        } else {
            const tags = defaultReason[0];
            const type = defaultReason[1];

            const params = {
                about_value: aboutValue,
                type: type,
                active: true,
                tags: [tags.toLowerCase()],
                reason: tags
            }
            props.deleteAboutMe(profile.id, params);
            setSwitchReadOnlyAbout(true);
        }
    }

    function proceedToDeleteUsername() {
        if(_.isEmpty(defaultReason)) {
            // alert indicating no tags/type (reason) selected
            console.log(errorRequireReason);
        } else {
            const tags = defaultReason[0];
            const type = defaultReason[1];

            const params = {
                username_value: usernameValue,
                type: type,
                active: true,
                tags: [tags.toLowerCase()],
                reason: tags
            }
            props.deleteUsername(profile.id, params);
            setSwitchReadOnlyUsername(true);
        }
    }

    return (
        <div className={"inner"} style={{width: "100%", overflowX: 'hidden', scrollbarWidth: 'none'}}>
            <Row gutter={'8'}>
                {
                    expandedProfile.members?.map((member, index) => (
                        <Col key={member.id} span={"12"}>
                            <FormGroup style={{marginBottom: "10px", marginTop: "5px"}}>
                                <Label> Display Name </Label>
                                <Input
                                    type="text"
                                    name="DisplayName"
                                    disabled={false}
                                    value={member.display_name ?? ""}
                                />
                            </FormGroup>
                            <Row>
                                <Col span={25}>
                                    <FormGroup style={{marginBottom: "10px"}}>
                                        <Label> Date Of Birth </Label>
                                        <Input
                                            type="text"
                                            name="dob"
                                            disabled={member.date_of_birth?.visible ? false : true}
                                            value={member.date_of_birth?.value}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={25}>
                                    <FormGroup style={{marginBottom: "10px"}}>
                                        <Label> Orientation </Label>
                                        <Input
                                            type="text"
                                            name="Orientation"
                                            disabled = {member.orientation?.visible ? false : true}
                                            value={member.orientation?.value}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={25}>
                                    <FormGroup style={{marginBottom: "10px"}}>
                                        <Label> Gender</Label>
                                            <Select style={{width: "100% !important"}} disabled = {member.gender?.visible ? false : true} value={member.gender?.value}>
                                            <Option value="Male"> Male </Option>
                                            <Option value="Female"> Female </Option>
                                            </Select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={25}>
                                    <FormGroup style={{marginBottom: "10px"}}>
                                        <Label> Pronouns </Label>
                                        <Input
                                            type="text"
                                            name="pronouns"
                                            disabled = {member.pronouns?.visible ? false : true}
                                            value={member.pronouns?.value}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    ))
                }</Row>

                    <Row key={"username"}>
                        <Col span={23}>
                            <FormGroup style={{marginBottom: "10px"}}>
                                <Label> Profile Username </Label>
                                <Input
                                    type="text"
                                    name="username"
                                    disabled = {profile.username?.visible ? false : true}
                                    onClick={() => editUsername()}
                                    defaultValue={profile.username?.value}
                                    onChange={(e) => setUsernameValue(e.target.value)}
                                />
                            </FormGroup>
                            { !switchReadOnlyUsername && usernameModerationAction ?
                            <Row className="photo-mod-select-div" gutter={2}>
                                <Col span={23}>
                                    <Cascader options={options} onChange={handleChange} value={defaultReason} placeholder="Select moderation reason from list" className="mod-select" />
                                </Col>
                            </Row>
                            : null
                        }
                            { !switchReadOnlyUsername ? <center><Popconfirm placement="top" title={deleteAboutText} onConfirm={proceedToDeleteUsername} okText="Yes" cancelText="No"><Button size="sm">Save About Me</Button></Popconfirm> <a onClick={() => editUsername2()}>Cancel</a></center>: null}
                            { !switchReadOnlyUsername ? <><br/></> : null}
                            </Col>
                    </Row>

                    <Row key={"aboutUs"}>
                        <Col span={23}>
                            <FormGroup style={{marginBottom: "10px"}} onClick={() => editAboutMe()}>
                                <Label> About {expandedProfile.members?.length === 1 ? "Me" : "Us"} </Label>
                                <TextArea
                                    showCount
                                    maxLength={700}
                                    autoSize={{ minRows: 6, maxRows: 24 }}
                                    name="aboutUs"
                                    disabled={aboutStatus}
                                    onClick={() => editAboutMe()}
                                    defaultValue={expandedProfile.about?.value}
                                    onChange={(e) => {
                                        setAboutValue(e.target.value);
                                    }
                                    }
                                />
                            </FormGroup>
                            { !switchReadOnlyAbout && aboutModerationAction ?
                            <Row className="photo-mod-select-div" gutter={2}>
                                <Col span={23}>
                                    <Cascader options={options} onChange={handleChange} value={defaultReason} placeholder="Select moderation reason from list" className="mod-select" />
                                </Col>
                            </Row>
                            : null
                        }
                            { !switchReadOnlyAbout ? <center><Popconfirm placement="top" title={deleteAboutText} onConfirm={proceedToDeleteAbout} okText="Yes" cancelText="No"><Button size="sm">Save About Me</Button></Popconfirm> <a onClick={() => editAboutMe2()}>Cancel</a></center>: null}
                            { !switchReadOnlyAbout ? <><br/></> : null}
                        </Col>
                    </Row>

                    <Row key={"relationShipStatus"}>
                        <Col span={23}>
                            <FormGroup style={{marginBottom: "10px", width: "100%"}}>
                                <Label> Relationship Status </Label>
                                <Input
                                    type="text"
                                    name="relationShipStatus"
                                    disabled = {expandedProfile.relationship_status?.visible ? false : true}
                                    value={expandedProfile.relationship_status?.value ?? ""}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row key={"relationShipType"}>
                        <Col span={23}>
                            <FormGroup>
                                <Label> Relationship Type </Label>
                                <Input
                                    type="text"
                                    name="relationShipType"
                                    disabled = {expandedProfile.relationship_type?.visible ? false : true}
                                    value={expandedProfile.relationship_type?.value ?? ""}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
        </div>
    );
}

const actionCreators = {
    deleteAboutMe: profileActions.deleteAboutMe,
    deleteUsername: profileActions.deleteUsername
};

function mapStateToProps(state) {
    const  { creatingNoteInProgress } = state.profileReducer
    return { creatingNoteInProgress }
}

export default connect(mapStateToProps, actionCreators)(TabProfile);
