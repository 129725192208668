import React, {useEffect, useState} from "react";
import {Switch} from 'antd';
import {profileActions} from "../../appRedux/Actions";
import {connect} from "react-redux";

function NoteSwitch(props) {

    const { record, profile } = props;
    const {members} = profile
    const accountID = members.length > 0 ? members[0]?.account?.id : 0


    const [checked, setChecked] = useState(record.active);
    const toggleActive = (record) => {
        record.active ? 
            props.patchNote(profile, accountID, record.id, record.content, record.tags, false) :
                props.patchNote(profile, accountID, record.id, record.content, record.tags, true);
        // end conditional
        record.active ? setChecked(false) : setChecked(true);
    }

    return <Switch 
    size="small" 
    checked={checked}
    onClick={() => toggleActive(record)}
    />
}

const actionCreators = {
    patchNote: profileActions.patchNoteAction
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, actionCreators)(NoteSwitch);