import {hashtagService} from "../Services";
import {alertActions} from "./alert.actions";
import {hashtagsConstants} from "../Constants";

export const hashtagsActions = {
    searchHashtagAction,
    updateHashtagBanStatusAction
}

function searchHashtagAction(searchOptions) {

    return dispatch => {
        if(searchOptions.include_preferences === false && searchOptions.include_boundaries === false) {
            dispatch(alertActions.error("You must check either include preferences or include boundaries"));
            return
        }

        if(searchOptions.hashtag === undefined || searchOptions.hashtag === "") {delete (searchOptions.hashtag)}
        if(!searchOptions.partial) {delete (searchOptions.partial)}
        if(searchOptions.bannedUnBanned) {delete (searchOptions.banned)}

        if((searchOptions.hashtag === undefined || searchOptions.hashtag === "") &&
            (searchOptions.banned === undefined)) {
            dispatch(alertActions.error("One of banned, hashtag is required."));
            return
        }

        delete (searchOptions.bannedUnBanned)
        console.log("searchOptions")
        console.log(searchOptions)

        dispatch(loading())

        hashtagService.searchHashtag(searchOptions)
            .then(response => {
                    console.log("hashtag Server Response")
                    console.log(response)
                    dispatch(successful(response))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function failure(error) { return { type: hashtagsConstants.HASHTAG_REQUEST_FAILURE, error } }
    function loading() { return { type: hashtagsConstants.HASHTAG_SEARCH_REQUEST } }
    function successful(response) { return { type: hashtagsConstants.HASHTAG_REQUEST_SUCCESSFUL, response } }
}

function updateHashtagBanStatusAction(preferenceList, boundaryList, banStatus)  {
    return async dispatch => {
        if (
            (preferenceList === undefined || preferenceList.length === 0)
            &&
            (boundaryList === undefined || boundaryList.length === 0)) {
            dispatch(alertActions.error("You need to select hashtags"));
            return
        }

        const params = {
            "preference_tags": preferenceList,
            "boundary_tags": boundaryList,
            "ban": banStatus
        }

        try {
            dispatch(loading())
            const response = await hashtagService.updateBanState(params)
            dispatch(successful(response))
        } catch (e) {
            dispatch(alertActions.error(e.toString()));
            dispatch(failure(e));
        }
    }
    function loading() { return { type: hashtagsConstants.HASHTAG_UPDATE_REQUEST } }
    function successful(response) { return { type: hashtagsConstants.HASHTAG_UPDATE_REQUEST_SUCCESSFUL, response } }
    function failure(error) { return { type: hashtagsConstants.HASHTAG_UPDATE_REQUEST_FAILURE, error } }
}