import React from "react";
import {Button, Tabs, Tooltip} from "antd";
import {connect, useDispatch, useSelector} from "react-redux";
import {ProfileReports, Notes, ProfileModeration, TabPremium} from "../index"
import {MessageFilled, StopOutlined, FlagOutlined, ProfileOutlined, DollarOutlined, CodeTwoTone} from '@ant-design/icons';
import _ from "lodash";
import {chatActions, streamRoomMessages} from "../../appRedux/Actions";
import uuid from "react-uuid";


const {TabPane} = Tabs;

function ProfileConnect(props) {

    const dispatcher = useDispatch();
    const updatedRooms = useSelector(state => state.chatReducer.updatedRooms)

    function setRoomToListenTo(profile) {
        const roomName = `_support_${profile.id}_`
        const roomName1 = `-support-${profile.id}-`
        const myRoom = updatedRooms.filter(item => (item.name === roomName || item.name === roomName1));
        if (!_.isEmpty(myRoom)) {
            const roomId = myRoom[0]?._id ?? 0
            streamRoomMessages(roomId)
            dispatcher(chatActions.getRoomMessages(roomId))
        }
    };

    function changeTab(props, key) {
        if (key === "2") {
            setRoomToListenTo(props.profile)
        }
    }


    const {profile} = props
    const profileNotesMap = useSelector(state => state.profileReducer.profileNotesMap);
    const profile_id = profile.id
    // const profileModerationMap = useSelector(state => state.moderationReducer.profileModerationList)

    let moderationCount = 0
    profile.members?.forEach(member => {
        if (member.account !== null) {
            moderationCount = member.account?.moderation_actions.length
        }
    });

    const tabSlot = {
        left: <Button className={"chat-open-button"} key={uuid()} icon={<MessageFilled style={{color: '#585980'}}/>}
            onClick={() => window.open("/chat?id=" + profile_id, "_blank",
                'popup=true,width=500,height=800')}
            >
            <span className={"chat-open-button-text"}>Chat</span></Button>
    }

    return (
        <div style={{height: "100%"}}>
            <Tabs className={"profile-connect-tabs"} defaultActiveKey="1" type="card"
                  onTabClick={ e => changeTab(props, e)}
                  tabBarExtraContent={tabSlot}>
                <TabPane tab={<span>
                             <Tooltip title="Moderation">
                                 <StopOutlined style={{color: '#585980'}}/>
                                  <span className={"profile-connect-tab-count"}>({moderationCount})</span>
                             </Tooltip>
                              </span>
                } key="1">
                    <ProfileModeration profile={profile}/>
                </TabPane>
                <TabPane tab={<span>
                             <Tooltip title="Reports">
                                 <FlagOutlined style={{color: '#585980'}}/>
                                  <span className={"profile-connect-tab-count"}>({profile.reports?.length})</span>
                             </Tooltip>
                              </span>
                } key="3">
                    <ProfileReports profile={profile}/>
                </TabPane>
                <TabPane tab={<span>
                             <Tooltip title="Notes">
                                 <ProfileOutlined style={{color: '#585980'}}/>
                                  <span className={"profile-connect-tab-count"}>({(profileNotesMap.get(profile.id) ?? []).length})</span>
                             </Tooltip>
                              </span>
                } key="4">
                    <Notes profile={profile}/>
                </TabPane>
                <TabPane tab={
                    <span>
                             <Tooltip title="Membership">
                                 <DollarOutlined style={{color: '#585980'}}/>
                             </Tooltip>
                              </span>

                } key="5">
                    <TabPremium profile={profile}/>
                </TabPane>
                {/*<TabPane tab={*/}
                {/*    <span>*/}
                {/*             <Tooltip title="Logs">*/}
                {/*                 <CodeTwoTone twoToneColor="#847df5"/>*/}
                {/*             </Tooltip>*/}
                {/*              </span>*/}
                {/*} key="6">*/}
                {/*    Content of card tab 2*/}
                {/*</TabPane>*/}
            </Tabs>
        </div>
    );

}

const actionCreators = {}

function mapStateToProps(state) {
    const {profileModerationList} = state.moderationReducer
    return {profileModerationList};
}

export default connect(mapStateToProps, actionCreators)(ProfileConnect);
