import {config} from "../Config";
import {storageService} from "../Storage";
import {storageConstants} from "../Constants/storage.constants";
import {handleResponse} from "./authentication.service";
import {authHeader, chatAuthHeader} from "../Helpers";

export const chatonlyService = {
    getAllRooms,
    sendChatMessage,
    createChatService,
    fetchRoomMessages,
    getRoomSubscription,
    markAllRoomMessageAsReadService
};


function getRoomSubscription() {
    const requestOptions = {
        method: 'GET',
        headers: chatAuthHeader()
    };
    return fetch(`${config.chatBaseUrl}/api/v1/subscriptions.get?`+ new URLSearchParams({
        updatedSince: "2020-05-25T15:08:17.248Z",
    }), requestOptions).then(handleResponse);
}

function getAllRooms() {
    const requestOptions = {
        method: 'GET',
        headers: chatAuthHeader()
    };
    return fetch(`${config.chatBaseUrl}/api/v1/rooms.get`, requestOptions).then(handleResponse);
}

function fetchRoomMessages(roomId) {
    const requestOptions = {
        method: 'GET',
        headers: chatAuthHeader()
    };
    return fetch(`${config.chatBaseUrl}/api/v1/groups.messages?`+ new URLSearchParams({
        roomId: roomId,
        offset: 0,
        count: 100000,
    }), requestOptions).then(handleResponse);
}

function sendChatMessage(roomId, message) {
    const requestOptions = {
        method: 'POST',
        headers: chatAuthHeader(),
        body: JSON.stringify({"message":{"rid":roomId,"msg":message}})
    };

    return fetch(`${config.chatBaseUrl}/api/v1/chat.sendMessage`, requestOptions).then(handleResponse);
}

function createChatService(profileId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}/chat`, requestOptions).then(handleResponse);
}

function markAllRoomMessageAsReadService(roomId) {
    const requestOptions = {
        method: 'POST',
        headers: chatAuthHeader(),
        body: JSON.stringify({"rid":roomId})
    };
    return fetch(`${config.chatBaseUrl}/api/v1/subscriptions.read`, requestOptions).then(handleResponse);
}

function logout() {
    storageService.deleteFromStore(storageConstants.SESSION_DATA);
    storageService.deleteFromStore(storageConstants.SUPPORT_ACCOUNT);
    storageService.deleteFromStore(storageConstants.GOOGLE_TOKEN_ID);
}
