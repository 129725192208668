import React, {useState} from "react";
import {Col, Empty, Image, Modal, Skeleton, Table, Tooltip, Button, Row, Tag} from "antd";
import openLogo from "../../assets/logo.svg";
import pairedIcon from "../../assets/paired.png";
import subscribedIcon from "../../assets/subscribed_active.png";
import facebookIcon from "../../assets/facebook.png";
import appsflyerIcon from "../../assets/appsflyer.png";
import androidIcon from '../../assets/android.png';
import appleIcon from "../../assets/apple.png";
import uuid from "react-uuid";
import moment from "moment";

function TableFlags(props) {
    const {record} = props;
    const reportLength = record.reports?.filter(report => report.reviewed === false)?.length ?? 0;
    const hasReport = reportLength > 0;

    const polyIcon = <Image src={openLogo} alt={"likeIcon"} className="table-icon" preview={false}/>
    const paired = <Image src={pairedIcon} alt={"pairedIcon"} className="table-icon" preview={false}/>
    const subscribed = <Image src={subscribedIcon} alt={"subscribedIcon"} className="table-icon" preview={false}/>
    const facebook = <Image src={facebookIcon} alt={"facebookIcon"} className="table-icon" preview={false}/>
    const appsflyer = <Image src={appsflyerIcon} alt={"appsflyerIcon"} className="table-icon" preview={false}/>
    const apple = <Row className="profile-row-flag platform-flag"><Image src={appleIcon} alt={"appleIcon"}
                                                                         className="table-icon" preview={false}/></Row>
    const android = <Row className="profile-row-flag platform-flag"><Image src={androidIcon} alt={"androidIcon"}
                                                                           className="table-icon"
                                                                           preview={false}/></Row>

    const flag_map = {
        "baby": "🍼",
        "pre_teen": "🍼",
        "teenager": "🍼",
        "toddler": "🍼",
        "general_nsfw": "🍑",
        "yes_sex_toy": "🕹️",
        "yes_female_nudity": "🍆",
        "yes_male_nudity": "🍆",
        "general_suggestive": "🍑",
        "gun_in_hand": "🔫",
        "gun_not_in_hand": "🔫",
        "knife_in_hand": "🔪️",
        "knife_not_in_hand": "🔪️",
        "a_little_bloody": "🩸",
        "other_blood": "🩸",
        "very_bloody": "🩸",
        "yes_nazi": "☠",
        "yes_kkk": "☠",
        "yes_terrorist": "☠",
        "yes_pills": "💊",
        "illicit_injectables": "💊",
        "medical_injectables": "💊",
        "no_demographics": "🚷",
        "text": "🔤",
        "yes_spam": "📔",
        "violence": "📔",
        "yes_bullying": "📔",
        "self_harm": "📔",
        "like_pass_ratio": "🚨",
        "like_threshold": "🚨",
        "reverse_match": "🖼️",
    };


    const getPhotoFlags = () => {
        let flags = new Set()
        record.photos?.map((photo) => {
            photo.ml_flagged?.map((flag) => {
                flags.add(flag)
            })
        });
        let out_rows = []
        flags.forEach(f => {
            if (f in flag_map) {
                out_rows.push(
                    <Row key={uuid()} className="profile-row-flag photo-flags">
                        <Tooltip key={uuid()} title={"Photo flagged with " + f}><b>{flag_map[f]}</b></Tooltip></Row>
                )
            }
        })
        return out_rows
    }

    const aboutFlag = () => {
        let hidden = true;
        let about_flags = []
        record.about_ml_flagged?.map((flag) => {
            if (flag == "yes_spam" || flag == "violence" || flag == "yes_bullying" || flag == "self_harm") {
                hidden = false;
            }
        });
        return <Row key={uuid()} className="profile-row-flag about-flags" hidden={hidden}>
            <Tooltip key={uuid()} title={"Profile's about me was flagged: " + about_flags.join(", ")}>📔</Tooltip></Row>
    }

    const scamAlert = () => {
        let hidden = true;
        record.active_auto_review_flags?.map((flag) => {
            if (flag == "like_pass_ratio" || flag == "like_threshold") {
                hidden = false;
            }
        });
        return <Row key={uuid()} className="profile-row-flag scam-alert-flag" hidden={hidden}>
            <Tooltip key={uuid()} title={"Interaction flag"}>🚨</Tooltip></Row>
    }

    const newHashtagsFlag = () => {
        let new_preferences = [];
        let new_boundaries = [];
        let hidden = true;
        record.preference_lists?.map((list) => {
            if (list.unreviewed_preference_instances?.length > 0) {
                hidden = false;
                new_preferences.push.apply(new_preferences, list.unreviewed_preference_instances)
            }
        });
        record.boundary_lists?.map((list) => {
            if (list.unreviewed_boundary_instances?.length > 0) {
                hidden = false;
                new_boundaries.push.apply(new_boundaries, list.unreviewed_boundary_instances)
            }
        });

        const tooltip_title = (new_preferences.length > 0 ? "New preferences: " + new_preferences.join(", ") + "  " : " ") + (new_boundaries.length > 0 ? "New boundaries: " + new_boundaries.join(", ") : " ")

        return <Row key={uuid()} className="profile-row-flag hashtag-flag" hidden={hidden}>
            <Tooltip placement={"top"} key={uuid()} title={tooltip_title}>#️⃣</Tooltip></Row>
    }

    const isSubscribed = () => {
        let hidden = true;
        record.members?.map((member) => {
            if (member.subscriber) {
                hidden = false;
            }
        });
        return <Row key={uuid()} className="profile-row-flag sub-flag" hidden={hidden}>
            <Tooltip key={uuid()} title={"Subscribed"}>{subscribed}</Tooltip></Row>
    }

    const countryFlag = () => {
        let hidden = true;
        record.active_auto_review_flags?.map((flag) => {
            if (flag == "flagged_country") {
                hidden = false;
            }
        });
        return <Row key={uuid()} className="profile-row-flag country-flag" hidden={hidden}>
            <Tooltip key={uuid()} title={"Country flag"}>‍🌐</Tooltip></Row>
    }

    const fromAppsflyer = () => {
        let hidden = true;
        let sources = [];
        record.members?.map((member) => {
            if (member.account?.appsflyer_campaign_id) {
                hidden = false;
                sources.push(member.account.appsflyer_campaign_id)
            }
        });
        return <Row key={uuid()} className="profile-row-flag appsflyer-flag" hidden={hidden}>
            <Tooltip key={uuid()} title={"Attributed to Appsflyer campaign(s) " + sources.join(", ")}>{appsflyer}</Tooltip></Row>
    }

    const fromFacebook = () => {
        let hidden = true;
        record.members?.map((member) => {
            if (member.account?.appsflyer_full_data?.media_source === "restricted") {
                hidden = false;
            }
        });
        return <Row key={uuid()} className="profile-row-flag facebook-flag" hidden={hidden}>
            <Tooltip key={uuid()} title="Attributed to a Facebook ad">{facebook}</Tooltip></Row>
    }

    const operatingSystem = () => {
        let out_icons = []
        record.members?.map((member) => {
            if (member.account?.operating_system === "Android") {
                out_icons.push(<Row key={uuid()} className="profile-row-flag platform-flag">
                    <Tooltip key={uuid()} title="A member uses Android">
                        <Image
                            src={androidIcon}
                            alt={"androidIcon"}
                            className="table-icon"
                            preview={false}/>
                    </Tooltip></Row>)
            } else if (member.account?.operating_system === "iOS") {
                out_icons.push(<Row key={uuid()} className="profile-row-flag platform-flag">
                    <Tooltip key={uuid()} title="A member uses iOS">
                        <Image
                            src={appleIcon}
                            alt={"appleIcon"}
                            className="table-icon"
                            preview={false}/>
                    </Tooltip></Row>)
            }
        });
        return out_icons
    }

    return <>
        <center>
            <Col style={{height: '100%', width: "25px", display: "block", alignItems: "center", marginRight: "10px"}}>
                {hasReport && <Row key={uuid()} style={{
                    color: "#FF0000",
                    display: "table-row",
                    alignItems: "center",
                    paddingLeft: 0,
                    fontWeight: "bold",
                    fontSize: "17px"
                }}>{reportLength}R</Row>}
                {scamAlert()}
                {getPhotoFlags()}
                {countryFlag()}
                {aboutFlag()}
                {newHashtagsFlag()}
                {record.members?.length < 2 ?
                    <Row key={uuid()} className="profile-row-flag members-flag"><Tooltip key={uuid()} title="Profile is solo">{polyIcon}</Tooltip></Row> : <><Row
                        key={uuid()} className="profile-row-flag members-flag"><Tooltip key={uuid()} title="Profile is partnered">{paired}</Tooltip></Row></>}
                {operatingSystem()}
                {isSubscribed()}
                {fromAppsflyer()}
                {fromFacebook()}
            </Col>
        </center>
    </>
}

export default TableFlags;