import emptyImage from "./assets/img.png";
import {useCallback, useEffect, useState} from "react";
import moment from "moment";

export function completeImages(photos, fromInner) {
    const screenWidth = window.innerWidth;
    const photoList = [...photos]
    // console.log("screenWidth", screenWidth)
    // console.log("screenWidth", fromInner)
    if((photoList.length < 7 && screenWidth > 1700) || fromInner === true) {
        for(let f = photoList.length; f < 7; f++) {
            photoList[f] = {
                avatar_url: emptyImage,
                card_url: emptyImage,
                created_ts: null,
                id: 0,
                reviewed: true,
                reviewed_by: null,
                reviewed_ts: null,
                grid_url: emptyImage,
            }
        }
    }
    return photoList
}

export const useTimeout = (
    callback, // function to call. No args passed.
    timeout = 0, // delay, ms (default: immediately put into JS Event Queue)
    {
        // manage re-render behavior.
        // by default, a re-render in your component will re-define the callback,
        //    which will cause this timeout to cancel itself.
        // to avoid cancelling on re-renders (but still cancel on unmounts),
        //    set `persistRenders: true,`.
        persistRenders = false,
    } = {},
    // These dependencies are injected for testing purposes.
    // (pure functions - where all dependencies are arguments - is often easier to test)
    _setTimeout = setTimeout,
    _clearTimeout = clearTimeout,
    _useEffect = useEffect,
) => {
    let timeoutId;
    const cancel = () => timeoutId && _clearTimeout(timeoutId);

    _useEffect(
        () => {
            timeoutId = _setTimeout(callback, timeout);
            return cancel;
        },
        persistRenders
            ? [_setTimeout, _clearTimeout]
            : [callback, timeout, _setTimeout, _clearTimeout],
    );

    return cancel;
}

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export function isSolo(profile) {
    return (profile.members?.length ?? 0)  === 1
}

export function getAccountOwner(profile) {
    if(isSolo(profile)) {
        return profile.members[0];
    } else {
        return profile.members[0]
    }
}

export function formatDate(date) {
    const today = moment.utc().local().endOf('day');
    const daysAgo = today.diff(moment.utc(date).local(), 'days');

    if (daysAgo === 0) {
        return (<center className={'timestamp'}>Today<br/>{moment.utc(date).local().format('h:mm a')}
        </center>)
    } else if (daysAgo === 1) {
        return (<center className={'timestamp'}>Yesterday<br/>{moment.utc(date).local().format('h:mm a')}</center>)
    } else if (daysAgo <= 7) {
        return (
            <center className={'timestamp'}>{moment.utc(date).local().format('dddd')}<br/>{moment.utc(date).local().format('h:mm a')}
            </center>)
    } else if (daysAgo < 365) {
        return (
            <center className={'timestamp'}>{moment.utc(date).local().format('ddd, MMM D')}<br/>{moment.utc(date).local().format('h:mm a')}
            </center>)
    } else {
        // Aug 8, 2014, 8:25 AM
        return (
            <center className={'timestamp'}>{moment.utc(date).local().format('MMM D, Y')}<br/>{moment.utc(date).local().format('h:mm a')}
            </center>)
    }
}