import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import React, {useEffect} from "react";
import {ProfileTableRenderer} from "../../components";

function TechIssues(props) {

    const {getTechIssues} = props;
    useEffect(() => {
        getTechIssues()
    }, [])

    const techIssueSelected = (profileId) => {
    }

    return ( 
    <ProfileTableRenderer profiles={props.techIssues}
                                 fetched={props.techIssuesFetched}
                                 profileSelectedAction={techIssueSelected}
                                 enableRowSelection={false}
    />
    )
}


const actionCreators = {
    getTechIssues: profileActions.getTechIssues,
};

function mapStateToProps(state) {
    const { techIssues, techIssuesFetched } = state.profileReducer
    return { techIssues, techIssuesFetched };
}


export default connect(mapStateToProps, actionCreators)(TechIssues);
