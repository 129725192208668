import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";
import {useEffect} from "react";
import {alertActions} from "../../appRedux/Actions";
import { notification } from 'antd';
import {SmileOutlined} from "@ant-design/icons";
import notificationSound from '../../assets/notification.mp3'
import likeIcon from "../../assets/logo.svg";

function Alert(props) {

    const alertErrorKey = "alertErrorKey"
    const alerts = useSelector(state => state.alertReducer)
    const alertDispatch = useDispatch()
    const audio = new Audio(notificationSound);

    useEffect(() =>{

        if(alerts?.type === "alert-danger") {
            message.error({ content: alerts?.message, key:alertErrorKey }).then(r =>  alertDispatch(alertActions.clear()))
        } else {
            message.destroy(alertErrorKey)
        }

        if(alerts?.type === "alert-notification" && alerts?.message !== undefined && alerts?.message !== null && alerts?.message !== "") {
            notification.open({
                duration: 0,
                message: "New Message Received",
                placement: 'topRight',
                description: alerts?.message,
                icon: <SmileOutlined style={{ color: '#000000' }} />,
            });
            audio.play().then(r => alertDispatch(alertActions.clear()));

            let permission = Notification.permission;
            if(permission === "granted") {
                showNotification(alerts?.message);
            } else if(permission === "default"){
                requestAndShowPermission(alerts?.message);
            } else {

            }

            function requestAndShowPermission(message) {
                Notification.requestPermission(function (permission) {
                    if (permission === "granted") {
                        showNotification(message);
                    }
                }).then(r => "");
            }

            function showNotification(body) {
                if(document.visibilityState === "visible") {
                    return;
                }
                const icon = likeIcon
                const notification = new Notification('New Message', {body, icon});
                notification.onclick = () => {
                    notification.close();
                    window.parent.focus();
                }
            }
        }
    })
    return <>
    </>
}

export default Alert