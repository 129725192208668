import {config} from "../Config";
import {apiConstants} from "../Constants";
import {storageService} from "../Storage";
import {storageConstants} from "../Constants/storage.constants";
import {redirectToLogin} from "../../route/Redirect";

export const authenticationService = {
    login
};

function login(id_token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id_token: id_token })
    };

    return fetch(`${config.baseURL}${apiConstants.LOGIN}`, requestOptions)
        .then(handleResponse)
        .then(sessionData => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            storageService.saveToStore(storageConstants.SESSION_DATA, JSON.stringify(sessionData));

            return sessionData;
        });
}


export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                // auto logout if 401 response returned from api
                logout();
                redirectToLogin().then(r => "")
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function logout() {
    storageService.clearStore()
}
