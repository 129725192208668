import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import React, {useEffect} from "react";
import {ProfileTableRenderer} from "../../components";

function DebugProfiles(props) {

    const {getBugsyProfiles} = props;
    useEffect(() => {
        getBugsyProfiles()
    }, [])

    const bugsyProfileSelected = (profileId) => {
    }

    return ( 
    <ProfileTableRenderer profiles={props.bugsyProfiles}
                                 fetched={props.bugsiesFetched}
                                 profileSelectedAction={bugsyProfileSelected}
                                 enableRowSelection={false}
    />
    )
}


const actionCreators = {
    getBugsyProfiles: profileActions.getBugsyProfiles,
};

function mapStateToProps(state) {
    const { bugsyProfiles, bugsiesFetched } = state.profileReducer
    return { bugsyProfiles, bugsiesFetched };
}


export default connect(mapStateToProps, actionCreators)(DebugProfiles);
