import React from "react";
import { Router } from "@gatsbyjs/reach-router";

import Routes from "./index";
import AppComponent from "./AppComponent";
// import { NotfoundPage } from "../components";
// import NotFound from 'components/NotFound';

function RouteComponent(props) {
    const { location } = props;
    const RouteList = Routes.map((route, index) => {
        let routeComponent = null;
        let component = route.component;
        let layout = route.layout;

        if (typeof route.children !== "undefined") {
            const childrens = route.children;
            const childrenComponent = childrens.map((route, index) => (
                <AppComponent
                    key={index}
                    route={route}
                    path={route.path}
                    auth={route.auth}
                    component={component}
                    layout={layout}
                />
            ));

            routeComponent = (
                <AppComponent
                    route={route}
                    key={index}
                    path={route.path}
                    auth={route.auth}
                    component={component}
                    layout={layout}
                >
                    {childrenComponent}
                </AppComponent>
            );
        } else {
            routeComponent = (
                <AppComponent
                    key={index}
                    route={route}
                    path={route.path}
                    auth={route.auth}
                    component={component}
                    layout={layout}
                />
            );
        }
        return routeComponent;
    });

    return (
        <>
            <Router location={location}>{RouteList}</Router>
        </>
    );
}

export default RouteComponent;
