import {storageService} from "../Storage";
import {storageConstants} from "../Constants/storage.constants";

export function authHeader() {
    // return authorization header with jwt token
    let sessionData = JSON.parse(storageService.getFromStore(storageConstants.SESSION_DATA));

    if (sessionData && sessionData.session_token) {
        return {
            'Open-Session': sessionData.session_token ,
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}

export function chatAuthHeader() {
    return {
        'X-Auth-Token': process.env.REACT_APP_CHAT_TOKEN,
        'X-User-Id': process.env.REACT_APP_CHAT_ID,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
}

export function axiosSettings() {
    // return authorization header with jwt token
    let sessionData = JSON.parse(storageService.getFromStore(storageConstants.SESSION_DATA));

    if (sessionData && sessionData.session_token) {
        return {
            baseURL:    "https://api2-staging.hashtagopen.com",
            baseURLSec: "https://chat-staging.hashtagopen.com",
            headers: {
                'Open-Session': sessionData.session_token,
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Expose-Headers': 'access_token',
            },
        };
    } else {
        return {};
    }
}