import { macroConstants } from '../Constants'

const INIT_STATE = {
    macroList: [],
    helpMacros: [],
    bugMacros: [],
    moderationMacros: [],
    outreachMacros: [],
    reportMacros: [],
    macro: {},
    creatingMacroInProgress: false,
    fetching: false,
    updating: false,
    deleting: false
}

export function macroReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case macroConstants.CREATE_MACRO_REQUEST:
            return {
                ...state,
                creatingMacroInProgress: true,
            }
        case macroConstants.CREATE_MACRO_SUCCESS:
            {
            
                const response = action.macro
                const {id, title, content, type} = response;

                const newMacroList = [...state.macroList, action.macro ];
                newMacroList.sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });

                const helpList = [...state.helpMacros];
                const bugList = [...state.bugMacros];
                const modList = [...state.moderationMacros];
                const outList = [...state.outreachMacros];
                const reportList =  [...state.reportMacros];

                if(type === 'Help') {
                    helpList.push(action.macro);
                } else if(type === 'Bug') {
                    bugList.push(action.macro);
                } else if(type === 'Moderation') {
                    modList.push(action.macro);
                } else if(type === 'Outreach') {
                    outList.push(action.macro);
                } else if(type === 'Report') {
                    reportList.push(action.macro)
                }

                helpList.sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });

                bugList.sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });

                modList.sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });

                outList.sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });

                reportList.sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });

                return {
                    ...state,
                    creatingMacroInProgress: false,
                    macroList: newMacroList,
                    helpMacros: helpList,
                    bugMacros: bugList,
                    moderationMacros: modList,
                    outreachMacros: outList,
                    reportMacros: reportList
                }
            }
        case macroConstants.CREATE_MACRO_FAILURE:
            return {
                ...state,
                creatingMacroInProgress: false
            }
        case macroConstants.GET_MACROS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case macroConstants.GET_MACROS_SUCCESS:
            
            const newMacroList = [...action.macros];
            const newHelpMacros = [];
            const newBugMacros = [];
            const newModerationMacros = [];
            const newOutreachMacros = [];
            const newReportMacros = [];

            newMacroList.map((macro) => {
                if(macro.type === 'Help') {
                    newHelpMacros.push(macro);
                } else if(macro.type === 'Bug') {
                    newBugMacros.push(macro);
                } else if(macro.type === 'Moderation') {
                    newModerationMacros.push(macro);
                } else if(macro.type === 'Outreach') {
                    newOutreachMacros.push(macro);
                } else if(macro.type === 'Report') {
                    newReportMacros.push(macro);
                }
            }
            );

            newMacroList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            newHelpMacros.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            newBugMacros.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            newModerationMacros.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            newOutreachMacros.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            newReportMacros.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });
        
            return {
                ...state,
                fetching: false,
                macroList: newMacroList,
                helpMacros: newHelpMacros,
                bugMacros: newBugMacros,
                moderationMacros: newModerationMacros,
                outreachMacros: newOutreachMacros,
                reportMacros: newReportMacros
            }
        case macroConstants.GET_MACROS_FAILURE:
            return {
                ...state,
                fetching: false
            }
        case macroConstants.DELETE_MACRO_REQUEST:
                return {
                    ...state,
                    deleting: true
                }
        case macroConstants.DELETE_MACRO_SUCCESS:
            
            const macroList = [...state.macroList];
            const macroIndex = action.macroIndex;
            const macro = action.macro;

            const helpList = state.helpMacros.filter((m) => m.id !== macroIndex && m.type !== macro.type);
            const bugList = state.bugMacros.filter((m) => m.id !== macroIndex && m.type !== macro.type);
            const modList = state.moderationMacros.filter((m) => m.id !== macroIndex && m.type !== macro.type);
            const outList = state.outreachMacros.filter((m) => m.id !== macroIndex && m.type !== macro.type);
            const reportList = state.reportMacros.filter((m) => m.id !== macroIndex && m.type !== macro.type);
           
            const newList = macroList.filter((m) => m.id !== macroIndex && m.type !== macro.type);

            newList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            helpList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            bugList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            modList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            outList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            reportList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            return {
                ...state,
                macroList: newList,
                helpMacros: helpList,
                bugMacros: bugList,
                moderationMacros: modList,
                outreachMacros: outList,
                reportMacros: reportList,
                deleting: false
            }
            case macroConstants.DELETE_MACRO_FAILURE:
                return {
                    ...state,
                    deleting: false
                }
            case macroConstants.EDIT_MACRO_REQUEST:
                return {
                    ...state,
                    updating: true
                }
        case macroConstants.EDIT_MACRO_SUCCESS:
            
            const macrosList = [...state.macroList];
            const macroID = action.macroIndex;
            const mcro = action.macro;

            const hList = state.helpMacros.filter((m) => m.id !== macroID);
            const bList = state.bugMacros.filter((m) => m.id !== macroID);
            const mList = state.moderationMacros.filter((m) => m.id !== macroID);
            const oList = state.outreachMacros.filter((m) => m.id !== macroID);
            const rList = state.reportMacros.filter((m) => m.id !== macroID);
            const allList = macrosList.filter((m) => m.id !== macroID);

            if(mcro.type === 'Help') {
                hList.push(action.macro);
            } else if(mcro.type === 'Bug') {
                bList.push(action.macro);
            } else if(mcro.type === 'Moderation') {
                mList.push(action.macro);
            } else if(mcro.type === 'Outreach') {
                oList.push(action.macro);
            } else if(mcro.type === 'Report') {
                oList.push(action.macro);
            }

            allList.push(mcro);


            allList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            hList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            bList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            mList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            oList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            rList.sort((a,b) => {
                return a.title.localeCompare(b.title)
            });

            return {
                ...state,
                macroList: allList,
                helpMacros: hList,
                bugMacros: bList,
                moderationMacros: mList,
                outreachMacros: oList,
                reportMacros: rList,
                updating: false
            }
        default:
            return state
    }
}