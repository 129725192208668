import {moderationConstants, profileConstants} from "../Constants";
import {profileService} from "../Services";
import {alertActions} from "../Actions";
import _ from 'lodash'
import parsePhoneNumber from 'libphonenumber-js'
import {navigateTo} from "../../route/Redirect";

export const profileActions = {
    getProfileWithUnreviewedPhotoAction,
    markProfilePhotoAsReviewed,
    deleteProfilePhotoAction,
    getReportedProfileAction,
    getNewProfilesFromServerAction,
    getReviewedProfilesFromServerAction,
    getNewAutoProfilesFromServerAction,
    markNewProfileAsReviewedAction,
    markReviewedProfileAsReviewed,
    searchProfileAction,
    createNoteAction,
    getNotesFromProfileAction,
    deleteNotesAction,
    markReportAsReViewedAction,
    searchProfileWithAccountIdAction,
    clearAIModeratedProfilesAction,
    changeSelectedProfilePhoto,
    deleteAboutMe,
    deleteUsername,
    getTechIssues,
    getBillingIssues,
    getModIssues,
    getAndroidIssues,
    patchNoteAction,
    getExpandedProfile,
    addMonths,
    addSparks,
    toggleBugsy,
    getBugsyProfiles,
    getAllSubscribers,
    searchProfileActionMore,
    toggleSubscription,
    reportMoreInfo,
    resetInteractions
};

function getNewProfilesFromServerAction() {
    return dispatch => {
        dispatch(request());
        profileService.getNewProfilesFromServer()
            .then(newProfiles => {
                    dispatch(success(newProfiles));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: profileConstants.NEW_PROFILE_REQUEST}
    }

    function success(newProfiles) {
        return {type: profileConstants.NEW_PROFILE_SUCCESS, newProfiles}
    }

    function failure(error) {
        return {type: profileConstants.NEW_PROFILE_ERROR, error}
    }
}

function getReviewedProfilesFromServerAction() {
    return dispatch => {
        dispatch(request());
        profileService.getReviewedProfilesFromServer()
            .then(profiles => {
                    dispatch(success(profiles));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: profileConstants.REVIEWED_PROFILE_REQUEST}
    }

    function success(profiles) {
        return {type: profileConstants.REVIEWED_PROFILE_SUCCESS, profiles}
    }

    function failure(error) {
        return {type: profileConstants.REVIEWED_PROFILE_ERROR, error}
    }
}

function getNewAutoProfilesFromServerAction() {
    return dispatch => {
        dispatch(request());
        profileService.getNewAutoProfilesFromServer()
            .then(newAutoProfiles => {
                    dispatch(success(newAutoProfiles));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: profileConstants.NEW_AUTO_PROFILE_REQUEST}
    }

    function success(newAutoProfiles) {
        return {type: profileConstants.NEW_AUTO_PROFILE_SUCCESS, newAutoProfiles}
    }

    function failure(error) {
        return {type: profileConstants.NEW_AUTO_PROFILE_ERROR, error}
    }
}


function markProfilePhotoAsReviewed(profileId) {
    return markNewProfileAsReviewedAction(profileId);
}

function markReviewedProfileAsReviewed(profileId) {
    return markNewProfileAsReviewedAction(profileId);
}

function markNewProfileAsReviewedAction(profileId) {
    return dispatch => {
        dispatch(request({}));

        profileService.markNewProfileAsReviewedOnServer(profileId)
            .then(response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(response) {
        return {type: profileConstants.MARK_NEW_PROFILE_REQUEST, response}
    }

    function success(response) {
        return {type: profileConstants.MARK_NEW_PROFILE_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.MARK_NEW_PROFILE_ERROR, error}
    }
}

function clearAIModeratedProfilesAction(profileId) {
    return dispatch => {
        dispatch(request({}));

        profileService.markNewProfileAsReviewedOnServer(profileId)
            .then(response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(response) {
        return {type: profileConstants.CLEAR_AI_MOD_PROFILE_REQUEST, response}
    }

    function success(response) {
        return {type: profileConstants.CLEAR_AI_MOD_PROFILE_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.CLEAR_AI_MOD_PROFILE_ERROR, error}
    }
}

function getProfileWithUnreviewedPhotoAction() {
    return dispatch => {
        profileService.getProfileWithUnreviewedPhoto()
            .then(profiles => {
                    dispatch(success(profiles));
                    //demoFetch(dispatch)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    // function request(profiles) { return { type: profileConstants.PROFILE_WITH_UNREVIEWED_PHOTO_REQUEST, profiles } }
    function success(profiles) {
        return {type: profileConstants.PROFILE_WITH_UNREVIEWED_PHOTO_SUCCESS, profiles}
    }

    function failure(error) {
        return {type: profileConstants.PROFILE_WITH_UNREVIEWED_PHOTO_FAILURE, error}
    }
}

function isPhoneNumber(p) {
    const phoneRe = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
    const digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
}

function searchProfileAction(searchText, preferenceSearch = true, boundarySearch = true,
                             fmx = "", subscribers = false, facebook = false,
                             appsflyer = false) {
    return dispatch => {
        let searchBody = {}
        let searchTypeResponse = null

        // If it's a phone number we have to do an account search
        if (isPhoneNumber(searchText)) {
            const phoneNumber = parsePhoneNumber(searchText.trim(), "US")
            if (phoneNumber.isValid() === false) {
                dispatch(alertActions.error("Phone number is invalid"));
                return
            } else {
                searchTypeResponse = profileService.searchByPhoneNumberOnServer(phoneNumber.number)
            }
        } else {
            // Otherwise it's a profile search
            if (searchText.startsWith("#")) {
                searchBody['hashtag'] = {
                    "hashtag": searchText.replace("#", ""),
                    "include_preferences": preferenceSearch,
                    "include_boundaries": boundarySearch
                }
            } else if (isPhoneNumber(searchText)) {
                const phoneNumber = parsePhoneNumber(searchText.trim(), "US")
                if (phoneNumber.isValid() === false) {
                    dispatch(alertActions.error("Phone number is invalid"));
                    return
                } else {
                    searchBody["phone_number"] = phoneNumber
                }
            } else {
                if (searchText) {
                searchBody["username"] = searchText.trim()
                }
            }

            // Set bool filters
            if (subscribers === true) {
                searchBody["subscribers"] = subscribers
            }
            if (facebook === true) {
                searchBody["facebook_acquisition"] = facebook
            }
            if (appsflyer === true) {
                searchBody["appsflyer_acquisition"] = appsflyer
            }
            if (fmx) {
                searchBody["fmx"] = fmx
            }
            searchTypeResponse = profileService.searchProfilesOnServer(searchBody)
        }


        dispatch(searchRequest());

        searchTypeResponse?.then(response => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        dispatch(alertActions.error(data.message));
                        console.log(data)
                        dispatch(searchFailure(searchText, "Search returned no profiles"))
                    } else {
                        dispatch(searchSuccess(searchText, data))
                        navigateTo(`/search-result`).then(r => "")
                    }
                })
            },
            error => {
                dispatch(searchFailure(searchText, error))
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
}

function searchRequest() {
    return {type: profileConstants.SEARCH_PROFILE_REQUEST}
}

function searchSuccess(searchedText, profiles) {
    return {type: profileConstants.SEARCH_PROFILE_SUCCESS, searchedText, profiles}
}

function searchFailure(searchedText, error) {
    return {type: profileConstants.SEARCH_PROFILE_ERROR, searchedText, error}
}


function searchProfileActionMore(searchText,
                                 skippedIds = null,
                                 preferenceSearch = true,
                                 boundarySearch = true,
                                 fmx = "", subscribers = false, facebook = false,
                                 appsflyer = false
) {
    return dispatch => {
        let searchBody = {}
        let searchTypeResponse = null
        // If it's a phone number we have to do an account search
        if (isPhoneNumber(searchText)) {
            const phoneNumber = parsePhoneNumber(searchText.trim(), "US")
            if (phoneNumber.isValid() === false) {
                dispatch(alertActions.error("Phone number is invalid"));
                return
            } else {
                searchTypeResponse = profileService.searchByPhoneNumberOnServer(searchText)
            }
        } else {
            // Otherwise it's a profile search
            if (searchText.startsWith("#")) {
                searchBody['hashtag'] = {
                    "hashtag": searchText.replace("#", ""),
                    "include_preferences": preferenceSearch,
                    "include_boundaries": boundarySearch
                }
            } else if (isPhoneNumber(searchText)) {
                const phoneNumber = parsePhoneNumber(searchText.trim(), "US")
                if (phoneNumber.isValid() === false) {
                    dispatch(alertActions.error("Phone number is invalid"));
                    return
                } else {
                    searchBody["phone_number"] = phoneNumber
                }
            } else {
                searchBody["username"] = searchText.trim()
            }

            // Set bool filters
            if (subscribers === true) {
                searchBody["subscribers"] = subscribers
            }
            if (facebook === true) {
                searchBody["facebook_acquisition"] = facebook
            }
            if (appsflyer === true) {
                searchBody["appsflyer_acquisition"] = appsflyer
            }
            if (fmx) {
                searchBody["fmx"] = fmx
            }

            if (skippedIds) {
                searchBody["skip"] = skippedIds
            }
            searchTypeResponse = profileService.searchProfilesOnServer(searchBody)
        }

        dispatch(searchRequest());

        searchTypeResponse?.then(response => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        dispatch(alertActions.error(data.message));
                        dispatch(searchFailure(searchText, "Search returned no profile"))
                    } else {
                        dispatch(searchSuccess(searchText, data))
                        navigateTo(`/search-result`).then(r => "")
                    }
                })
            },
            error => {
                dispatch(searchFailure(searchText, error))
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function searchRequest() {
        return {type: profileConstants.MORE_SEARCH_PROFILE_REQUEST}
    }

    function searchSuccess(searchedText, profiles) {
        return {type: profileConstants.MORE_SEARCH_PROFILE_SUCCESS, searchedText, profiles}
    }

    function searchFailure(searchedText, error) {
        return {type: profileConstants.MORE_SEARCH_PROFILE_ERROR, searchedText, error}
    }

}


function getAllSubscribers() {

    return dispatch => {
        dispatch(request());
        profileService.getAllSubscribers()
            .then(subProfiles => {
                    dispatch(success(subProfiles));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: profileConstants.GET_SUBSCRIBERS_REQUEST}
    }

    function success(profiles) {
        return {type: profileConstants.GET_SUBSCRIBERS_SUCCESS, profiles}
    }

    function failure(error) {
        return {type: profileConstants.GET_SUBSCRIBERS_FAILURE, error}
    }
}

function fetchUserProfileFromAccount(searchText, dispatch, account) {
    let accountMemberPromises = account.members?.map(memberId => profileService.getAccountMemberOnServer(memberId))
    Promise.all(accountMemberPromises).then((members) => {
        let profilePromises = []
        members.forEach(member => {
            profilePromises.push(profileService.getProfileByIdOnServer(member.profile_id))
        })
        console.log(profilePromises)
        Promise.all(profilePromises).then((profiles) => {
            console.log(profiles[0])
            dispatch(searchSuccess(searchText, profiles[0]))
            navigateTo("/search-result").then(r => "")
        }).catch(error => {
            console.log(error);
            dispatch(alertActions.error(error.toString()));
        });

    }).catch(error => {
        console.log(error);
        dispatch(alertActions.error(error.toString()));
    });
}

// function demoFetch(dispatch) {
//     let profilePromises = []
//     const data = [59044, 54871, 8977, 64765, 68663, 67953, 40076, 33, 87731]
//     data.forEach(id => {
//         profilePromises.push(profileService.getProfileByIdOnServer(id))
//     })
//     Promise.all(profilePromises).then((profiles) => {
//         console.log("------LIKED-PROFILES------")
//         console.log(profiles)
//     }).catch(error => {
//         console.log(error);
//         dispatch(alertActions.error(error.toString()));
//     });
// }

function changeSelectedProfilePhoto(photo) {
    return dispatch => {
        dispatch(success(photo));
    }

    function success(photo) {
        return {type: profileConstants.CHANGE_SELECTED_PHOTO_SUCCESS, photo}
    };
}

function deleteProfilePhotoAction(profile, photoId, params) {
    return dispatch => {
        dispatch(request({}))

        profileService.deleteProfilePhotoOnServer(profile.id, photoId, params)
            .then(moderationList => {
                    console.log(params);
                    dispatch(success({
                        deleted: true,
                        profileId: profile.id,
                        photoId: photoId,
                        moderationList: moderationList
                    }));
                    if (!_.isEmpty(moderationList)) {
                        moderationList.forEach((moderation) => (
                            dispatch(successModerationList(profile, moderation))
                        ));
                    }

                    dispatch(alertActions.success("Photo deleted and moderation added!"));
                    dispatch(alertActions.clear);
                },
                error => {
                    dispatch(failure({deleted: false, error: error}));
                    console.log(error.toString())
                    console.log(params)
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(response) {
        return {type: profileConstants.DELETE_PROFILE_PHOTO_REQUEST, response}
    }

    function success(response) {
        return {type: profileConstants.DELETE_PROFILE_PHOTO_SUCCESS, response}
    }

    function successModerationList(profileObj, moderation) {
        return {type: moderationConstants.CREATE_ACCOUNT_MODERATION_SUCCESS, profileObj, moderation}
    }

    function failure(response) {
        return {type: profileConstants.DELETE_PROFILE_PHOTO_FAILURE, response}
    }
}

function getReportedProfileAction() {
    return dispatch => {
        profileService.fetchReportedProfilesFromTheServer()
            .then(reportedProfiles => {
                    dispatch(success(reportedProfiles));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    // function request(reportedProfiles) { return { type: profileConstants.REPORTED_PROFILE_REQUEST, reportedProfiles } }
    function success(reportedProfiles) {
        return {type: profileConstants.REPORTED_PROFILE_SUCCESS, reportedProfiles}
    }

    function failure(error) {
        return {type: profileConstants.REPORTED_PROFILE_FAILURE, error}
    }
}

function createNoteAction(profile, accountId, content, tags) {
    return dispatch => {
        dispatch(request({}));

        profileService.createNoteOnServer(accountId, content, tags)
            .then(note => {
                    dispatch(success(profile, note));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function success(profile, note) {
        return {type: profileConstants.CREATE_NOTE_SUCCESS, profile, note}
    }

    function request(response) {
        return {type: profileConstants.CREATE_NOTE_REQUEST, response}
    }

    function failure(error) {
        return {type: profileConstants.CREATE_NOTE_FAILURE, error}
    }
}

function patchNoteAction(profile, accountId, noteId, content, tags, active) {
    return dispatch => {
        dispatch(request({}));

        profileService.patchNoteOnServer(accountId, noteId, content, tags, active)
            .then(note => {
                    dispatch(success(profile, note));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function success(profile, note) {
        return {type: profileConstants.CREATE_NOTE_SUCCESS, profile, note}
    }

    function request(response) {
        return {type: profileConstants.CREATE_NOTE_REQUEST, response}
    }

    function failure(error) {
        return {type: profileConstants.CREATE_NOTE_FAILURE, error}
    }
}

function getNotesFromProfileAction(profile) {
    return dispatch => {
        const notes = []
        const {members} = profile

        members.map(member => {
            if (member.account !== null) {
                notes.push(...((member.account?.notes) ?? []))
            }
        })
        dispatch(success(notes, profile))
    }

    function success(notes, profile) {
        return {type: profileConstants.GET_NOTES_FROM_PROFILE, notes, profile}
    }
}

function deleteNotesAction(profile, accountId, noteId) {
    return dispatch => {
        dispatch(request({}));

        profileService.deleteNoteOnServer(accountId, noteId)
            .then(_ => {
                    dispatch(success(profile, true, noteId));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: profileConstants.DELETE_NOTE_REQUEST}
    }

    function success(profile, deleted, noteId) {
        return {type: profileConstants.DELETE_NOTE_SUCCESS, profile, deleted, noteId}
    }
}

function markReportAsReViewedAction(profile, reportId) {
    return dispatch => {
        profileService.markReportAsReviewedOnServer(reportId)
            .then(_ => {
                    dispatch(success(profile, reportId));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(profile, reportId) {
        return {type: profileConstants.MARK_REPORT_AS_REVIEWED, profile, reportId}
    }
}

function searchProfileWithAccountIdAction(accountId) {
    return dispatch => {
        dispatch(searchRequest());
        profileService.getProfileWithAccountIdOnServer(accountId)
            .then(account => {
                    fetchUserProfileFromAccount(accountId, dispatch, account)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

function deleteAboutMe(profileId, params) {
    return dispatch => {
        dispatch(request());
        profileService.deleteAboutMe(profileId, params)
            .then(response => {
                    dispatch(success(profileId, params.about_value, response));
                    // dispatch to moderation reducer also
                },
                error => {
                    dispatch(failure(error.toString()));
                });
    }

    function request() {
        return {type: profileConstants.DELETE_ABOUT_ME_REQUEST}
    }

    function success(profileId, aboutVal, response) {
        return {type: profileConstants.DELETE_ABOUT_ME_SUCCESS, profileId, aboutVal, response}
    }

    function failure(error) {
        return {type: profileConstants.DELETE_ABOUT_ME_ERROR, error}
    }
}

function deleteUsername(profileId, params) {
    return dispatch => {
        dispatch(request());
        profileService.deleteUsername(profileId, params)
            .then(response => {
                    dispatch(success(profileId, response));
                    // dispatch to moderation reducer also
                },
                error => {
                    dispatch(failure(error.toString()));
                });
    }

    function request() {
        return {type: profileConstants.DELETE_USERNAME_REQUEST}
    }

    function success(profileId, response) {
        return {type: profileConstants.DELETE_USERNAME_SUCCESS, profileId, response}
    }

    function failure(error) {
        return {type: profileConstants.DELETE_USERNAME_ERROR, error}
    }
}

function getTechIssues() {
    return dispatch => {
        dispatch(request())
        profileService.getTechIssues()
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                });
    }

    function request() {
        return {type: profileConstants.GET_TECH_ISSUES_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.GET_TECH_ISSUES_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.GET_TECH_ISSUES_ERROR, error}
    }
}

function getBillingIssues() {
    return dispatch => {
        dispatch(request())
        profileService.getBillingIssues()
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                });
    }

    function request() {
        return {type: profileConstants.GET_BILLING_ISSUES_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.GET_BILLING_ISSUES_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.GET_BILLING_ISSUES_ERROR, error}
    }
}

function getModIssues() {
    return dispatch => {
        dispatch(request())
        profileService.getModIssues()
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                });
    }

    function request() {
        return {type: profileConstants.GET_MOD_ISSUES_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.GET_MOD_ISSUES_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.GET_MOD_ISSUES_ERROR, error}
    }
}

function getAndroidIssues() {
    return dispatch => {
        dispatch(request())
        profileService.getAndroidIssues()
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                });
    }

    function request() {
        return {type: profileConstants.GET_ANDROID_ISSUES_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.GET_ANDROID_ISSUES_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.GET_ANDROID_ISSUES_ERROR, error}
    }
}

function getExpandedProfile(profileId) {
    return dispatch => {
        dispatch(request())
        profileService.getExpandedProfile(profileId)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.GET_EXPANDED_PROFILE_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.GET_EXPANDED_PROFILE_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.GET_EXPANDED_PROFILE_ERROR, error}
    }
}

function addMonths(accountId, mnths) {
    return dispatch => {
        dispatch(request())
        profileService.addMonths(accountId, mnths)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.ADD_MONTHS_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.ADD_MONTHS_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.ADD_MONTHS_ERROR, error}
    }
}

function addSparks(accountId, sprks) {
    return dispatch => {
        dispatch(request())
        profileService.addSparks(accountId, sprks)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.ADD_SPARKS_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.ADD_SPARKS_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.ADD_SPARKS_ERROR, error}
    }
}

function toggleSubscription(accountId){
    return dispatch => {
        dispatch(request())
        profileService.toggleSubscription(accountId)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.TOGGLE_SUBSCRIPTION_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.TOGGLE_SUBSCRIPTION_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.TOGGLE_SUBSCRIPTION_ERROR, error}
    }
}

function toggleBugsy(accountId) {
    return dispatch => {
        dispatch(request())
        profileService.toggleBugsy(accountId)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.TOGGLE_BUGSY_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.TOGGLE_BUGSY_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.TOGGLE_BUGSY_ERROR, error}
    }
}

function resetInteractions(accountId) {
    return dispatch => {
        dispatch(request())
        profileService.resetInteractions(accountId)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.RESET_INTERACTIONS_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.RESET_INTERACTIONS_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.RESET_INTERACTIONS_ERROR, error}
    }
}


function getBugsyProfiles() {
    return dispatch => {
        dispatch(request())
        profileService.getBugsyProfiles()
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.GET_BUGSY_PROFILES_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.GET_BUGSY_PROFILES_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.GET_BUGSY_PROFILES_ERROR, error}
    }
}

function reportMoreInfo(reportId, macroId) {
    return dispatch => {
        dispatch(request())
        profileService.reportMoreInfo(reportId, macroId)
            .then(response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()))
                });
    }

    function request() {
        return {type: profileConstants.REPORT_MORE_INFO_REQUEST}
    }

    function success(response) {
        return {type: profileConstants.REPORT_MORE_INFO_SUCCESS, response}
    }

    function failure(error) {
        return {type: profileConstants.REPORT_MORE_INFO_ERROR, error}
    }
}