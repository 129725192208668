import {hashtagsConstants} from '../Constants'

const INIT_STATE = {
    hashtagsPreferenceList: [],
    hashtagsBoundaryList: [],
    fetching: false,
    updating: false
}

export function hashtagsReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case hashtagsConstants.HASHTAG_SEARCH_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case hashtagsConstants.HASHTAG_REQUEST_SUCCESSFUL:
            const response = action.response

            const {preferences, boundaries} = response;
            return {
                ...state,
                fetching: false,
                hashtagsPreferenceList: preferences,
                hashtagsBoundaryList: boundaries
            }
        case hashtagsConstants.HASHTAG_REQUEST_FAILURE:
            return {
                ...state,
                fetching: false,
            }
        case hashtagsConstants.HASHTAG_UPDATE_REQUEST:
            return {
                ...state,
                updating: true,
            }
        case hashtagsConstants.HASHTAG_UPDATE_REQUEST_SUCCESSFUL:
            const updateResponse = action.response

            const updatedPreferences = updateResponse.preferences
            const updatedBoundaries = updateResponse.boundaries

            const existingPreferenceList = [...state.hashtagsPreferenceList ]
            const existingBoundaryList = [...state.hashtagsBoundaryList ]

            updatedPreferences.forEach((preference) => {
                const objIndex = existingPreferenceList.findIndex((obj => obj.tag === preference.tag))
                existingPreferenceList[objIndex] = preference
            })

            updatedBoundaries.forEach((boundary) => {
                const objIndex = existingBoundaryList.findIndex((obj => obj.tag === boundary.tag))
                existingBoundaryList[objIndex] = boundary
            })

            return {
                ...state,
                updating: false,
                hashtagsPreferenceList: existingPreferenceList,
                hashtagsBoundaryList: existingBoundaryList
            }
        case hashtagsConstants.HASHTAG_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                updating: false,
            }
        default:
            return state
    }
}