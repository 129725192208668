export const moderationConstants = {
    GET_ACCOUNT_MODERATION_REQUEST: 'GET_ACCOUNT_MODERATION_REQUEST',
    GET_ACCOUNT_MODERATION_SUCCESS: 'GET_ACCOUNT_MODERATION_SUCCESS',
    GET_ACCOUNT_MODERATION_FAILURE: 'GET_ACCOUNT_MODERATION_FAILURE',

    CREATE_ACCOUNT_MODERATION_REQUEST: 'CREATE_ACCOUNT_MODERATION_REQUEST',
    CREATE_ACCOUNT_MODERATION_SUCCESS: 'CREATE_ACCOUNT_MODERATION_SUCCESS',
    CREATE_ACCOUNT_MODERATION_FAILURE: 'CREATE_ACCOUNT_MODERATION_FAILURE',

    PATCH_ACCOUNT_MODERATION_REQUEST: 'PATCH_ACCOUNT_MODERATION_REQUEST',
    PATCH_ACCOUNT_MODERATION_SUCCESS: 'PATCH_ACCOUNT_MODERATION_SUCCESS',
    PATCH_ACCOUNT_MODERATION_FAILURE: 'PATCH_ACCOUNT_MODERATION_FAILURE',

    DELETE_ACCOUNT_MODERATION_REQUEST: 'DELETE_ACCOUNT_MODERATION_REQUEST',
    DELETE_ACCOUNT_MODERATION_SUCCESS: 'DELETE_ACCOUNT_MODERATION_SUCCESS',
    DELETE_ACCOUNT_MODERATION_FAILURE: 'DELETE_ACCOUNT_MODERATION_FAILURE',

    GET_MODERATION_TAGS_REQUEST: 'GET_MODERATION_TAGS_REQUEST',
    GET_MODERATION_TAGS_SUCCESS: 'GET_MODERATION_TAGS_SUCCESS',
    GET_MODERATION_TAGS_ERROR: 'GET_MODERATION_TAGS_ERROR',
};