export const config = {
    baseURL:    process.env.REACT_APP_BASE_URL,
    chatBaseUrl: process.env.REACT_APP_CHAT_URL,
    //timeout: 1000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json;charset',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Expose-Headers': 'access_token',
    },
};