import {profileConstants} from '../Constants'
import _ from "lodash"
import {storageService} from "../Storage";
import {storageConstants} from "../Constants/storage.constants";
import { UserProfile } from "../Helpers/UserProfile";
import {Form} from "antd";

const { PROFILE_WITH_UNREVIEWED_PHOTO_REQUEST, PROFILE_WITH_UNREVIEWED_PHOTO_SUCCESS, PROFILE_WITH_UNREVIEWED_PHOTO_FAILURE } = profileConstants
const { MARK_PROFILE_PHOTO_REVIEWED_REQUEST, MARK_PROFILE_PHOTOS_REVIEWED_SUCCESS, MARK_PROFILE_PHOTO_FAILURE } = profileConstants
const { DELETE_PROFILE_PHOTO_SUCCESS, DELETE_PROFILE_PHOTO_FAILURE, DELETE_PROFILE_PHOTO_REQUEST } = profileConstants
const { CHANGE_SELECTED_PHOTO_SUCCESS } = profileConstants 
const { GET_TECH_ISSUES_REQUEST, GET_TECH_ISSUES_SUCCESS, GET_TECH_ISSUES_ERROR } = profileConstants;
const { GET_BILLING_ISSUES_REQUEST, GET_BILLING_ISSUES_SUCCESS, GET_BILLING_ISSUES_ERROR } = profileConstants;
const { GET_MOD_ISSUES_REQUEST, GET_MOD_ISSUES_SUCCESS, GET_MOD_ISSUES_ERROR } = profileConstants;
const { GET_ANDROID_ISSUES_REQUEST, GET_ANDROID_ISSUES_SUCCESS, GET_ANDROID_ISSUES_ERROR } = profileConstants;
const { GET_EXPANDED_PROFILE_REQUEST, GET_EXPANDED_PROFILE_SUCCESS, GET_EXPANDED_PROFILE_ERROR } = profileConstants;
const { ADD_SPARKS_REQUEST, ADD_SPARKS_SUCCESS, ADD_SPARKS_ERROR } = profileConstants;
const { ADD_MONTHS_REQUEST, ADD_MONTHS_SUCCESS, ADD_MONTHS_ERROR } = profileConstants;
const { GET_BUGSY_PROFILES_REQUEST, GET_BUGSY_PROFILES_SUCCESS, GET_BUGSY_PROFILES_ERROR } = profileConstants;
const { TOGGLE_BUGSY_REQUEST, TOGGLE_BUGSY_SUCCESS, TOGGLE_BUGSY_ERROR } = profileConstants;

const INIT_STATE = {
    reportedProfiles: [],
    reportedProfilesFetched: false,
    newProfilesFetched: false,
    reviewedProfilesFetched: false,
    newAutoProfilesFetched: false,
    markingNewProfileAsReviewed: false,
    newProfiles: [],
    reviewedProfiles: [],
    newAutoProfiles: [],
    markingNewAutoProfileAsReviewed: false,
    profiles: [],
    fetched: false,
    profile: new UserProfile(),
    expandedProfile: new UserProfile(),
    modalPhoto: {},
    fetchingExpanded: false,
    fetchedExpanded: false,
    deletingProfilePhoto: false,
    markingProfileAsReviewed: false,
    photoDeleted: false,
    searchingProfile: false,
    searchProfileResult: [],
    hashtagProfileResult: [],
    searchedText: "",
    searchedHashtag: "",
    reviewed: false,
    chatProfiles: [],
    chatProfilesLoading: false,
    creatingNoteInProgress: false,
    patchingNoteInProgress: false,
    profileNotesMap: new Map(),
    unReadMessageCount: 0,
    changingSelectedPhoto: false,
    deletingAboutMe: false,
    techIssues: [],
    fetchingTechIssues: false,
    techIssuesFetched: false,
    billingIssues: [],
    fetchingBillingIssues: false,
    billingIssuesFetched: false,
    modIssues: [],
    fetchingModIssues: false,
    modIssuesFetched: false,
    androidIssues: [],
    fetchingAndroidIssues: false,
    androidIssuesFetched: false,
    addingMonths: false,
    addingSparks: false,
    gettingBugsyProfiles: false,
    bugsyProfiles: [],
    bugsiesFetched: false,
    togglingBugsy: false,
    searchResultCount: 0
}

export function profileReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case PROFILE_WITH_UNREVIEWED_PHOTO_REQUEST:
            return {
                ...state,
                fetched: false,
                profiles: action.profiles
            }
        case PROFILE_WITH_UNREVIEWED_PHOTO_SUCCESS:
            // console.log("profiles", action.profiles)

            return {
                ...state,
                fetched: true,
                profiles: action.profiles
            }
        case PROFILE_WITH_UNREVIEWED_PHOTO_FAILURE:
            return {
                ...state
            }
        case CHANGE_SELECTED_PHOTO_SUCCESS:
                return {
                    ...state,
                    modalPhoto: action.photo
                }
        case DELETE_PROFILE_PHOTO_REQUEST:
            return {
                ...state,
                deletingProfilePhoto: true
            }
            case DELETE_PROFILE_PHOTO_SUCCESS:
                {
                    let profiles = [ ...state.profiles ]
                    let newProfiles = [...state.newProfiles]
                    let reviewedProfiles = [...state.reviewedProfiles]
                    let searchResult = [...state.searchProfileResult]
                    let reportedProfiles = [...state.reportedProfiles]
                    let chatProfiles = [...state.chatProfiles]
                    let moderatedProfiles = [...state.newAutoProfiles]
                    let billingIssues = [...state.billingIssues]
                    let techIssues = [...state.techIssues]
                    let androidIssues = [...state.androidIssues]
                    const deletionResponse = action.response
        
                    const profileId = deletionResponse.profileId
                    const photoId = deletionResponse.photoId
                    const mod = deletionResponse.moderationList;
        

                    profiles?.forEach((profile) => {
                        //for each profile, and for each member of the profile
                        if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                        }
                    });
                    

                    newProfiles?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })

                    reviewedProfiles?.forEach((profile) => {
                        if(profile.id === profileId) {
                            profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                            const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                        }
                    })

                    searchResult?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })

                    reportedProfiles?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })

                    chatProfiles?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })
                    
                    moderatedProfiles?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })

                    billingIssues?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })
                    
                    techIssues?.forEach((profile) => {
                            if(profile.id === profileId) {
                                profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                                const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                    }
                            })

                    androidIssues?.forEach((profile) => {
                        if(profile.id === profileId) {
                            profile.photos = profile.photos?.filter((photo) => photo.id !== photoId);
                            const remainPhotos = profile.photos?.filter((photo) => photo.id !== 0);
                                }
                        })

            return {
                ...state,
                deletingProfilePhoto: false,
                profiles: profiles,
                newProfiles: newProfiles,
                reviewedProfiles: reviewedProfiles,
                reportedProfiles: reportedProfiles,
                chatProfiles: chatProfiles,
                newAutoProfiles: moderatedProfiles
            }
        }

        case DELETE_PROFILE_PHOTO_FAILURE:
            return {
                ...state,
                deletingProfilePhoto: false,
                photoDeletedError: action.response
            }

        case MARK_PROFILE_PHOTO_REVIEWED_REQUEST:
            return {
                ...state,
                markingProfileAsReviewed: true
            }
        case MARK_PROFILE_PHOTOS_REVIEWED_SUCCESS:
            const deletionResponse = action.response
            let profiles = [ ...state.profiles ]
            const profileId = deletionResponse.id
            profiles = profiles.filter((profile) => profile.id !== profileId)
            return {
                ...state,
                markingProfileAsReviewed: false,
                profiles: profiles
            }
        case MARK_PROFILE_PHOTO_FAILURE:
            return {
                ...state,
                markingProfileAsReviewed: false,
            }
        case profileConstants.MARK_NEW_PROFILE_REQUEST:
            return {
                ...state,
                markingNewProfileAsReviewed: true
            }
        case profileConstants.MARK_NEW_PROFILE_SUCCESS:
            const deletionNewResponse = action.response
            let newProfiles = [ ...state.newProfiles ]
            let oldProfiles = [ ...state.profiles ]

            const newProfileId = deletionNewResponse.id
            newProfiles = newProfiles.filter((profile) => profile.id !== newProfileId)
            oldProfiles = oldProfiles.filter((profile) => profile.id !== newProfileId)
            return {
                ...state,
                markingNewProfileAsReviewed: false,
                profiles: oldProfiles,
                newProfiles: newProfiles
            }
        case profileConstants.MARK_NEW_PROFILE_ERROR:
            return {
                ...state,
                markingNewProfileAsReviewed: false,
            }
        case profileConstants.REPORTED_PROFILE_REQUEST:
            return {
                ...state,
                reportedProfilesFetched: false,
            }
        case profileConstants.REPORTED_PROFILE_SUCCESS:

            return {
                ...state,
                reportedProfilesFetched: true,
                reportedProfiles: action.reportedProfiles
            }
        case profileConstants.MARK_REPORT_AS_REVIEWED:
            let reportedProfileList = [...state.reportedProfiles] ?? []
            reportedProfileList.forEach(reportedProfile => {
                if(reportedProfile.id === action.profile.id) {
                    const unReviewedCount = reportedProfile.reports.filter(reportObject => reportObject.reviewed === false)?.length ?? 0
                    if(unReviewedCount > 0) {
                        reportedProfile.reports.forEach(report => {
                            if(report.id === action.reportId) {
                                report.reviewed = true
                            }
                        })
                    } else {
                        reportedProfileList = reportedProfileList.filter(profileIObject => profileIObject.id !== action.profile.id)
                    }

                }
            })
            return {
                ...state,
                reportedProfiles: reportedProfileList
            }
        case profileConstants.REPORTED_PROFILE_FAILURE:
            return {
                ...state,
                reportedProfilesFetched: true
            }

        case profileConstants.NEW_PROFILE_REQUEST:
            return {
                ...state,
                newProfilesFetched: false
            }

        case profileConstants.NEW_PROFILE_SUCCESS:
            return {
                ...state,
                newProfiles: action.newProfiles,
                newProfilesFetched: true
            }
        case profileConstants.NEW_PROFILE_ERROR:
            return {
                ...state,
                newProfilesFetched: true
            }

        case profileConstants.REVIEWED_PROFILE_REQUEST:
            return {
                ...state,
                reviewedProfilesFetched: false
            }

        case profileConstants.REVIEWED_PROFILE_SUCCESS:
            return {
                ...state,
                reviewedProfiles: action.profiles,
                reviewedProfilesFetched: true
            }
        case profileConstants.REVIEWED_PROFILE_ERROR:
            return {
                ...state,
                reviewedProfilesFetched: true
            }

        case profileConstants.SEARCH_PROFILE_REQUEST:
            return {
                ...state,
                searchingProfile: true,
                searchProfileResult: [],
                searchedText: ""
            }
        case profileConstants.SEARCH_PROFILE_SUCCESS:

        let result = action.profiles.profiles ? action.profiles : action;
        console.log('search result')
        console.log(result);
            return {
                ...state,
                searchingProfile: false,
                searchProfileResult: [...result.profiles],
                searchResultCount: result.count ? result.count : result.profiles.length,
                searchedText: action.searchedText
            }
        case profileConstants.SEARCH_PROFILE_ERROR:
            return {
                ...state,
                searchingProfile: false,
                searchProfileResult: [],
                searchedText: action.searchedText
            }
        case profileConstants.MORE_SEARCH_PROFILE_REQUEST:
            return {
                ...state,
                searchingProfile: true
            }       
        case profileConstants.MORE_SEARCH_PROFILE_SUCCESS:
            let moreArray = action.profiles.profiles;
            let existingArray = state.searchProfileResult;

            existingArray.push(...moreArray);
                return {
                    ...state,
                    searchingProfile: false,
                    searchProfileResult: existingArray,
                    searchedText: action.searchedText
                }
        case profileConstants.MORE_SEARCH_PROFILE_ERROR:
            return {
                ...state,
                searchingProfile: false,
                searchedText: action.searchedText
            }
        case profileConstants.PROFILE_WITH_CHATS_REQUEST:
            return {
                ...state,
                chatProfilesLoading: true,
            }
        case profileConstants.PROFILE_WITH_CHATS:
            return {
                ...state,
                chatProfiles: action.profiles.profiles,
                chatProfilesLoading: false,
                unReadMessageCount: action.unReadMessageCount
            }
        case profileConstants.REMOVE_PROFILES_FROM_WITH_CHATS:
            let newChatProfiles = [ ...state.chatProfiles ]
            let profilesToRemove = action.profilesToRemove
            // console.log("profilesToRemove")
            // console.log(profilesToRemove)

            const profileNewList = []
            const profileIdToRemove = profilesToRemove[0]

            storageService.saveToStore()

            // console.log("newChatProfiles-Before")
            // console.log(newChatProfiles)
            newChatProfiles.forEach(profile => {
                if(profile.id !== profileIdToRemove) {
                    profileNewList.push(profile)
                }
            })
            // console.log("newChatProfiles-Before")
            // console.log(profileNewList)
            //
            // console.log("action.unReadMessageCount")
            // console.log(action.unReadMessageCount)

            let count = 0
            if(state.unReadMessageCount > 0) {
                count = state.unReadMessageCount - 1
            }

            return {
                ...state,
                chatProfiles: profileNewList,
                unReadMessageCount: count
            }
        case profileConstants.CREATE_NOTE_REQUEST:
            return {
                ...state,
                creatingNoteInProgress: true
            }
        case profileConstants.DELETE_NOTE_SUCCESS:
        
            let cachedNotesSuccess = new Map(state.profileNotesMap)
            const noteProfileSuccess = action.profile

            let noteListSuccess = cachedNotesSuccess.get(noteProfileSuccess.id) ?? []
            noteListSuccess = noteListSuccess.filter((note) => note.id !== action.noteId)

            cachedNotesSuccess.set(noteProfileSuccess.id, noteListSuccess);

            return {
                ...state,
                creatingNoteInProgress: false,
                profileNotesMap: cachedNotesSuccess
            }
        case profileConstants.CREATE_NOTE_SUCCESS:
            const cachedNotes = new Map(state.profileNotesMap)
            const noteProfile = action.profile

            const noteList = cachedNotes.get(noteProfile.id) ?? []

            const cachedProfileList = [...noteList]

            cachedProfileList.push(action.note)
            cachedNotes.set(noteProfile.id, cachedProfileList)

            return {
                ...state,
                creatingNoteInProgress: false,
                profileNotesMap: cachedNotes
            }
        case profileConstants.CREATE_NOTE_FAILURE:
            return {
                ...state,
                creatingNoteInProgress: false
            }
        case profileConstants.GET_NOTES_FROM_PROFILE:
            const notesMap = new Map(state.profileNotesMap)

            const profile = action.profile

            notesMap.set(profile.id, action.notes)

            return {
                ...state,
                profileNotesMap: notesMap
            }
        case profileConstants.PATCH_NOTE_REQUEST:
            return {
                    ...state,
                    patchingNoteInProgress: true
            }
        case profileConstants.PATCH_NOTE_FAILURE:
                return {
                    ...state,
                    patchingNoteInProgress: false
            }
        case profileConstants.PATCH_NOTE_SUCCESS:
                const cachedNotesObj = new Map(state.profileNotesMap)
                const noteProfileObj = action.profile
    
                const noteListObj = cachedNotesObj.get(noteProfileObj.id) ?? []
    
                const cachedProfileListObj = [...noteListObj]
    
                cachedProfileListObj.push(action.note)
                cachedNotesObj.set(noteProfileObj.id, cachedProfileListObj)
    
                return {
                    ...state,
                    patchingNoteInProgress: false,
                    profileNotesMap: cachedNotesObj
                }
        case profileConstants.NEW_AUTO_PROFILE_REQUEST:
            return {
                ...state,
                newAutoProfilesFetched: false
            }
        case profileConstants.NEW_AUTO_PROFILE_SUCCESS:
            return {
                ...state,
                newAutoProfiles: action.newAutoProfiles,
                newAutoProfilesFetched: true
            }
        case profileConstants.NEW_AUTO_PROFILE_ERROR:
            return {
                ...state,
                newAutoProfilesFetched: true
            }
        case profileConstants.CLEAR_AI_MOD_PROFILE_REQUEST:
            return {
                ...state,
                markingNewAutoProfileAsReviewed: true
            }
        case profileConstants.CLEAR_AI_MOD_PROFILE_SUCCESS:
            const clearAiModProfileResponse = action.response
            let newAutoProfiles = [ ...state.newAutoProfiles ]

            const newAutoProfileId = clearAiModProfileResponse.id
            newAutoProfiles = newAutoProfiles.filter((profile) => profile.id !== newAutoProfileId)
            return {
                ...state,
                markingNewAutoProfileAsReviewed: false,
                newAutoProfiles: newAutoProfiles
            }
        case profileConstants.CLEAR_AI_MOD_PROFILE_ERROR:
            return {
                ...state,
                markingNewAutoProfileAsReviewed: false,
            }
        case profileConstants.TOGGLE_MODERATION_ACTIVE_SUCCESS:

            const mod = action.moderation;
            let prof = state.expandedProfile;

            prof.members.map((member) => {
                    // if the account id of the moderation and the account id of the member match
                    if(mod.account_id === member.account?.id) {
                        for (let index in member.account.moderation_actions) {
                            if(member.account.moderation_actions[index].id === mod.id) {
                                // update the moderation if the id matches
                                member.account.moderation_actions[index] = mod;
                            }
                        }
                    }
                });

            return {
                ...state,
                expandedProfile: prof
            }
        case profileConstants.DELETE_ABOUT_ME_REQUEST:
            return {
                ...state,
                deletingAboutMe: true
            }
        case profileConstants.DELETE_ABOUT_ME_SUCCESS:
            // get profile and replace about me with nothing
            console.log(action);
            console.log(action.response)
            console.log(action.aboutVal)
            let selectedProfile = state.expandedProfile;
            selectedProfile.about.value = action.aboutVal;
            selectedProfile.members.map(member => {
                
                if(!_.isEmpty(member.account)){
                    member.account?.moderation_actions.push(...action.response);
                }

            })

            return {
                ...state,
                deletingAboutMe: false,
                expandedProfile: selectedProfile
            }
        case profileConstants.DELETE_ABOUT_ME_FAILURE:
            return {
                ...state,
                deletingAboutMe: false
            }
        case GET_TECH_ISSUES_REQUEST:
            return {
                ...state,
                fetchingTechIssues: true
            }
        case GET_TECH_ISSUES_SUCCESS:
            return {
                ...state,
                fetchingTechIssues: false,
                techIssues: action.response,
                techIssuesFetched: true
            }
        case GET_TECH_ISSUES_ERROR:
            return {
                ...state,
                fetchingTechIssues: false
            }
        case GET_BILLING_ISSUES_REQUEST:
            return {
                ...state,
                fetchingBillingIssues: true
            }
        case GET_BILLING_ISSUES_SUCCESS:
            return {
                ...state,
                fetchingBillingIssues: false,
                billingIssues: action.response,
                billingIssuesFetched: true
            }
        case GET_BILLING_ISSUES_ERROR:
            return {
                ...state,
                fetchingBillingIssues: false
            }
        case GET_MOD_ISSUES_REQUEST:
            return {
                ...state,
                fetchingModIssues: true
            }
        case GET_MOD_ISSUES_SUCCESS:
            return {
                ...state,
                fetchingModIssues: false,
                modIssues: action.response,
                modIssuesFetched: true
            }
        case GET_MOD_ISSUES_ERROR:
            return {
                ...state,
                fetchingModIssues: false
            }
        case GET_ANDROID_ISSUES_REQUEST:
                return {
                    ...state,
                    fetchingAndroidIssues: true
                }
        case GET_ANDROID_ISSUES_SUCCESS:
                return {
                    ...state,
                    fetchingAndroidIssues: false,
                    androidIssues: action.response,
                    androidIssuesFetched: true
                }
        case GET_ANDROID_ISSUES_ERROR:
                return {
                    ...state,
                    fetchingAndroidIssues: false
                }
        case GET_EXPANDED_PROFILE_REQUEST:
            return{
                ...state,
                fetchingExpanded: true,
                fetchedExpanded: false
            }
        case GET_EXPANDED_PROFILE_SUCCESS:
            return{
                ...state,
                expandedProfile: action.response,
                modalPhoto: action.response.photos[0],
                fetchedExpanded: true,
                fetchingExpanded: false
            }
        case GET_EXPANDED_PROFILE_ERROR:
            return{
                ...state,
                fetchedExpanded: false,
                fetchingExpanded: false

            }
        case ADD_MONTHS_REQUEST:
            return{
                ...state,
                addingMonths: true
            }
        case ADD_MONTHS_SUCCESS:
            return{
                ...state,
                addingMonths: false
            }
        case ADD_MONTHS_ERROR:
            return{
                ...state,
                addingMonths: false
            }
        case ADD_SPARKS_REQUEST:
                return{
                    ...state,
                    addingSparks: true
                }
        case ADD_SPARKS_SUCCESS:
                return{
                    ...state,
                    addingSparks: false
                }
        case ADD_SPARKS_ERROR:
                return{
                    ...state,
                    addingSparks: false
                }
        case GET_BUGSY_PROFILES_REQUEST:
            return{
                ...state,
                gettingBugsyProfiles: true
            }
        case GET_BUGSY_PROFILES_SUCCESS:
            return{
                ...state,
                bugsiesFetched: true,
                bugsyProfiles: action.response,
                gettingBugsyProfiles: false
            }
        case GET_BUGSY_PROFILES_ERROR:
            return{
                ...state,
                gettingBugsyProfiles: false,
                bugsiesFetched: false
            }
        case TOGGLE_BUGSY_REQUEST:
            return{
                ...state,
                togglingBugsy: true
            }
        default:
            return state
    }
}