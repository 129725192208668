import React from "react";
import './App.css';
import RouteComponent from "./route/RouteComponent";
import {Location} from "@gatsbyjs/reach-router";

function App() {


  return (
      <Location>
        {({location}) => (
            <RouteComponent location={location}/>
        )}
      </Location>
  );
}

export default App;
