import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import React, {useEffect} from "react";
import {ProfileTableRenderer} from "../../components";

function BillingIssues(props) {

    useEffect(() => {
        props.getBillingIssues()
    }, [])

    const billingIssueSelected = (profileId) => {
    }

    return ( 
    <ProfileTableRenderer profiles={props.billingIssues}
                                 fetched={props.billingIssuesFetched}
                                 profileSelectedAction={billingIssueSelected}
                                 enableRowSelection={false}
    />
    )
}


const actionCreators = {
    getBillingIssues: profileActions.getBillingIssues,
};

function mapStateToProps(state) {
    const { billingIssues, billingIssuesFetched } = state.profileReducer
    return { billingIssues, billingIssuesFetched };
}


export default connect(mapStateToProps, actionCreators)(BillingIssues);
