
// Module COOKIES_TIME
export const COOKIES_TIME = 7;
export const NOTIFICATION_DURATION = 4;

export const FETCH_AUTH_FAILED = "FETCH_AUTH_FAILED";
export const FETCH_AUTH_START = "FETCH_AUTH_START";
export const FETCH_AUTH_END = "FETCH_AUTH_END";


export const ADMIN_LOGIN_LINK = "/admin/log-in";


export * from './alert.constants';
export * from './api.constants';
export * from './authentication.constants';
export * from './profile.constants';
export * from './moderation.constants';
export * from './chat.constants';
export * from './hashtags.constants';
export * from './macro.constants';
export * from './ml-label.constants';
