import uuid from "react-uuid";
import {Col, Row, message, Cascader, Popconfirm, Button, Tag, Modal, Typography, Card} from "antd";
import React, {useState, useEffect} from "react";
import _, {capitalize, result} from "lodash";
import {profileActions} from "../../appRedux/Actions";
import {connect} from "react-redux";
import {storageService} from "../../appRedux/Storage";
import {storageConstants} from "../../appRedux/Constants/storage.constants";
import {completeImages} from "../../Utilities";
import blankImg from "../../assets/img.png";
import { Tooltip } from 'antd';
import {DeleteTwoTone, MessageTwoTone} from '@ant-design/icons';

const {Text, Title} = Typography;

function ProfilePhotos(props) {
    const {profile, expandedProfile, modalPhoto, currentPhoto} = props;

    const usedPhoto = currentPhoto ?? modalPhoto

    const types = [
        {
            value: 'Free',
            label: 'Free',
        },
        {
            value: 'Suspension',
            label: 'Suspension',
        },
        {
            value: 'Ban',
            label: 'Ban',
        }
    ]

    const storedModerationTags = storageService.getFromStore(storageConstants.MODERATION_TAGS) ?? []
    let options = []

    storedModerationTags.split(",").forEach((tag) => {
        options.push({
            value: tag,
            label: capitalize(tag),
            children: types,
        })
    })

    let photos = completeImages(profile.photos, true);


    const deletePhotoText = 'Are you sure to you want to delete this photo?';
    const errorRequireReason = 'Select a reason for deleting the photo';

    const [selectedImage, setSelectedImage] = useState();
    const [defaultReason, setDefaultReason] = useState("");
    const [photoModerationAction, showModerationAction] = useState(true);

    const [allImage, setAllImage] = useState(completeImages(profile.photos, true));
    const blur_emoji = "🚫"
    const photo_flag_emoji_map = {
        "baby": "🍼",
        "pre_teen": "🍼",
        "teenager": "🍼",
        "toddler": "🍼",
        "general_nsfw": "🍑",
        "yes_sex_toy": "🕹️",
        "yes_female_nudity": "🍆",
        "yes_male_nudity": "🍆",
        "general_suggestive": "🍑",
        "gun_in_hand": "🔫",
        "gun_not_in_hand": "🔫",
        "knife_in_hand": "🔪️",
        "knife_not_in_hand": "🔪️",
        "a_little_bloody": "🩸",
        "other_blood": "🩸",
        "very_bloody": "🩸",
        "yes_nazi": "☠",
        "yes_kkk": "☠",
        "yes_terrorist": "☠",
        "yes_pills": "💊",
        "illicit_injectables": "💊",
        "medical_injectables": "💊",
        "no_demographics": "🚷",
        "reverse_match": "🖼️",
        "text": "🔤",
        "yes_spam": "📔",
        "violence": "📔",
        "yes_bullying": "📔",
        "self_harm": "📔",
        "like_pass_ratio": "🚨",
        "like_threshold": "🚨",
    };

    useEffect(() => {
        photos = completeImages(profile.photos, true);
    }, [photos, profile, completeImages]);

    function sortImage(position) {
        let tempImage = allImage[position]
        showModerationAction(tempImage.id !== 0)
        allImage[position] = selectedImage
        setSelectedImage(tempImage)
        setAllImage(allImage)
        setDefaultReason("")
    }

    function handleChange(value) {
        if (!_.isEmpty(value)) {
            setDefaultReason(value)
        }
    }

    function proceedToDelete() {
        const photoId = usedPhoto.id
        if (_.isEmpty(defaultReason)) {
            message.error(errorRequireReason).then(r => "")
        } else {
            const tags = defaultReason[0]
            const type = defaultReason[1]

            const params = {
                type: type,
                active: true,
                tags: [tags],
                reason: tags
            }
            props.deletePhoto(profile, photoId, params);
        }
        ;

        let currentVal = document.getElementById('chatMessage').value;

    }

    function quickDelete(tag, macro_id) {
        const photoId = usedPhoto.id
        const params = {
            type: "Free",
            active: true,
            tags: [tag],
            reason: tag,
            macro_id: macro_id
        }
        props.deletePhoto(profile, photoId, params);
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function getFlagIcon(flag) {
        return <Text key={uuid()}> {photo_flag_emoji_map[flag]} </Text> ?? <Text key={uuid()}> {flag} </Text>
    }

    function getReverseResults(photo) {
        let reverse_results = photo?.ml_reverse_results?.matches ?? null
        console.log(reverse_results)
        if (reverse_results) {
            let out = []
            reverse_results.forEach(function(item, index) {
                console.log(item)
                let site = ''
                if (item?.urls[0]?.match("^.+?[^\\/:](?=[?\\/]|$)")[0] === undefined) {
                    site = 'undefined'
                } else {
                    site = item?.urls[0]?.match("^.+?[^\\/:](?=[?\\/]|$)")[0]
                }

                out.push(
                    <Card
                    size="small"
                    bordered={false}
                    style={{
                        width: "300px",
                        overflow: "hidden",
                        marginBottom: "10px"
                    }}
                    key={uuid()}>
                        <Title level={5} key={uuid()} style={{marginBottom: "0px"}}>Reverse image matches</Title>
                    </Card>
                    )
                out.push(
                    <Card
                        size="small"
                        title={site}
                        extra={(item.similarity_score * 100).toFixed(0) + "%"}
                        bordered={false}
                        style={{
                            width: "300px",
                            overflow: "hidden",
                            marginBottom: "20px"
                        }}
                        key={uuid()}
                    >
                        {item.urls.map((url) =>
                            <a href={url} style={{whiteSpace: "nowrap"}}>{url}</a>)}
                    </Card>
                )
                })
            return out
            }
        else {
            return null
        }



        // let out =  reverse_results.map((result) =>
        //     <Card title="Small size card">
        // );


    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    return (<>
            <Col span={24}>
                <div onClick={showModal} key={uuid()}
                     className="table-img2 photo-mod-div">
                    <img className="expanded-profile-image" src={usedPhoto?.card_url ?? blankImg} />
                    <div className="photo-mod-tags-div photo-mod-tags-full">
                        {usedPhoto?.pending_blur ?
                            <Tooltip title={"pending_blur"} color="red" key={uuid()}>
                                <Tag key={uuid()} color="red">
                                    <Text key={uuid()}>{blur_emoji}</Text>
                                </Tag> </Tooltip> : ""}
                        {usedPhoto?.ml_flagged?.length > 0 ? usedPhoto.ml_flagged.map((flag) =>
                            <Tooltip title={flag} color="red" key={uuid()}>
                            <Tag key={uuid()} color="red">
                                {getFlagIcon(flag)}
                            </Tag> </Tooltip>) : ""}
                    </div>
                    <Row className="photo-details-div">
                        <div className="photo-member-name">
                            {expandedProfile.members?.length === 1 ? expandedProfile.members?.map((m) => {
                                    return <Text key={uuid()}> {m.display_name} </Text>
                                }) :
                                <small>{expandedProfile.members[0]?.display_name + " & " + expandedProfile.members[1]?.display_name}</small>
                            }
                        </div>
                        {expandedProfile.members?.map((m) => {
                            let age = m.date_of_birth.visible ? getAge(m.date_of_birth.value) : "(age hidden)";
                            let orientation = m.orientation.visible ? m.orientation.value : "(orientation hidden)";
                            let gender = m.gender.visible ? m.gender.value : "(gender hidden)";
                            return <Row key={uuid()}
                                     className="photo-member-deets">{(m.date_of_birth.visible ? getAge(m.date_of_birth.value) : "") + " • " + (m.orientation.visible ? m.orientation.value : "") + " " + (m.gender.visible ? m.gender.value : "") + " "}<br /></Row>
                        })}
                    </Row>
                </div>
                <br/>

                {photoModerationAction ?
                    <Row key={uuid()} className="photo-mod-select-div" gutter={3}>
                        <Col key={uuid()} span={17}>
                            <Cascader key={uuid()} options={options} onChange={handleChange} value={defaultReason}
                                      placeholder="Mod Reason" className="mod-select"/>
                        </Col>
                        <Col key={uuid()} span={7}>
                            <Button key={uuid()} icon={<DeleteTwoTone twoToneColor="red" />}
                                    onClick={() => proceedToDelete()} />
                        </Col>
                    </Row>
                    : null
                }
                <Row className="photo-mod-quick-button-div">
                    <div>
                        <Button key={uuid()} onClick={() => quickDelete("meme", 38)}>🚷</Button>
                    </div>
                    <div>
                    <Button key={uuid()} onClick={() => quickDelete("nudity/sex", 37)}>🍆</Button>
                    </div>
                        <div>
                        <Button key={uuid()} onClick={() => quickDelete("minors", 36)}>🍼</Button>
                        </div>
                </Row>
            </Col>
            <Modal width={500} style={{top: 10, height: "90vh"}} title="View Photo" visible={isModalVisible} onOk={handleOk} onCancel={handleOk}
                   footer={[<Button key="submit" type="primary" onClick={handleOk}>
                       Close
                   </Button>]}>
                <Row>
                    <Col flex={"none"} style={{height: "80vh", overflow: "hidden"}}>
                        <img src={usedPhoto?.card_url ?? blankImg} height="100%"/>
                    </Col>
                    <div className={"reverse-image-matches"} style={{position: "absolute", right: "-320px", height: "80vh", overflow: "hidden"}}>
                        {getReverseResults(usedPhoto)}
                    </div>
                </Row>
            </Modal>
        </>
    );
}

const actionCreators = {
    deletePhoto: profileActions.deleteProfilePhotoAction
};

function mapStateToProps(state) {
    const {photoDeleted, photoDeletedError, modalPhoto} = state.profileReducer
    return {photoDeleted, photoDeletedError, modalPhoto};
}

export default connect(mapStateToProps, actionCreators)(ProfilePhotos);

