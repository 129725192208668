import React, {useEffect} from 'react';
import {connect} from "react-redux";
import uuid from 'react-uuid';
import {Col, Row, Image, Tag, Badge, Tooltip} from "antd";
import likeIcon from "../../assets/logo.svg";
import {profileActions, mlLabelActions} from "../../appRedux/Actions";

function TablePhotos(props) {
    const photo_flag_emoji_map = {
        "baby": "🍼",
        "pre_teen": "🍼",
        "teenager": "🍼",
        "toddler": "🍼",
        "general_nsfw": "🍑",
        "yes_sex_toy": "🕹️",
        "yes_female_nudity": "🍆",
        "yes_male_nudity": "🍆",
        "general_suggestive": "🍑",
        "gun_in_hand": "🔫",
        "gun_not_in_hand": "🔫",
        "knife_in_hand": "🔪️",
        "knife_not_in_hand": "🔪️",
        "a_little_bloody": "🩸",
        "other_blood": "🩸",
        "very_bloody": "🩸",
        "yes_nazi": "☠",
        "yes_kkk": "☠",
        "yes_terrorist": "☠",
        "yes_pills": "💊",
        "illicit_injectables": "💊",
        "medical_injectables": "💊",
        "no_demographics": "🚷",
        "reverse_match": "🖼️",
        "text": "🔤",
        "yes_spam": "📔",
        "violence": "📔",
        "yes_bullying": "📔",
        "self_harm": "📔",
        "like_pass_ratio": "🚨",
        "like_threshold": "🚨",
    };

    const blur_emoji = "🚫"

    function getFlagIcon(flag) {
        return photo_flag_emoji_map[flag] ?? flag
    }

    // useEffect(() => {
    //     props.setPhoto()
    // })

    const {setBigPhoto, setPhoto} = props;
    const {photos, members, reports, key} = props.record
    const disLike = <Image width={20} src={likeIcon} alt={"likeIcon"} className="table-icon" preview={false}/>

    const reportLength = reports?.filter(report => report.reviewed === false)?.length ?? 0
    const hasReport = reportLength > 0;

    const photosInit = photos?.map(photo => {
        const unreviewedBorder = (!photo.reviewed) ? "img-responsive table-img unreviewed-border table-img-size img-button" : "img-responsive table-img table-img-size img-button"
        const count = photo.ml_flagged ? photo.ml_flagged.length : 0;
        const flags = photo.ml_flagged ? photo.ml_flagged.map((flag) => flag) : "";

        if (count === 0) {
            return (
                <div className={"photo-row-div"} key={uuid()}>
                    <img src={photo.card_url} className={unreviewedBorder} key={uuid()} onClick={() =>  {props.changeSelectedProfilePhoto(photo); props.handleRowExpansion(true, props.record, [], photo);}} />
                </div>
            )
        }

        return (
            <Tooltip key={uuid()} title={flags.join(", ")} color="red">
                <div className={"photo-row-div"}>
                <div className={"photo-mod-tags-div photo-mod-tags-row"}>
                    {photo.pending_blur ?
                        <Tag key={uuid()} color="red">
                            {blur_emoji}
                        </Tag> : ""}
                    {photo.ml_flagged?.length > 0 ? photo.ml_flagged.map((flag) => (
                        <Tag key={uuid()}>
                            {getFlagIcon(flag) ?? flag}
                        </Tag>)): ""}
                </div>
                    <img src={photo.card_url} className={unreviewedBorder} key={uuid()} onClick={() =>  {props.changeSelectedProfilePhoto(photo); props.handleRowExpansion(true, props.record, [], photo);}} />
                </div>
            </Tooltip>
        )
    })

    return (
               <div key={uuid()} className="flex-row photo-row" style={{"whiteSpace": "nowrap"}}>
                   {photosInit}
               </div>
    );
}

const actionCreators = {
    changeSelectedProfilePhoto: profileActions.changeSelectedProfilePhoto
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, actionCreators)(TablePhotos);
