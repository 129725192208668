import React, {useState, useEffect} from "react";
import {Empty, Image, Skeleton, Tooltip, Button} from "antd";
import Table from 'react-bootstrap/Table';
import openLogo from "../../assets/logo.svg";
import likeIcon from "../../assets/like.png";
import dislikeIcon from "../../assets/dislike.png";
import matchedIcon from "../../assets/matched.png";
import likedByIcon from "../../assets/liked_by.png";
import passedByIcon from "../../assets/passed_by.png";
import pairedIcon from "../../assets/paired.png";
import {TablePhotos, TableRender, TableFlags} from "../index";
import moment, {now} from "moment";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {chatActions, moderationActions, profileActions, streamRoomMessages} from "../../appRedux/Actions";
import {PlusCircleTwoTone, MinusCircleTwoTone} from "@ant-design/icons";
import uuid from "react-uuid";
import Collapse from 'react-bootstrap/Collapse';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function NewTableRenderer(props) {
    const {profiles, fetched, profileSelectedAction, enableRowSelection} = props

    const [dataSource, setDataSource] = useState(profiles);

    useEffect(() => {
        setDataSource(profiles);
    }, [profiles]);

    const [expanded, setExpanded] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const like_img = <Image src={likeIcon} alt={"likeIcon"} className="table-icon" preview={false}/>
    const dislike_img = <Image src={dislikeIcon} alt={"dislikeIcon"} className="table-icon" preview={false}/>
    const matched_img = <Image src={matchedIcon} alt={"matchedIcon"} className="table-icon" preview={false}/>
    const liked_by_img = <Image src={likedByIcon} alt={"liked_byIcon"} className="table-icon" preview={false}/>
    const passed_by_img = <Image src={passedByIcon} alt={"passed_byIcon"} className="table-icon" preview={false}/>


    const paired_img = <Image src={pairedIcon} alt={"pairedIcon"} className="table-icon" preview={false}/>

    const updatedRooms = useSelector(state => state.chatReducer.updatedRooms)
    const recentlyCreatedChatRoom = useSelector(state => state.chatReducer.recentlyCreatedChatRoom)

    function formatDate(date) {
        const daysAgo = moment().diff(moment.utc(date).local(), 'days')
        if (daysAgo === 0) {
            return (<center>Today<br/>{moment.utc(date).local().format('h:mm a')}</center>)
        } else if (daysAgo === 1) {
            return (<center>Yesterday<br/>{moment.utc(date).local().format('h:mm a')}</center>)
        } else if (daysAgo > 7 && daysAgo <= 14) {
            return (
                <center>{moment.utc(date).local().format('dddd')}<br/><small>{moment.utc(date).local().format('MMM Do, y')}</small><br/>{moment.utc(date).local().format('h:mm a')}
                </center>)
        } else if (daysAgo > 14 && daysAgo < 365) {
            return (
                <center>{moment.utc(date).local().format('dddd')}<br/><small>{moment.utc(date).local().format('MMM Do, y')}</small><br/>{moment.utc(date).local().format('h:mm a')}
                </center>)
        } else {
            return (
                <center>{moment.utc(date).local().format('dddd')}<br/><small>{moment.utc(date).local().format('MMM Do, y')}</small><br/>{moment.utc(date).local().format('h:mm a')}
                </center>)
        }
    }

    const polyIcon = <Image width={14} src={openLogo} alt={"likeIcon"} className="table-icon" preview={false}/>

    const columns = [
        {
            title: 'Flags',
            key: 's-p'
        },
        {

            title: 'Photos',
            key: 'photos'
        },
        {
            title: "Last Seen",
            key: "last_seen_ts"
        },
        {
            title: 'Created',
            key: 'created_ts'
        },
        {
            title: 'Matches',
            dataIndex: 'match_count'
        },
        {
            title: 'Likes/Passes',
            key: 'liked_profiles'
        },
        {
            title: 'Liked/Passed By',
            key: 'liked_by_profiles'
        },
        {
            title: 'Sparked/Sparked By',
            key: 'sparked_by_profiles'
        }
    ];

    const handleRowExpansion = (expanded, record) => {
        const expandedRows = [];
        if (expanded) {
            expandedRows.push(record.id);
            setRoomToListenTo(record)
        }
        setExpanded(expandedRows);
        setDataSource(profiles);
    };

    const dispatcher = useDispatch();

    function setRoomToListenTo(profile) {
        const roomName = `_support_${profile.id}_`
        const roomName1 = `-support-${profile.id}-`
        const myRoom = updatedRooms.filter(item => (item.name === roomName || item.name === roomName1));
        if (!_.isEmpty(myRoom)) {
            const roomId = myRoom[0]?._id ?? 0
            streamRoomMessages(roomId)
            dispatcher(chatActions.getRoomMessages(roomId))
        }
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
        if (!_.isEmpty(selectedRowKeys)) {
            const profileId = selectedRowKeys[0]
            profileSelectedAction(profileId)
        }
    };

    const rowSelection = enableRowSelection ? {
        selectedRowKeys,
        onChange: onSelectChange,
    } : undefined


    const [modalProf, setModalProf] = useState({});
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const handleExpand = (prof) => {
        setModalProf(prof);
        setShowModal(true);
    }

    const handleReview = (id) => {
        setShowModal(false);
        profileSelectedAction(id);
    }


    return <>
        <Modal onHide={() => handleClose()} fullscreen={true} centered show={showModal}>
            <Modal.Header closeButton>
                <Container fluid>
                    <Row>
                        <Col>
                            <TableFlags record={modalProf}/>
                        </Col>
                        <Col xs={6}>
                            <TablePhotos record={modalProf}/>
                        </Col>
                        <Col>
                            {formatDate(modalProf.last_seen_ts ?? modalProf.modified_ts)}
                        </Col>
                        <Col>
                            {formatDate(modalProf.created_ts)}
                        </Col>
                        <Col>
                            <div className="table-cell-image">
                                <b>{modalProf.match_count ?? 0}</b>&nbsp; {matched_img}
                            </div>
                        </Col>
                        <Col>
                            <div className="table-cell-image">
                                <b>{modalProf.like_count ?? 0}</b>&nbsp;
                                {like_img}
                                <br/>
                                <b>{modalProf.pass_count ?? 0}</b>&nbsp;
                                {dislike_img}
                            </div>
                        </Col>
                        <Col>
                            <div className="table-cell-image">
                                <b>{modalProf.liked_by_count ?? 0}</b>&nbsp;
                                {liked_by_img}
                                <br/>
                                <b>{modalProf.passed_by_count ?? 0}</b>&nbsp;
                                {passed_by_img}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <TableRender profile={modalProf}/>
            <Modal.Footer>
                <Button onClick={() => handleClose()}>Close Profile</Button>
            </Modal.Footer>
        </Modal>
        <Table>
            <thead>
            <tr>
                <th></th>
                {columns.map((col) => {
                    return <th key={col.key}>{col.title}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {profiles.map((prof) => {
                return (
                    <>
                        <tr className="member-row" onClick={() => {
                            handleExpand(prof)
                        }}>
                            <td>
                                <Form><Form.Check key={uuid()} onChange={() => {
                                    handleReview(prof.id)
                                }} type="checkbox" id={prof.id} name={prof.id}/></Form>
                            </td>
                            <td style={{"alignItems": "center"}}><TableFlags record={prof}/></td>
                            <td><TablePhotos record={prof}/></td>
                            <td>{formatDate(prof.last_seen_ts ?? prof.modified_ts)}</td>
                            <td>{formatDate(prof.created_ts)}</td>
                            <td>
                                <div className="table-cell-image">
                                    <b>{prof.match_count ?? 0}</b>&nbsp; {matched_img}
                                </div>
                            </td>
                            <td>
                                <div className="table-cell-image">
                                    <b>{prof.like_count ?? 0}</b>&nbsp;
                                    {like_img}
                                    <br/>
                                    <b>{prof.pass_count ?? 0}</b>&nbsp;
                                    {dislike_img}
                                </div>
                            </td>
                            <td>
                                <div className="table-cell-image">
                                    <b>{prof.liked_by_count ?? 0}</b>&nbsp;
                                    {like_img}
                                    <br/>
                                    <b>{prof.passed_by_count ?? 0}</b>&nbsp;
                                    {dislike_img}
                                </div>
                            </td>
                            <td>
                                <div className="table-cell-image">
                                    <b>{prof.sparked_profiles?.length ?? 0}</b>&nbsp;
                                    ✨
                                    <br/>
                                    <b>{prof.sparked_by_profiles?.length ?? 0}</b>&nbsp;
                                    ✨
                                </div>
                            </td>
                        </tr>
                    </>
                )
            })}
            </tbody>
        </Table>
    </>
}

export default NewTableRenderer