import {macroService} from "../Services";
import {macroConstants} from "../Constants";

export const macroActions = {
    createNewMacro,
    getAllMacros,
    editMacro,
    deleteMacro
}

function getAllMacros() {
    return dispatch => {
        dispatch(request());
        macroService.getAllMacros()
            .then(macros => {
                dispatch(success(macros));
            },
            error => {
                dispatch(failure(error));
            });
         };
    function request() { return { type: macroConstants.GET_MACROS_REQUEST } }
    function success(macros) { return { type: macroConstants.GET_MACROS_SUCCESS, macros } }
    function failure(response) { return { type: macroConstants.GET_MACROS_FAILURE, response } }
}

function createNewMacro(params) {
    return dispatch => {
        dispatch(request());
        macroService.createMacro(params)
            .then(macro => {
                dispatch(success(macro));
            },
            error => {
                dispatch(failure(error));
            });
         };
    function request() { return { type: macroConstants.CREATE_MACRO_REQUEST } }
    function success(macro) { return { type: macroConstants.CREATE_MACRO_SUCCESS, macro } }
    function failure(response) { return { type: macroConstants.CREATE_MACRO_FAILURE, response } }
}

function editMacro(macroIndex, macro) {
    return dispatch => {
        macroService.editMacro(macroIndex, macro)
        .then(macro => {
            dispatch(success(macroIndex, macro));
        },
        error => {
            dispatch(failure(error));
        });
    };
    function success(macroIndex, macro) { return { type: macroConstants.EDIT_MACRO_SUCCESS, macroIndex, macro }}
    function failure(response) { return { type: macroConstants.EDIT_MACRO_FAILURE }}
}

function deleteMacro(macroIndex) {
    return dispatch => {
        dispatch(request());
        macroService.deleteMacro(macroIndex)
            .then(macro => {
                dispatch(success(macroIndex, macro));
            },
            error => {
                dispatch(failure(error));
            });
         };
    function request() { return { type: macroConstants.DELETE_MACRO_REQUEST } }
    function success(macroIndex, macro) { return { type: macroConstants.DELETE_MACRO_SUCCESS, macroIndex, macro } }
    function failure(response) { return { type: macroConstants.DELETE_MACRO_FAILURE, response } }
}