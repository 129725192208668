import { mlLabelConstants} from "../Constants";
import { mlLabelService } from "../Services";
import { alertActions } from "../Actions";
import _ from "lodash";


export const mlLabelActions = {
    addLabels,
    getLabels
};


function getLabels() {
    return dispatch => {
        dispatch(request());
        mlLabelService.fetchLabels()
            .then( labels => {
                    if(!_.isEmpty(labels)) {
                        dispatch(success(labels))
                    } else {
                        dispatch(success([]));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: mlLabelConstants.GET_LABELS_REQUEST } }
    function success(labels) { return { type: mlLabelConstants.GET_LABELS_SUCCESS, labels} }
    function failure(error) { return { type: mlLabelConstants.GET_LABELS_FAILURE, error } }
}

function addLabels(key, friendlyKey) {
    return dispatch => {
        dispatch(request());
        mlLabelService.addLabel({key: key, friendlyKey: friendlyKey})
            .then( label => {
                    if(!_.isEmpty(label)) {
                        dispatch(success(label));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: mlLabelConstants.ADD_LABEL_REQUEST } }
    function success(label) { return { type: mlLabelConstants.ADD_LABEL_SUCCESS, label} }
    function failure(error) { return { type: mlLabelConstants.ADD_LABEL_FAILURE, error } }
}