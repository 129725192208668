import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Avatar, Col, Image, Row, Tag, Tooltip, Form, Select, Option, Button} from "antd";
import androidIcon from '../../assets/android.png';
import appleIcon from "../../assets/apple.png";
import sparksIcon from "../../assets/sparks.png";
import interactionIcon from "../../assets/interaction.png";
import noteRedIcon from "../../assets/note-red.png";
import noteGreenIcon from "../../assets/note-green.png";
import blockedIcon from '../../assets/blocked.png';
import cameraIcon from '../../assets/camera.png';
import chaIcon from '../../assets/chat-icon.webp';
import unknown from '../../assets/device_unknown.svg';
import {profileActions} from "../../appRedux/Actions";
import emptyImage from "../../assets/img.png";
import bugActive from "../../assets/bug_active.png";
import bugInactive from "../../assets/bug_inactive.png";
import subscribedIcon from "../../assets/subscribed_active.png";
import unsubscribedIcon from "../../assets/subscribed_inactive.png";
import likedByIcon from "../../assets/liked_by.png";

import {profileReducer} from "../../appRedux/Reducers/profile.reducer";
import moment from "moment";
import {SettingOutlined, RedoOutlined} from "@ant-design/icons";

function ProfileMembers(props) {

    const [issueType, setIssueType] = useState('');
    const [form] = Form.useForm();

    const {profile, expandedProfile} = props;
    const accountID = expandedProfile.members?.length > 1 ? expandedProfile.members[1]?.account?.id : expandedProfile.members[0]?.account?.id;
    const {Option} = Select;

    const deviceUnknown = <Image src={unknown} alt={"unknown"} className="table-icon" preview={false}/>
    const blocked = <Image src={blockedIcon} alt={"blockedIcon"} className="table-icon" preview={false}/>
    const apple = <Image src={appleIcon} alt={"appleIcon"} className="table-icon" preview={false}/>
    const android = <Image src={androidIcon} alt={"androidIcon"} className="table-icon" preview={false}/>
    const noteGreen = <Tooltip placement="top" title="Account has agreed to the rules"><Image src={noteGreenIcon}
                                                                                              alt={"noteGreenIcon"}
                                                                                              className="table-icon"
                                                                                              preview={false}/></Tooltip>
    const noteRed = <Tooltip placement="top" title="Account has not agreed to the rules"><Image src={noteRedIcon}
                                                                                                alt={"noteRedIcon"}
                                                                                                className="table-icon"
                                                                                                preview={false}/></Tooltip>
    const camera = <Image src={cameraIcon} alt={"cameraIcon"} className="table-icon" preview={false}/>
    const chat = <Image src={chaIcon} alt={"chaIcon"} className="table-icon" preview={false}/>
    const sparks = <Image src={sparksIcon} alt={"sparksIcon"} className="table-icon" preview={false}/>
    const interactions = <Image src={interactionIcon} alt={"interactionIcon"} className="table-icon" preview={false}/>

    const bug_active = <Tooltip title={"Bug tracking active"}><Image src={bugActive} alt={"bugActive"}
                                                                     className="table-icon img-button"
                                                                     onClick={() => props.toggleBugsy(member1.account?.id)}
                                                                     preview={false}/></Tooltip>
    const bug_inactive = <Tooltip title={"Bug tracking inactive"}><Image src={bugInactive} alt={"bugInactive"}
                                                                         className="table-icon img-button"
                                                                         onClick={() => props.toggleBugsy(member1.account?.id)}
                                                                         preview={false}/></Tooltip>

    const subscription_active = <Image src={subscribedIcon} alt={"subscribedIcon"} className="table-icon"
                                       preview={false}/>
    const unsubscribed_active = <Image src={unsubscribedIcon} alt={"unsubscribedIcon"} className="table-icon"
                                       preview={false}/>


    function getSparksTooltip(member) {
        return member.account?.sparks_comped + " comped sparks, " + member.account?.sparks_subscription + " subscription sparks, " + member.account?.sparks_purchased + " purchased sparks"
    }

    function getPlatformIcon(member) {
        if (member?.account?.operating_system === "iOS") {
            return apple
        } else if (member.account?.operating_system === "Android") {
            return android
        } else {
            return deviceUnknown
        }
    }

    function getPlatformInfo(member) {
        if (member?.account?.operating_system === "iOS") {
            return member.account?.operating_system + " " + member.account?.operating_system_version + " on " + member.account?.device_model
        } else if (member.account?.operating_system === "Android") {
            return member.account?.operating_system + " " + member.account?.operating_system_version + " on " + member.account?.device_model
        } else {
            return ""
        }
    }

    function getAppVersion(member) {
        if (member.account?.app_version) {
            return "Build " + member.account?.app_version
        } else {
            return "N/A"
        }
    }

    function getNote(member) {
        if (member.account?.rules_agreed_ts != null) {
            return noteGreen
        } else {
            return noteRed
        }
    }

    function getSubStatus(member) {
        if (member.account?.subscribed) {
            return subscription_active
        } else {
            return unsubscribed_active
        }
    }


    function getSubStatusTooltip(member) {
        let tooltip = []

        if (member.account?.subscribed) {
            tooltip.push("Account is subscribed. ")
        } else {
            tooltip.push("Account is not subscribed. ")
        }
        if (member.account?.google_play_subscription_expiration) {
            tooltip.push("Google Play expiration: " + moment.utc(member.account.google_play_subscription_expiration).local().format('MMM Do, y') + ". ")
        }
        if (member.account?.ios_subscription_expiration) {
            tooltip.push("App Store expiration: " + moment.utc(member.account.ios_subscription_expiration).local().format('MMM Do, y') + ". ")
        }
        return tooltip.join(" ")
    }

    function getUnit(member) {
        if (member.account?.use_kilometers === true) {
            return "Km"
        } else {
            return "Mi"
        }
    }

    function getDebugIcon(member) {
        if (member.account?.debug_logging_enabled === true) {
            return <Tooltip title={"Bug tracking active"}><Image src={bugActive} alt={"bugActive"}
                                                                 className="table-icon img-button"
                                                                 onClick={() => props.toggleBugsy(member.account?.id)}
                                                                 preview={false}/></Tooltip>
        } else
            return <Tooltip title={"Bug tracking inactive"}><Image src={bugInactive} alt={"bugInactive"}
                                                                   className="table-icon img-button"
                                                                   onClick={() => props.toggleBugsy(member.account?.id)}
                                                                   preview={false}/></Tooltip>
    }

    function handleMarkIssue(value) {
        setIssueType(value);
    }

    function submitMarkIssue() {
        props.createNote(profile, accountID, 'marked for follow-up', [issueType]);
        props.getNotes(profile);
        form.resetFields();
    }

    function getAccStatus(member) {
        if (member.account?.banned) {
            return <Tag color='red'>Banned</Tag>
        } else if (member.account?.suspended) {
            return <Tag color='orange'>Suspended</Tag>
        } else if (member.account?.suspended === false && member.account?.banned === false) {
            return <Tooltip placement="top" title="Account is not banned or suspended">
                <Tag color='green'>Active </Tag>
            </Tooltip>
        } else {
            return "N/A"
        }
    }

    function getLikedBy(member) {
        if (member.account) {
            return <Tooltip placement="top" title="Liked by count within member's filters">
                <div>
                    <span className={"member-liked-count"}>{member.liked_by_count}</span>
                    <Image src={likedByIcon} alt={"liked_byIcon"} className="table-icon" preview={false}/>
                </div>
            </Tooltip>
        } else {
            return <Tooltip placement="top" title="Liked by count within member's filters">
                <div>
                    <span className={"member-liked-count"}>N/A</span>
                    <Image src={likedByIcon} alt={"liked_byIcon"} className="table-icon" preview={false}/>
                </div>
            </Tooltip>
        }
    }

    function formatPhoneForSearch(number) {
        let format_number = number.replace('+1', '')
        format_number = format_number.replace('+', '')
        return format_number
    }

    function formatPhoneNumberForDisplay(number) {
        let match = number.match(/\+(1|)?(\d{3})(\d{3})(\d{4})/);
        if (match) {
            let intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', 'xxx', ') ', 'xxx', '-', match[4]].join('');
        } else {
            let format_number = number.replace('+1', '')
            format_number = format_number.replace('+', '')
            return format_number
        }
    }

    function getPhoneNumber(member) {
        if (member.account) {
            return <span><a href={"https://www.google.com/search?q=" + formatPhoneForSearch(member.account?.phone_number ?? "")}
                      target="_blank">{member.account?.phone_number ? formatPhoneNumberForDisplay(member.account?.phone_number): "N/A"}</a></span>
        }
        else {
            return <span>{member.account?.phone_number ?? "N/A"}</span>
        }
    }

    const member1 = expandedProfile.members[0] ?? [];
    const member2 = expandedProfile.members[1] ?? [];

    const [memberNum, setMemberNum] = useState(profile.members?.length);

    return (
        expandedProfile.members?.length === 1 ?
            <Row className="member-row" style={{padding: 0}}>
                <Col span={1}>
                    <Avatar src={expandedProfile.photos[0]?.avatar_url ?? emptyImage}/>
                </Col>
                <Col className="member-row-col" span={3}>
                    {getPhoneNumber(member1)}
                </Col>
                <Col className="member-row-col" span={4}>
                    {getDebugIcon(member1)}
                    <Tooltip placement="top" title={getPlatformInfo(member1)}>
                        {getPlatformIcon(member1)}
                    </Tooltip>
                    <Tooltip placement="top" title="App Build">
                        {getAppVersion(member1)}
                    </Tooltip>
                </Col>
                <Col className="member-row-col" span={5}>
                    {getAccStatus(member1)}

                    {getNote(member1)}

                    <Tooltip placement="top" title={getSubStatusTooltip(member1)}>
                        {getSubStatus(member1)}
                    </Tooltip>
                    <Tooltip placement="top" title={getSparksTooltip(member1)}>
                        <b>{member1.account?.sparks ?? 0}{sparks}</b>
                    </Tooltip>
                    <Tooltip placement="top" title="Interactions available">
                        <b>{member1.account?.interactions_available ?? 0}{interactions}</b>
                    </Tooltip>
                    <Tooltip placement="top" title="Reset interactions">
                        <Button shape="round" size="small" type="primary" icon={<RedoOutlined/>} disabled={false} onClick={() => props.resetInteractions(member1.account?.id)}></Button>
                    </Tooltip>
                </Col>
                <Col className="member-row-col" span={2}>
                    {getLikedBy(member1)}
                </Col>
                <Col span={9}>
                    <Form form={form} style={{float: "right"}}>
                        <Select onChange={handleMarkIssue} placeholder="Select..." style={{width: 120}}>
                            <Option value="tech">Tech</Option>
                            <Option value="moderation">Moderation</Option>
                            <Option value="billing">Billing</Option>
                            <Option value="android">Android</Option>
                        </Select>
                        <> </>
                        <Button onClick={submitMarkIssue} loading={props.creatingNoteInProgress}>Mark Issue For
                            Follow-Up</Button>
                    </Form>
                </Col>
            </Row> :
            <Row className="members-row" style={{padding: 0}}>
                <Col span={1}>
                    <Avatar src={expandedProfile.photos[0]?.avatar_url ?? emptyImage}/>
                </Col>
                <Col className="member-row-col" span={3}>
                    <Row>
                        <Col flex="none">
                            <Row>
                                {getPhoneNumber(member1)}
                            </Row>
                            <Row>
                                {getPhoneNumber(member2)}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="member-row-col" span={4}>
                    <Row>
                        <Col flex="none">
                            <Row>{getDebugIcon(member1)}</Row>
                            <Row>{getDebugIcon(member2)}</Row>
                        </Col>
                        <Col flex="none">
                            <Row><Tooltip placement="top" title={getPlatformInfo(member1)}>
                                {getPlatformIcon(member1)}
                            </Tooltip></Row>
                            <Row><Tooltip placement="top" title={getPlatformInfo(member1)}>
                                {getPlatformIcon(member2)}
                            </Tooltip></Row>
                        </Col>
                        <Col flex="none">
                            <Row><Tooltip placement="top" title="App Build">
                                {getAppVersion(member1)}
                            </Tooltip></Row>
                            <Row><Tooltip placement="top" title="App Build">
                                {getAppVersion(member2)}
                            </Tooltip></Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="member-row-col" span={5}>
                    <Row>
                        <Col>
                            <Row>
                                {getAccStatus(member1)}
                            </Row>
                            <Row>
                                {getAccStatus(member2)}
                            </Row>
                        </Col>
                        <Col>
                            <Row>{getNote(member1)}</Row>
                            <Row>{getNote(member2)}</Row>
                        </Col>
                        <Col>
                            <Row>
                                <Tooltip placement="top" title={getSubStatusTooltip(member1)}>
                                    {getSubStatus(member1)}
                                </Tooltip>
                            </Row>
                            <Row>
                                <Tooltip placement="top" title={getSubStatusTooltip(member2)}>
                                    {getSubStatus(member2)}
                                </Tooltip>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Tooltip placement="top" title={getSparksTooltip(member1)}>
                                    <b>{member1.account?.sparks ?? 0}{sparks}</b>
                                </Tooltip>
                            </Row>
                            <Row>
                                <Tooltip placement="top" title={getSparksTooltip(member2)}>
                                    <b>{member2.account?.sparks ?? 0}{sparks}</b>
                                </Tooltip>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Tooltip placement="top" title="Interactions available">
                                    <b>{member1.account?.interactions_available ?? 0}{interactions}</b>
                                </Tooltip>
                                <Tooltip placement="top" title="Reset interactions">
                                    <Button shape="round" size="small" type="primary" icon={<RedoOutlined/>} disabled={false} onClick={() => props.resetInteractions(member1.account?.id)}></Button>
                                </Tooltip>
                            </Row>
                            <Row>
                                <Tooltip placement="top" title="Interactions available">
                                    <b>{member2.account?.interactions_available ?? 0}{interactions}</b>
                                </Tooltip>
                                <Tooltip placement="top" title="Reset interactions">
                                    <Button shape="round" size="small" type="primary" icon={<RedoOutlined/>} disabled={false} onClick={() => props.resetInteractions(member2.account?.id)}></Button>
                                </Tooltip>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="member-row-col" span={2}>
                    <Row>
                        {getLikedBy(member1)}
                    </Row>
                    <Row>
                        {getLikedBy(member2)}
                    </Row>
                </Col>
                <Col span={9}>
                    <Form form={form} style={{float: "right"}}>
                        <Select onChange={handleMarkIssue} placeholder="Select..." style={{width: 120}}>
                            <Option value="tech">Tech</Option>
                            <Option value="moderation">Moderation</Option>
                            <Option value="billing">Billing</Option>
                            <Option value="android">Android</Option>
                        </Select>
                        <> </>
                        <Button onClick={submitMarkIssue} loading={props.creatingNoteInProgress}>Mark Issue For
                            Follow-Up</Button>
                    </Form>
                </Col>
            </Row>

    )
}

const actionCreators = {
    createNote: profileActions.createNoteAction,
    getNotes: profileActions.getNotesFromProfileAction,
    toggleBugsy: profileActions.toggleBugsy,
    resetInteractions: profileActions.resetInteractions
}

function mapStateToProps(state) {
    const {creatingNoteInProgress} = state.profileReducer
    return {creatingNoteInProgress}
}

export default connect(mapStateToProps, actionCreators)(ProfileMembers);
