import {Button, message, Table, Tooltip, Divider, Select, Input} from "antd";
import uuid from "react-uuid";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import _, {capitalize} from "lodash";
import {connect, useDispatch, useSelector} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import {Link} from "@gatsbyjs/reach-router";
import {InfoCircleOutlined, CheckCircleOutlined, SendOutlined} from "@ant-design/icons";
import {storageService} from "../../appRedux/Storage";
import {storageConstants} from "../../appRedux/Constants/storage.constants";

// Needs an onclick to send da macro

function Reports(props) {
    const {profile} = props
    const [expanded, setExpanded] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [reports, setReports] = useState([]);
    const searchingProfile = useSelector(state => state.profileReducer.searchingProfile)
    const storedModerationTags = storageService.getFromStore(storageConstants.MODERATION_TAGS) ?? []

    const [selectedMacro, setSelectedMacro] = useState("Select Macro");
    const [selectedMacroId, setSelectedMacroId] = useState();

    const [sendMacroDisabled, setSendMacroDisabled] = useState(false);

    const errorRequireMacro = 'Please select a macro'
    const macroSentMessage = 'Macro sent!'


    const dispatcher = useDispatch()

    function searchWithAccountId (accountId) {
        console.log(accountId)
        let id = [...accountId]
        dispatcher(profileActions.searchProfileWithAccountIdAction(id))
    }

   function getMacroOptions() {
        const opts = props.reportMacros.map((selectOption) => (
            {"value": selectOption.content, "label": selectOption.title, "id": selectOption.id}))
        return opts
   }

    const columns = [
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Reporter',
            dataIndex: 'reporting_account_id',
            key: 'reporting_account_id',
            render: reporting_account_id =>{

                return <span><Tooltip title={"Search for account"}><Button className="table-link-button" loading={searchingProfile} type="link" onClick={() => searchWithAccountId([reporting_account_id])}>
                    {reporting_account_id}
                </Button>
                </Tooltip>
                </span>
            },
        },
        {
            title: 'Created',
            dataIndex: 'created_ts',
            key: 'created_ts',
            render: created_ts => <> {
                moment.utc(created_ts).local().fromNow()
            } </>,

        },
        {
            title: 'Reviewed',
            dataIndex: 'reviewed',
            key: 'reviewed',
        },
    ];

    useEffect(() => {
        setReports(profile.reports)
        setExpanded(profile.reports.map(report => report.id));
    }, [])

    const handleReportRowExpansion = (expanded, record) => {
        const expandedRows = [];
        if (expanded) {
            expandedRows.push(record.id);
        }
        setExpanded(expandedRows);
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
        if(!_.isEmpty(selectedRowKeys)) {
            const reportID = selectedRowKeys[0]
            setSelectedRowKeys([])
            reports.forEach(reportObject => {
                if(reportObject.id === reportID) {
                    reportObject.reviewed = true
                }
            })
            dispatcher(profileActions.markReportAsReViewedAction(profile, reportID))
            setReports(reports)
            message.success("Report Reviewed").then(r => "")
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.reviewed === true, // Column configuration not to be checked
            name: record.name
        }),
    }

    const handleMacroSelectChange = (value, opt) => {
        setSelectedMacro(value)
        setSelectedMacroId(opt.id)
    }

    function sendMacro(record) {
        if (selectedMacroId == null) {
            message.error(errorRequireMacro).then(r => "")
        } else {
            props.reportMoreInfo(record.id, selectedMacroId)
        }
        setSendMacroDisabled(true)
        setTimeout(() => setSendMacroDisabled(false), 2500);
    }

    const sendReportActedMacro = (record) => {
        props.reportMoreInfo(record.id, 52)
        setSendMacroDisabled(true)
        setTimeout(() => setSendMacroDisabled(false), 2500);
}

    const sendMoreInfoMacro = (record) => {
        props.reportMoreInfo(record.id, 51)
        setSendMacroDisabled(true)
        setTimeout(() => setSendMacroDisabled(false), 2500);
    }

    return <div style={{height: "508px", overflow: "auto", padding:"10px", backgroundColor:"#fff"}} className="report-table">
        <Table
            dataSource={reports}
            rowKey={record => record.id}
            columns={columns}
            expandIconColumnIndex={-1}
            expandedRowKeys={expanded}
            rowSelection={rowSelection}
            pagination={{position: ['bottomRight']}}
            expandable={{
                expandedRowRender: record => <span>
                    {record.additional_information ?
                    <div style={{margin: 0, padding: "4px 8px 8px 8px"}}>
                        {record.additional_information}
                    </div>: null}
                    <div style={{ margin: 0}}>
                    <span>
                        <Input.Group size="small" className="report-macro-button-group">
                            <span style={{width: "30%"}}>
                            <Tooltip title={"Send request for info"}>
                        <Button className="table-button" type="primary" size="small" icon={<InfoCircleOutlined />} onClick={() => sendMoreInfoMacro(record)} loading={sendMacroDisabled} disabled={sendMacroDisabled}>
                        </Button></Tooltip>
                            <Tooltip title={"Confirm report was acted on"}>
                        <Button className="table-button" type="primary" size="small" icon={<CheckCircleOutlined />} onClick={() => sendReportActedMacro(record)} loading={sendMacroDisabled} disabled={sendMacroDisabled}>
                        </Button></Tooltip>
                            </span>
                            <span style={{float: "right", width: "70%"}}>
                                <Tooltip title={"Send"}>
                        <Button className="table-button" type="primary" size="small" icon={<SendOutlined />} onClick={() => sendMacro(record)} style={{marginLeft: "0", float: "right"}}  loading={sendMacroDisabled} disabled={sendMacroDisabled}>
                        </Button></Tooltip>
                            <Select key={uuid()} options={getMacroOptions()} onChange={handleMacroSelectChange}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return ((option?.label ?? '').includes(input) || (option?.value ?? '').includes(input))
                                    }
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    value={selectedMacro} size={"small"} style={{width: "70%", float: "right"}}
                                    placeholder="Select Macro" className="mod-select"/>

</span>
                        </Input.Group>
                </span>
                </div>
                </span>

                ,
                rowExpandable: record =>  true,
            }}
            onExpand={handleReportRowExpansion}
        />
    </div>
}

const actionCreators = {
    reportMoreInfo: profileActions.reportMoreInfo
}

function mapStateToProps(state) {
    const {helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros} = state.macroReducer;
    return {helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros};
}

export default connect(mapStateToProps, actionCreators)(Reports);