import React, {useState, useEffect} from "react";
import {Col, Empty, Image, Modal, Skeleton, Table, Tooltip, Button, Row, Tag} from "antd";
import completenessIcon from "../../assets/completeness.png";
import completenessGreenIcon from "../../assets/completeness_green.png";
import completenessRedIcon from "../../assets/completeness_red.png";
import activeIcon from "../../assets/active.png";
import opennessIcon from "../../assets/openness.png";
import opennessGreenIcon from "../../assets/openness_green.png";
import opennessRedIcon from "../../assets/openness_red.png";
import likeIcon from "../../assets/like.png";
import dislikeIcon from "../../assets/dislike.png";
import matchedIcon from "../../assets/matched.png";
import pairedIcon from "../../assets/paired.png";
import {TablePhotos, TableRender, TableFlags} from "../index";
import moment, {now} from "moment";
import _ from "lodash";
import {connect, useDispatch, useSelector} from "react-redux";
import {chatActions, moderationActions, profileActions, streamRoomMessages} from "../../appRedux/Actions";
import {PlusCircleTwoTone, MinusCircleTwoTone} from "@ant-design/icons";
import uuid from "react-uuid";
import likedByIcon from "../../assets/liked_by.png";
import passedByIcon from "../../assets/passed_by.png";

function ProfileTableRenderer(props) {
    const {profiles, fetched, profileSelectedAction, enableRowSelection} = props

    const [dataSource, setDataSource] = useState(profiles);

    useEffect(() => {
        setDataSource(profiles);
    }, [profiles]);

    const [expanded, setExpanded] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState()

    const like_img = <Image src={likeIcon} alt={"likeIcon"} className="table-icon" preview={false}/>
    const pass_img = <Image src={dislikeIcon} alt={"passIcon"} className="table-icon" preview={false}/>
    const match_img = <Image src={matchedIcon} alt={"matchIcon"} className="table-icon" preview={false}/>
    const liked_by_img = <Image src={likedByIcon} alt={"liked_byIcon"} className="table-icon" preview={false}/>
    const passed_by_img = <Image src={passedByIcon} alt={"passed_byIcon"} className="table-icon" preview={false}/>
    const completeness_img = <Image src={completenessIcon} alt={"completenessIcon"} className="table-icon" preview={false}/>
    const completeness_green_img = <Image src={completenessGreenIcon} alt={"completenessGreenIcon"} className="table-icon" preview={false}/>
    const completeness_red_img = <Image src={completenessRedIcon} alt={"completenessRedIcon"} className="table-icon" preview={false}/>
    const openness_img = <Image src={opennessIcon} alt={"opennessIcon"} className="table-icon" preview={false}/>
    const openness_green_img = <Image src={opennessGreenIcon} alt={"opennessGreenIcon"} className="table-icon" preview={false}/>
    const openness_red_img = <Image src={opennessRedIcon} alt={"opennessRedIcon"} className="table-icon" preview={false}/>
    const active_img = <Image src={activeIcon} alt={"activeIcon"} className="table-icon" preview={false}/>

    function formatDate(date) {
        const today = moment.utc().local().endOf('day');
        const daysAgo = today.diff(moment.utc(date).local(), 'days');

        if (daysAgo === 0) {
            return (<center className={'timestamp'}><Tooltip
                title={moment.utc(date).local().format('MMM Do, y')}>Today</Tooltip><br/>{moment.utc(date).local().format('h:mm a')}
            </center>)
        } else if (daysAgo === 1) {
            return (<center className={'timestamp'}>Yesterday<br/>{moment.utc(date).local().format('h:mm a')}</center>)
        } else if (daysAgo <= 7) {
            return (
                <center className={'timestamp'}>{moment.utc(date).local().format('dddd')}<br/>{moment.utc(date).local().format('h:mm a')}
                </center>)
        } else if (daysAgo < 365) {
            return (
                <center className={'timestamp'}>{moment.utc(date).local().format('ddd, MMM D')}<br/>{moment.utc(date).local().format('h:mm a')}
                </center>)
        } else {
            // Aug 8, 2014, 8:25 AM
            return (
                <center className={'timestamp'}>{moment.utc(date).local().format('MMM D, Y')}<br/>{moment.utc(date).local().format('h:mm a')}
                </center>)
        }
    }

    function shortNumber(number){
        if (number >= 1000) {
            return Math.round((number / 1000) * 10) / 10 + "k"
        }
        else {
            return number
        }
    }

    function roundPercent(number){
        return Math.round(number * 100)
    }

    function getCompletenessIcon(score){
        if (score >= 0.6) {
            return <span style={{"color": "rgb(61 149 74)"}}> {roundPercent(score) + "%"} {completeness_green_img}</span>
        }
        else if (score >= 0.4) {
            return <span> {roundPercent(score) + "%"} {completeness_img}</span>
        }
        else {
            return <span style={{"color": "rgb(139 31 31)"}}> {roundPercent(score) + "%"} {completeness_red_img}</span>
        }
    }

    function getOpenessIcon(score){
        if (score >= 0.6) {
            return <span style={{"color": "rgb(61 149 74)"}}> {roundPercent(score) + "%"} {openness_green_img}</span>
        }
        else if (score >= 0.4) {
            return <span> {roundPercent(score) + "%"} {openness_img}</span>
        }
        else {
            return <span style={{"color": "rgb(139 31 31)"}}> {roundPercent(score) + "%"} {openness_red_img}</span>
        }
    }

    const columns = [
        {
            title: 'Flags',
            key: 's-p',
            render: (text, record) => <TableFlags record={record}/>
        },
        {
            title: 'Interaction',
            key: 'matched_profiles',
            render: profile => <div className="table-cell-image">
                <Row justify={"space-between"} align="middle" style={{"fontSize": "0.6em"}}>
                    <Col span={24}>
                        <div className="interaction-row">
                            <div className="interaction-div">
                                <Tooltip title={"Matched " + profile.match_count ?? 0}>
                                    <b>{profile.match_count ? shortNumber(profile.match_count) : 0}</b>
                                    {match_img}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="interaction-row">
                            <div className="interaction-div">
                                <Tooltip title={"Liked " + profile.like_count ?? 0}>
                                    <b>{profile.like_count ? shortNumber(profile.like_count) : 0}</b>
                                    {like_img}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="interaction-row">
                            <div className="interaction-div">
                                <Tooltip title={"Passed " + profile.pass_count ?? 0}>
                                    <b>{profile.pass_count ? shortNumber(profile.pass_count) : 0}</b>
                                    {pass_img}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="interaction-row">
                            <div className="interaction-div">
                                <Tooltip title={"Liked by " + profile.liked_by_count ?? 0}>
                                    <b>{profile.liked_by_count ? shortNumber(profile.liked_by_count) : 0}</b>
                                    {liked_by_img}
                                </Tooltip>
                            </div>
                        </div>
                            <div className="interaction-row">
                                <div className="interaction-div">
                                    <Tooltip title={"Passed by " + profile.passed_by_count ?? 0}>
                                        <b>{profile.passed_by_count ? shortNumber(profile.passed_by_count) : 0}</b>
                                        {passed_by_img}
                                    </Tooltip>
                                </div>
                            </div>
                    </Col>
                </Row>
            </div>
        },
        {
            title: 'Metrics',
            key: 'matched_profiles',
            render: profile => <div className="table-cell-image">
                <Row justify={"space-between"} align="middle" style={{"fontSize": "0.6em"}}>
                    <Col span={24}>
                        <div className="interaction-row">
                            <div className="interaction-div">
                                <Tooltip title={"Investment"}>
                                    <b>{profile.completeness_score ? getCompletenessIcon(profile.completeness_score) : getCompletenessIcon(0)}</b>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="interaction-row">
                            <div className="interaction-div">
                                <Tooltip title={"Openness"}>
                                    <b>{profile.openness_score ? getOpenessIcon(profile.openness_score) : getOpenessIcon(0)}</b>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="interaction-row" style={{"fontSize": "0.9em"}}>
                            <div className="interaction-div">
                                <Tooltip title={"Days active within 30/60/90 days"}>
                                    <b>{profile.days_active_30 ?? "0"}
                                        /{profile.days_active_60 ?? "0"}
                                        /{profile.days_active_90 ?? "0"}</b>
                                </Tooltip>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        },
        {
            title: 'Photos',
            dataIndex: 'photos',
            key: 'photos',
            render: (text, record) => <TablePhotos handleRowExpansion={handleRowExpansion} record={record}/>
        },
        // {
        //     title: '#',
        //     key: 'hash',
        //     render: (text, record) => <Col>
        //         <Row className="preference-flags"
        //              style={{
        //                  "maxHeight": "180px",
        //                  "overflowY": "hidden",
        //                  "overflowX": "hidden",
        //              }}>{record.preference_lists?.map((prefs) => {
        //             return prefs.unreviewed_preference_instances.map((unrev) => {
        //                 return <Tag className="preference-flag" closable color="orange" key={uuid()}>{unrev}</Tag>
        //             })
        //         })}
        //             {record.boundary_lists?.map((prefs) => {
        //                 return prefs.unreviewed_boundary_instances?.map((unrev) => {
        //                     return <Tag className="preference-flag" closable color="red" key={uuid()}>{unrev}</Tag>
        //                 })
        //             })}</Row></Col>
        // },
        {
            title: "Seen",
            key: "last_seen_ts",
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return moment.utc(a.last_seen_ts ?? a.created_ts).local().unix() - moment.utc(b.last_seen_ts ?? b.modified_ts).local().unix()
            },
            render: profile => {
                return formatDate(profile.last_seen_ts ?? profile.created_ts)
            },
        },
        {
            title: 'Created',
            key: 'created_ts',
            dataIndex: 'created_ts',
            sorter: (a, b) => moment.utc(a.created_ts).local().unix() - moment.utc(b.created_ts).local().unix(),
            render: created_ts => <> {
                formatDate(created_ts)
            } </>,
        },
    ];

    const handleRowExpansion = (exp, record, current_expanded, photo) => {
        const expandedRows = [];
        if (record.id !== (current_expanded ? current_expanded[0] : null)) {
            if (exp) {
                expandedRows.push(record.id);
                // setRoomToListenTo(record)
            }
        }
        if (expandedRows[0]) {
            setExpanded(expandedRows);
            setDataSource(profiles);
        }
        else if (exp === false) {
            setExpanded(expandedRows)
        }

        if (photo) {
            setCurrentPhoto(photo)
        }
        else {
            setCurrentPhoto(null)
        }
    };

    const dispatcher = useDispatch();

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
        if (!_.isEmpty(selectedRowKeys)) {
            const profileId = selectedRowKeys[0]
            profileSelectedAction(profileId)
        }
    };

    const rowSelection = enableRowSelection ? {
        selectedRowKeys,
        onChange: onSelectChange,
    } : undefined

    return <>
        <Table
            expandIconColumnIndex={1}
            size='small'
            pagination={false}
            rowKey={record => record.id}
            columns={columns}
            rowSelection={rowSelection}
            loading={!props.fetched}
            onExpand={(e, r) => handleRowExpansion(e, r, expanded)}
            locale={{
                emptyText: !fetched ?
                    <>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                        <Skeleton active={true} paragraph={true} avatar={true}/>
                    </> :
                    <Empty/>
            }}
            expandable={{
                expandIcon: ({expanded, onExpand, record}) =>
                    expanded ? (
                        <Button size="small" onClick={e => onExpand(record, e)}>-</Button>
                    ) : (
                        <Button size="small" onClick={e => onExpand(record, e)}>+</Button>
                    )
            }}
            expandRowByClick={false}
            expandedRowKeys={expanded}
            selectedRowKeys={selectedRowKeys}
            onExpandedRowsChange={() => setDataSource(profiles)}
            expandedRowRender={(record, index, indent, expanded) => (
                expanded ? <TableRender key={uuid()} profile={record} currentPhoto={currentPhoto}/> : null
            )}
            dataSource={profiles}/>

    </>
}

export default ProfileTableRenderer
