import {
    Login,
    Unreviewed,
    Reported,
    Reviewed,
    Health, NewProfiles, SearchResult, ChatsProfile, Hashtags, AutoModeratedProfiles,
    Settings,
    TechIssues,
    BillingIssues,
    ModIssues,
    AndroidIssues,
    DebugProfiles,
    ChatOnlyContainer
} from "../containers";
import {Access, Landing, ChatOnly} from "../layouts";

const Routes = [
    {path: "/login", component: Login, layout: Access, auth: false},
    {path: "/logout"},
    {path: "/health", component: Health, layout: Access, auth: false},

    {path: "/", component: Login, layout: Access, auth: true},
    {path: "/unreviewed", component: Unreviewed, layout: Landing, auth: true},
    {path: "/reviewed", component: Reviewed, layout: Landing, auth: true},
    {path: "/new-profiles", component: NewProfiles, layout: Landing, auth: true},
    {path: "/reported", component: Reported, layout: Landing, auth: true},
    {path: "/search-result", component: SearchResult, layout: Landing, auth: true},
    {path: "/chats", component: ChatsProfile, layout: Landing, auth: true},
    {path: "/hashtag-management", component: Hashtags, layout: Landing, auth: true},
    {path: "/auto-moderated-profile", component: AutoModeratedProfiles, layout: Landing, auth: true},
    {path: "/tech-issues", component: TechIssues, layout: Landing, auth: true},
    {path: "/billing-issues", component: BillingIssues, layout: Landing, auth: true},
    {path: "/mod-issues", component: ModIssues, layout: Landing, auth: true},
    {path: "/android-issues", component: AndroidIssues, layout: Landing, auth: true},
    {path: "/bugsy-profiles", component: DebugProfiles, layout: Landing, auth: true},
    {path: "/chat", component: ChatOnlyContainer, layout: ChatOnly, auth: true},

    {path: "/settings", component: Settings, layout: Landing, auth: true}
];

export default Routes;
export {default as RouteComponent} from "./RouteComponent";
