import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import React, {useEffect} from "react";
import {ProfileTableRenderer} from "../../components";
import moment, {now} from "moment";

function ModIssues(props) {

    useEffect(() => {
        props.getModIssues()
    }, [])

    const modIssueSelected = (profileId) => {
    }

    return ( 
    <ProfileTableRenderer profiles={props.modIssues}
                                 fetched={props.modIssuesFetched}
                                 profileSelectedAction={modIssueSelected}
                                 enableRowSelection={false}
    />
    )
}


const actionCreators = {
    getModIssues: profileActions.getModIssues,
};

function mapStateToProps(state) {
    const { modIssues, modIssuesFetched } = state.profileReducer
    return { modIssues, modIssuesFetched };
}


export default connect(mapStateToProps, actionCreators)(ModIssues);
