import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import React, {useEffect} from "react";
import {ProfileTableRenderer} from "../../components";

function AndroidIssues(props) {

    useEffect(() => {
        props.getAndroidIssues()
    }, [])

    const androidIssueSelected = (profileId) => {
    }

    return ( 
    <ProfileTableRenderer profiles={props.androidIssues}
                                 fetched={props.androidIssuesFetched}
                                 profileSelectedAction={androidIssueSelected}
                                 enableRowSelection={false}
    />
    )
}


const actionCreators = {
    getAndroidIssues: profileActions.getAndroidIssues,
};

function mapStateToProps(state) {
    const { androidIssues, androidIssuesFetched } = state.profileReducer
    return { androidIssues, androidIssuesFetched };
}


export default connect(mapStateToProps, actionCreators)(AndroidIssues);
