import { authHeader } from '../Helpers';
import {config} from "../Config";
import {handleResponse} from "./authentication.service";

export const moderationService = {
    getModerationByAccountIDFromServer,
    patchAccountModerationWithIdOnServer,
    deleteModerationByAccountIdOnServer,
    createModerationWithProfileIdOnServer,
    fetchModerationTagsFromServer
};

function fetchModerationTagsFromServer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/mod-action/tags`, requestOptions).then(handleResponse);
}

function getModerationByAccountIDFromServer(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/mod-action`, requestOptions).then(handleResponse);
}

function createModerationWithProfileIdOnServer(profileId, params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/profile/${profileId}/mod-action`, requestOptions).then(handleResponse);
}

function patchAccountModerationWithIdOnServer(accountId, moderationId, params) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/mod-action/${moderationId}`, requestOptions).then(handleResponse);
}

function deleteModerationByAccountIdOnServer(accountId, moderationId) {
    const requestOptions = {
        method: 'Delete',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/account/${accountId}/mod-action/${moderationId}`, requestOptions).then(handleResponse);
}
