export const macroConstants = {
    CREATE_MACRO_REQUEST: 'CREATE_MACRO_REQUEST',
    CREATE_MACRO_SUCCESS: 'CREATE_MACRO_SUCCESS',
    CREATE_MACRO_FAILURE: 'CREATE_MACRO_FAILURE',
    GET_MACROS_REQUEST: 'GET_MACROS_REQUEST',
    GET_MACROS_SUCCESS: 'GET_MACROS_SUCCESS',
    GET_MACROS_FAILURE: 'GET_MACROS_FAILURE',
    EDIT_MACRO_REQUEST: 'EDIT_MACROS_REQUEST',
    EDIT_MACRO_SUCCESS: 'EDIT_MACROS_SUCCESS',
    EDIT_MACRO_FAILURE: 'EDIT_MACROS_FAILURE',
    DELETE_MACRO_REQUEST: 'DELETE_MACRO_REQUEST',
    DELETE_MACRO_SUCCESS: 'DELETE_MACRO_SUCCESS',
    DELETE_MACRO_FAILURE: 'DELETE_MACRO_FAILURE'
};