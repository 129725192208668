import {useDispatch, useSelector} from "react-redux";
import {ProfileTableRenderer} from "../../components";
import React, {useEffect, useMemo} from "react";
import {chatActions} from "../../appRedux/Actions";

function Chats() {
    const chatProfiles = useSelector(state => state.profileReducer.chatProfiles) ?? []
    const chatProfilesLoading = useSelector(state => state.profileReducer.chatProfilesLoading) ?? false

    const dispatcher = useDispatch()

    useEffect(() => {
        console.log("Chats page fetching all chat rooms.")
        dispatcher(chatActions.fetchAllRooms(false))
    }, [])

    const reportedProfileSelected = (profileId) => {
    }
    return <>
        { chatProfilesLoading && <div style={{color:'black', height:20, width: '100%'}}>Updating...</div> }
        <ProfileTableRenderer profiles={chatProfiles}
                              fetched={!chatProfilesLoading}
                              profileSelectedAction={reportedProfileSelected}
                              enableRowSelection={false}/>
    </>
}

export default Chats