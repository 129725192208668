import React, {useEffect, useState} from "react";
import {Button, Card, Avatar, Modal, message} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    BugOutlined,
    QuestionOutlined
} from "@ant-design/icons";
import {macroActions} from "../../appRedux/Actions";
import {connect} from "react-redux";

function Macro(props) {

    const {Meta} = Card;
    const [modalVisible, setModalVisible] = useState(false);

    const icons = [
        <BugOutlined/>,

    ];

    const handleEdit = () => {
        props.setEditMacro(props.macro);
        props.setType('Edit Macro');
    }

    const showModal = () => {
        setModalVisible(true);
    };

    const hideModalConfirm = () => {
        setModalVisible(false);
        props.deleteMacro(props.macro.id);
        message.success('Success! Macro deleted.', 3);
    };

    const hideModalCancel = () => {
        setModalVisible(false);
    };

    return <>
        <Card className={"settings-macro-card"}
              actions={[
                  <EditOutlined key="edit" onClick={() => handleEdit()}/>,
                  <DeleteOutlined key="delete" onClick={() => showModal()}/>,
              ]}
              title={props.macro.title}
              extra={"#" + props.macro.id}
        >
            {props.macro.content}
        </Card><br/>

        <Modal
            title="Confirm"
            visible={modalVisible}
            onOk={hideModalConfirm}
            onCancel={hideModalCancel}
            okText="Confirm"
            cancelText="Cancel"
        >
            Are you sure you'd like to delete this Macro? <b>This cannot be undone!</b>
        </Modal>
    </>
}

const actionCreators = {
    deleteMacro: macroActions.deleteMacro
};

function mapStateToProps(state) {
    const {} = state.macroReducer;
    return {};
}

export default connect(mapStateToProps, actionCreators)(Macro);