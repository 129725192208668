import uuid from "react-uuid";
import {Button, Table, Tag, Drawer, Popconfirm, Badge, Space, Typography, Tooltip} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import _, {capitalize} from "lodash";
import {moderationActions} from "../../appRedux/Actions";
import {connect} from "react-redux";
import {PlusOutlined, DeleteFilled, EditFilled} from '@ant-design/icons';
import {
    Form,
    Input,
    Select,
    Switch,
} from 'antd';
import {storageService} from "../../appRedux/Storage";
import {storageConstants} from "../../appRedux/Constants/storage.constants";
import ExpandedModeration from "../ExpandedModeration";
import ModSwitch from "./ModSwitch";
import {QuestionCircleOutlined} from '@ant-design/icons';
import {formatDate} from "../../Utilities";

const {Text} = Typography

function ProfileModeration(props) {
    const {profile} = props
    const {members} = profile

    let mods = props.profileModerationList.get(profile.id);

    console.log(members)
    const accountID = members.length > 0 ? members[0]?.account?.id : 0
    console.log(accountID)

    useEffect(() => {
        props.getModerationByProfile(profile);
    }, []);

    const storedModerationTags = storageService.getFromStore(storageConstants.MODERATION_TAGS) ?? []
    let options = []

    storedModerationTags.split(",").forEach((tag) => {
        options.push({
            value: tag,
            label: capitalize(tag),
        })
    })

    const [modExpanded, setModExpanded] = useState([]);

    // add moderation drawer visibility state
    const [drawerVisibility, setDrawerVisibility] = useState(false);

    // patch moderation drawer visibility state
    const [patchDrawerVis, setPatchDrawerVis] = useState(false);
    const [modToEdit, setModToEdit] = useState({
        account_id: 0,
        active: false,
        created_ts: "",
        creator: "",
        field_modified: "",
        id: 0,
        ml_flagged: [],
        ml_results: [],
        new_value: "",
        original_value: "",
        reason: "",
        tags: [],
        type: ""
    });

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };
    const [form] = Form.useForm();
    const [patchForm] = Form.useForm();

    const typeColors = {
        'Warning': {color: "#0958d9"},
        "Strike": {color: "#c41d7f"},
        "Suspension": {color: "#d4380d"},
        "Ban": {color: "#cf1322"},
        "Free": {color: "#389e0d"}
    }


    const columns = [
            {
                title: ' ',
                width: '1em',
                dataIndex: 'active',
                key: 'active',
                render:
                    (active, record) => {
                        if (active) {
                            return <center><Tooltip title="Active"><Badge className="badge-no-text"
                                                                                 status="success"/></Tooltip>
                            </center>
                        } else {
                            return <center><Tooltip title="Inactive"><Badge className="badge-no-text"
                                                                            status="default"/></Tooltip>
                            </center>
                        }
                    }
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: type => (
                    < Text style={typeColors[type] ?? ''} key={uuid()} strong>
                        {type}
                    </Text>
                )
            },
            {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                render: tags => (
                    <span>
                        {tags ? tags.length > 1 ?
                            <Tooltip title={tags.join(', ')}>
                            <Badge color="#fafafa" count={'+' + (tags.length - 1)} size={"small"}
                                   style={{color: "#000"}}>
                                <Tag key={uuid()}>{tags[0]}</Tag>
                            </Badge>
                            </Tooltip>
                            : <Tag key={uuid()}>
                                {tags[0]}
                            </Tag>: ''}

            </span>
                )
            },
            {
                title: 'Created',
                dataIndex: 'created_ts',
                key: 'created_ts',
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment.utc(a.created_ts).local().unix() - moment.utc(b.created_ts).local().unix(),
                render: created_ts => <> {
                    <small>{formatDate(created_ts)}</small>
                } </>,

            },
            {
                title: ' ',
                key: 'action',
                render:
                    (record) =>
                        <span>
                            <Tooltip title="Edit"><Button shape="circle" size="small" icon={<EditFilled/>}
                                                          onClick={() => editMod(record)}/></Tooltip>
                        <Popconfirm placement="top" title={"Confirm deletion"}
                                    onConfirm={() => showMoreActions(record)}
                                    okText="Delete" cancelText="Cancel">
                            <Tooltip title="Delete"><Button shape="circle" size="small" icon={<DeleteFilled/>}
                                                            style={{marginLeft: '4px'}}/></Tooltip>
                        </Popconfirm>
                            </span>
            }
        ]
    ;

    const handleModRowExpansion = (expanded, record) => {
        const expandedRows = modExpanded;
        if (expanded) {
            expandedRows.push(record.id);
        } else {
            const index = expandedRows.indexOf(record.id)
            if (index > -1) {
                expandedRows.splice(index, 1)
            }
        }
        setModExpanded(expandedRows);
    };


    function showDrawer() {
        setDrawerVisibility(true)
    }

    function onClose() {
        setDrawerVisibility(false)
    }

    function editMod(record) {
        setModToEdit(record);
        patchForm.setFieldsValue({
            type: record.type,
            tags: record.tags,
            active: record.active,
            reason: record.reason
        })
        setPatchDrawerVis(true);
    }

    function editClose() {
        setPatchDrawerVis(false);
    }

    const onSubmitEditMod = (values) => {
        props.patchModification(modToEdit.account_id, modToEdit.id, profile.id, values);
        if (!props.creatingModerationInProgress) {
            form.resetFields()
            editClose();
            props.getModerationByProfile(profile);
            mods = props.profileModerationList.get(profile.id);
        }
    }

    const onFinish = (values) => {
        props.createModeration(profile, values)

        if (!props.creatingModerationInProgress) {
            form.resetFields()
            onClose();
        }
    };

    function showMoreActions(moderation) {
        if (accountID !== 0) {
            props.deleteModeration(profile, moderation.account_id, moderation.id)
        }
    }

    function setActive(checked) {
        modToEdit.active = !checked
    }

    return <div style={{height: "100%", minHeight: "455px", padding: "10px", backgroundColor: "#fff"}}
                className="site-drawer-render-in-current-wrapper-2">
        <Button
            onClick={showDrawer}
            type="primary">
            <PlusOutlined/> New Moderation
        </Button>
        <Drawer
            title="Add Moderation"
            placement="right"
            width={400}
            closable={false}
            onClose={onClose}
            visible={drawerVisibility}
            getContainer={false}
            style={{position: 'absolute'}}>

            <Form
                form={form}
                {...layout}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{
                    type: 'Free',
                    active: true,
                    tags: [],
                    reason: "",
                }}> <a
                href="https://drive.google.com/file/d/1ztKAk16iSQbFHpXCw-b1NEFMjmBOMeyQ/view"><QuestionCircleOutlined/></a>
                <Form.Item label="Type" name='type'>
                    <Select>
                        <Select.Option value="Free">Free</Select.Option>
                        <Select.Option value="Suspension">Suspension</Select.Option>
                        <Select.Option value="Ban">Ban</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Tags" name='tags'
                           rules={[
                               () => ({
                                   validator(rules, value) {
                                       if (_.isEmpty(value)) {
                                           return Promise.reject(new Error('Select at least one tag'));
                                       }
                                       return Promise.resolve();
                                   },
                               }),
                           ]}>
                    <Select
                        options={options}
                        mode="multiple"
                        style={{width: '100%'}}
                        placeholder="Select tags"
                        optionLabelProp="label"/>
                </Form.Item>
                <Form.Item label="Active" name='active'>
                    <Switch/>
                </Form.Item>
                <Form.Item label="Reason" name='reason' help="Any additional information if any.">
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: 6}}>
                    <Button onClick={onClose}>Cancel</Button> &nbsp;
                    <Button type="primary" htmlType="submit" loading={props.creatingModerationInProgress}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
        <Table
            dataSource={mods}
            className={"moderation-table"}
            pagination={{pageSize: 9, showSizeChanger: false}}
            rowKey={record => record.id}
            columns={columns}
            size="small"

            expandable={{
                expandRowByClick: true,
                showExpandColumn: false,
                expandedRowKeys: modExpanded,
                expandedRowRender: (record) =>
                    <ExpandedModeration
                        editMod={editMod}
                        profile={profile}
                        record={record}
                    />,
                rowExpandable: record => true,
                expandedRowClassName: record => "moderation-table-expanded-row",
            }}
            onExpand={handleModRowExpansion}/>
        <Drawer
            title="Patch Moderation"
            placement="right"
            width={400}
            closable={false}
            onClose={editClose}
            visible={patchDrawerVis}
            getContainer={false}
            style={{position: 'absolute'}}>
            <Form
                form={patchForm}
                {...layout}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onSubmitEditMod}
                scrollToFirstError>
                <Form.Item label="Type" name='type'>
                    <Select value={modToEdit.type}>
                        <Select.Option key="Free" value="Free">Free</Select.Option>
                        <Select.Option key="Warning" value="Warning">Warning</Select.Option>
                        <Select.Option key="Strike" value="Strike">Strike</Select.Option>
                        <Select.Option key="Suspension" value="Suspension">Suspension</Select.Option>
                        <Select.Option key="Ban" value="Ban">Ban</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Tags" name='tags'
                           rules={[
                               () => ({
                                   validator(rules, value) {
                                       if (_.isEmpty(value)) {
                                           return Promise.reject(new Error('Select at least one tag'));
                                       }
                                       return Promise.resolve();
                                   },
                               }),
                           ]}>
                    <Select
                        options={options}
                        mode="multiple"
                        style={{width: '100%'}}
                        optionLabelProp="label"/>
                </Form.Item>
                <Form.Item label="Active" name='active'>
                    <Switch checked={modToEdit.active} onChange={setActive}/>
                </Form.Item>
                <Form.Item label="Reason" name='reason' help="Any additional information if any.">
                    <Input.TextArea defaultValue="ffgfdfgd"/>
                </Form.Item>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: 6}}>
                    <Button onClick={editClose}>Cancel</Button> &nbsp;
                    <Button type="primary" htmlType="submit" loading={props.creatingModerationInProgress}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    </div>
}


const actionCreators = {
    getModerationByProfile: moderationActions.getModerationOnProfile,
    createModeration: moderationActions.createModerationWithProfileAction,
    patchModification: moderationActions.patchAccountModerationWithId,
    deleteModeration: moderationActions.deleteModerationByAccountId
};

function mapStateToProps(state) {
    const {
        creatingModerationInProgress,
        fetching,
        moderationList,
        profileModerationList,
        moderation
    } = state.moderationReducer
    return {creatingModerationInProgress, fetching, moderationList, profileModerationList, moderation};
}

export default connect(mapStateToProps, actionCreators)(ProfileModeration);



