import { moderationConstants, profileConstants} from "../Constants";
import { moderationService } from "../Services";
import { alertActions } from "../Actions";
import _ from "lodash";
import {storageService} from "../Storage";
import {storageConstants} from "../Constants/storage.constants";


export const moderationActions = {
    createModerationWithProfileAction,
    patchAccountModerationWithId,
    deleteModerationByAccountId,
    getModerationOnProfile,
    getModerationTagAction
};


function getModerationTagAction() {
    return dispatch => {
        moderationService.fetchModerationTagsFromServer()
            .then( moderationTags => {
                    storageService.saveToStore(storageConstants.MODERATION_TAGS, moderationTags)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    // function request() { return { type: moderationConstants.GET_MODERATION_TAGS_REQUEST } }
    // function success(moderationTags) { return { type: moderationConstants.GET_MODERATION_TAGS_SUCCESS, moderationTags } }
    function failure(error) { return { type: moderationConstants.GET_MODERATION_TAGS_ERROR, error } }
}

function getModerationOnProfile(profile) {
    return dispatch => {
        const moderationList = []
        const { members } = profile

        members.forEach(member => {
            if(member.account !== null) {
                member.account?.moderation_actions.forEach(moderation => {
                    moderationList.push(moderation)
                })
            }
        })
        console.log("Moderation.Actions - Get Mod On Profile");
        console.log(moderationList);
        dispatch(success(moderationList, profile))
    }
    function success(moderationList, profile) { return { type: moderationConstants.GET_ACCOUNT_MODERATION_SUCCESS, moderationList, profile } }
}



function createModerationWithProfileAction(profile, params) {
    return dispatch => {
        dispatch(request());
        moderationService.createModerationWithProfileIdOnServer(profile.id, params)
            .then(mod => {
                console.log('mod list:', mod)
                    if(!_.isEmpty(mod)) {
                            dispatch(success(profile, mod[0]))
                    }
                },
                error => {
                    dispatch(failure({deleted: false, error: error}));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: moderationConstants.CREATE_ACCOUNT_MODERATION_REQUEST } }
    function success(profile, moderation) { return { type: moderationConstants.CREATE_ACCOUNT_MODERATION_SUCCESS, profile, moderation } }
    function failure(response) { return { type: moderationConstants.CREATE_ACCOUNT_MODERATION_FAILURE, response } }
}

function patchAccountModerationWithId(accountId, moderationId, profileId, params) {
    return dispatch => {
        dispatch(request({}));
        moderationService.patchAccountModerationWithIdOnServer(accountId, moderationId, params)
            .then(moderation => {
                    dispatch(success(moderation, profileId));
                    dispatch(successProfile(moderation));
                    
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: moderationConstants.PATCH_ACCOUNT_MODERATION_REQUEST } }
    function success(moderation, profileID) { return { type: moderationConstants.PATCH_ACCOUNT_MODERATION_SUCCESS, moderation, profileID } }
    function successProfile(moderation) { return { type: profileConstants.TOGGLE_MODERATION_ACTIVE_SUCCESS, moderation }}
    function failure(error) { return { type: moderationConstants.PATCH_ACCOUNT_MODERATION_FAILURE, error } }
}


function deleteModerationByAccountId(profile, accountId, moderationId) {
    return dispatch => {
        dispatch(request());
        console.log('deleteModerationByAccountId', "onRequest")

        moderationService.deleteModerationByAccountIdOnServer(accountId, moderationId)
            .then(_ => {
                    console.log('deleteModerationByAccountId', "onSuccessful")
                    dispatch(success(profile, true, moderationId));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: moderationConstants.DELETE_ACCOUNT_MODERATION_REQUEST } }
    function success(profile, deleted, moderationId) { return { type: moderationConstants.DELETE_ACCOUNT_MODERATION_SUCCESS, profile, deleted, moderationId } }
    function failure(error) { return { type: moderationConstants.DELETE_ACCOUNT_MODERATION_FAILURE, error } }
}
