import {Badge, Layout, Menu, message, Tooltip} from "antd";
import {StarFilled, CameraFilled, FlagFilled, MessageFilled, HourglassFilled, TagsFilled} from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, connect} from "react-redux";
import {navigateTo} from "../../route/Redirect";
import moment from 'moment'
import {chatActions, macroActions, mlLabelActions, profileActions} from "../../appRedux/Actions";
const {Header} = Layout;

function ProfileMenu(props) {

    const profiles = useSelector(state => state.profileReducer.profiles)
    const reportedProfiles = useSelector(state => state.profileReducer.reportedProfiles)
    const newProfiles = useSelector(state => state.profileReducer.newProfiles)
    const newAutoProfiles = useSelector(state => state.profileReducer.newAutoProfiles)
    const reviewedProfiles = useSelector(state => state.profileReducer.reviewedProfiles)
    const searchProfileResult = useSelector(state => state.profileReducer.searchProfileResult) ?? []
    const searchedText = useSelector(state => state.profileReducer.searchedText) ?? ""
    const sendingMessageError = useSelector(state => state.chatReducer.sendingMessageError)
    const isChatServerWorking = useSelector(state => state.chatReducer.chatServerWorking)
    const techIssues = useSelector(state => state.profileReducer.techIssues) ?? []
    const billingIssues = useSelector(state => state.profileReducer.billingIssues) ?? []
    const modIssues = useSelector(state => state.profileReducer.modIssues) ?? []
    const androidIssues = useSelector(state => state.profileReducer.androidIssues) ?? []
    const bugsyProfiles = useSelector(state => state.profileReducer.bugsyProfiles) ?? []

    const dispatcher = useDispatch()

    const unReadMessageCount = useSelector(state => state.profileReducer.unReadMessageCount)
    let [counter, setCounter] = useState(0);

    
    useEffect(()=> {
        props.getAllMacros();
    }, []);

    const {getReportedProfile} = props;

    useEffect(() => {
        props.getReportedProfile()
    }, [props.getReportedProfile])

    useEffect(()=> {
        props.getLabels();
    }, []);

    useEffect(()=> {
        props.getProfileWithUnreviewedPhoto();
    }, []);

    useEffect(()=> {
        props.getReviewedProfiles();
    }, []);


    if(sendingMessageError !== null && sendingMessageError !== "") {
        message.error(sendingMessageError).then(r => "")
    }

    let menus = [
        {
            name: "New Profiles",
            url: "/new-profiles",
            icon: <StarFilled style={{color: '#585980'}}/>,
            count: newProfiles?.length ?? 0
        },
        {
            name: "Unreviewed Photos",
            url: "/unreviewed",
            icon: <CameraFilled style={{color: '#585980'}} />,
            count: profiles?.length ?? 0
        },
        {
            name: "Reported Profiles",
            url: "/reported",
            icon: <FlagFilled style={{color: '#585980'}} />,
            count: reportedProfiles?.length ?? 0
        },
        {
            name: "New Chats",
            url: "/chats",
            icon: <MessageFilled style={{color: '#585980'}} />,
            count: unReadMessageCount
        },
        {
            name: "Your Recently Reviewed Profiles",
            url: "/reviewed",
            icon: <HourglassFilled style={{color: '#585980'}} />,
            count:  reviewedProfiles?.length ?? 0
        },
        {
            name: "#Management",
            url: "/hashtag-management",
            icon: <TagsFilled style={{color: '#585980'}} />,
            count: 0
        },
        {
            name: "AI",
            url: "/auto-moderated-profile",
            count: newAutoProfiles?.length ?? 0
        },
        {
            name: "Tech",
            url: "/tech-issues",
            count: techIssues?.length ?? 0
        },
        {
            name: "Billing",
            url: "/billing-issues",
            count: billingIssues?.length ?? 0
        },
        {
            name: "Mod",
            url: "/mod-issues",
            count: modIssues?.length ?? 0
        },
        {
            name: "Android",
            url: "android-issues",
            count: androidIssues?.length ?? 0
        },
        {
            name: "Bugsy",
            url: "bugsy-profiles",
            count: bugsyProfiles?.length ?? 0
        }
    ]

    if(searchProfileResult.length > 0) {
        menus.push( {
            name: `Search Result [${searchedText}]`,
            url: "/search-result",
            count: props.searchResultCount ?? 0
        })
    } else {
        menus = menus.filter( menu => menu.url !== "/search-result")
    }

    function handleMenuClicked(menu) {
        navigateTo(menu.key).then(r => "")
    }

    const defaultSelected = [props.path]

    return  <Header className="site-layout-sub-header-background" style={{padding: 0, position: 'fixed', zIndex: 2, width: '100%', top: "48px"}}>
        <Menu className="main-menu" theme="light" mode="horizontal" defaultSelectedKeys={defaultSelected} onClick={handleMenuClicked}>
            {
                menus.map(menu => (
                    <Menu.Item key={menu.url}>
                        <Tooltip title={menu.name}>
                        <Badge offset={[10, 4]} size="small" overflowCount={99} count={menu.count} style={{ backgroundColor: '#777676' }}>
                            {menu.icon ? menu.icon : menu.name}</Badge>
                        </Tooltip>
                    </Menu.Item>
                ))
            }
        </Menu>
    </Header>
}

const actionCreators = {
    getAllMacros: macroActions.getAllMacros,
    getLabels: mlLabelActions.getLabels,
    getReportedProfile: profileActions.getReportedProfileAction,
    getProfileWithUnreviewedPhoto: profileActions.getProfileWithUnreviewedPhotoAction,
    getMessageCounts: chatActions.dispatchSubscribeToMessageCountsAction,
    getNewProfiles: profileActions.getNewProfilesFromServerAction,
    getReviewedProfiles: profileActions.getReviewedProfilesFromServerAction
}

function mapStateToProps(state) {
    const { searchResultCount } = state.profileReducer
    return {searchResultCount}
}

export default connect(mapStateToProps, actionCreators)(ProfileMenu);