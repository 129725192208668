import {connect} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import React, {useEffect} from "react";
import {ProfileTableRenderer} from "../../components";

function Reported(props) {

    const reportedProfileSelected = (profileId) => {
    }

    return <ProfileTableRenderer profiles={props.reportedProfiles}
                                 fetched={props.reportedProfilesFetched}
                                 profileSelectedAction={reportedProfileSelected}
                                 enableRowSelection={false}
    />
}


const actionCreators = {
    getReportedProfile: profileActions.getReportedProfileAction,
};

function mapStateToProps(state) {
    const { reportedProfiles, reportedProfilesFetched } = state.profileReducer
    return { reportedProfiles, reportedProfilesFetched };
}


export default connect(mapStateToProps, actionCreators)(Reported);
