import {storageConstants} from "../Constants/storage.constants";
import _ from 'lodash'
const LZUTF8 = require('lzutf8');

export const storageService = {
    getFromStore,
    saveToStore,
    deleteFromStore,
    requiredToLogin,
    compressSaveToStore,
    decompressFromStore,
    clearStore
};

function getFromStore(key) {
    return localStorage.getItem(key)
}

function saveToStore(key, value) {
    return localStorage.setItem(key, value)
}

function deleteFromStore(key) {
    return localStorage.removeItem(key)
}

function requiredToLogin() {
    return getFromStore(storageConstants.SESSION_DATA) == null || getFromStore(storageConstants.SUPPORT_ACCOUNT) == null
}

function compressSaveToStore(key, value) {
    return localStorage.setItem(key, LZUTF8.compress(value, { inputEncoding: "String", outputEncoding: "StorageBinaryString" }))
}

function decompressFromStore(key) {
    if(_.isEmpty(localStorage.getItem(key))) {
        return ""
    }
    return LZUTF8.decompress(localStorage.getItem(key), { inputEncoding: "StorageBinaryString", outputEncoding: "String" })
}

function clearStore() {
    deleteFromStore(storageConstants.SESSION_DATA)
    deleteFromStore(storageConstants.MODERATION_TAGS)
    deleteFromStore(storageConstants.SUPPORT_ACCOUNT)
    deleteFromStore(storageConstants.GOOGLE_TOKEN_ID)
    deleteFromStore(storageConstants.CHAT_SESSION_DATA)
    deleteFromStore(storageConstants.CHAT_ROOMS_DATA)
    deleteFromStore(storageConstants.UPDATE_CHAT_ROOM)
    deleteFromStore(storageConstants.CHAT_PROFILE_CACHE)
    localStorage.clear()
}