import { moderationConstants } from '../Constants'

const INIT_STATE = {
    profileModerationList: new Map(),
    moderationList: [],
    moderation: {},
    creatingModerationInProgress: false,
    fetching: false
}

export function moderationReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case moderationConstants.GET_ACCOUNT_MODERATION_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case moderationConstants.GET_ACCOUNT_MODERATION_SUCCESS:
            const profile = action.profile
            const updateModeration = new Map(state.profileModerationList);

            updateModeration.set(profile.id, action.moderationList);
            return {
                ...state,
                fetching: false,
                profileModerationList: updateModeration
            }
        case moderationConstants.GET_ACCOUNT_MODERATION_FAILURE:
            return {
                ...state,
                fetching: false
            }
        case moderationConstants.CREATE_ACCOUNT_MODERATION_REQUEST:
            return {
                ...state,
                creatingModerationInProgress: true,
            }
        case moderationConstants.CREATE_ACCOUNT_MODERATION_SUCCESS:
        {
            const cachedProfileModeration = new Map(state.profileModerationList);
            console.log("All existing moderations for people:");
            console.log(cachedProfileModeration);
            const profileObj = action.profile
            // above works

            const profileModerationList = state.profileModerationList.get(profileObj.id) ?? [];
            console.log("All existing moderations for person:");
            console.log(profileModerationList);

            const cachedModerationList = [ ...profileModerationList ];
            console.log("Spread moderation list set to cached mod list:");
            console.log(cachedModerationList);

            console.log("Moderation object:");
            console.log(action.moderation);
            cachedModerationList.push(action.moderation)

            cachedProfileModeration.set(profileObj.id, cachedModerationList)
            console.log("New cached moderation list, returned as profileModerationList:");
            console.log(cachedProfileModeration);
            console.log(cachedProfileModeration.get(profileObj.id));

            return {
                ...state,
                creatingModerationInProgress: false,
                profileModerationList: cachedProfileModeration
            }
        }
        case moderationConstants.CREATE_ACCOUNT_MODERATION_FAILURE:
            return {
                ...state,
                creatingModerationInProgress: false
            }
        case moderationConstants.PATCH_ACCOUNT_MODERATION_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case moderationConstants.PATCH_ACCOUNT_MODERATION_SUCCESS:
        {
            const cachedModeration = [ ...state.moderationList ]
            const updateModeration = action.moderation

            const profModList = new Map(state.profileModerationList);
            profModList.set(action.profileId, updateModeration);

            for (const index in cachedModeration) {
                if(cachedModeration[index].id === updateModeration.id)  {
                    cachedModeration[index] = updateModeration
                    break
                }
            }

            return {
                ...state,
                fetching: false,
                moderationList: cachedModeration,
                profileModerationList: profModList
            }
        }
        case moderationConstants.PATCH_ACCOUNT_MODERATION_FAILURE:
            return {
                ...state,
                fetching: false
            }

        case moderationConstants.DELETE_ACCOUNT_MODERATION_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case moderationConstants.DELETE_ACCOUNT_MODERATION_SUCCESS:
        {
            const cachedProfileModeration = new Map(state.profileModerationList)
            const profile = action.profile
            let profileModerationList = cachedProfileModeration.get(profile.id) ?? []


            profileModerationList = profileModerationList.filter((moderation) => moderation.id !== action.moderationId)

            cachedProfileModeration.set(profile.id, profileModerationList)

            return {
                ...state,
                fetching: false,
                profileModerationList: cachedProfileModeration
            }
        }
        case moderationConstants.DELETE_ACCOUNT_MODERATION_FAILURE:
            return {
                ...state,
                fetching: false
            }
        default:
            return state
    }
}