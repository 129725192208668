import { combineReducers } from 'redux';

import Auth from './Auth';
import { authenticationReducer } from './authentication.reducer';
import { profileReducer } from './profile.reducer';
import { moderationReducer } from './moderation.reducer';
import { chatReducer } from './chat.reducer';
import { chatonlyReducer } from './chatonly.reducer';
import { alertReducer } from './alert.reducer';
import { hashtagsReducer } from './hashtags.reducer';
import { macroReducer } from './macro.reducer';
import { mlLabelReducer } from './ml-label.reducer';

const reducers = combineReducers({
    Auth,
    authenticationReducer,
    profileReducer,
    moderationReducer,
    chatReducer,
    chatonlyReducer,
    alertReducer,
    hashtagsReducer,
    macroReducer,
    mlLabelReducer
});

export default reducers;
