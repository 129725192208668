import React, {useState} from 'react';
import {Layout, message, Spin} from "antd";
import {Col, Row,Image} from "antd";
import {GoogleLogin} from 'react-google-login';

import {connect} from "react-redux";
import { navigate } from "@gatsbyjs/reach-router"

import {authenticationActions } from '../../appRedux/Actions';
import {storageService} from "../../appRedux/Storage";
import {storageConstants} from "../../appRedux/Constants/storage.constants";

function Login(props) {

    const [submitted, setSubmitted] = useState(false);
    const login = (response) => {
        //storageService.clearStore()
        if (response.tokenId) {
            storageService.saveToStore(storageConstants.SUPPORT_ACCOUNT, JSON.stringify(response.profileObj));
            storageService.saveToStore(storageConstants.GOOGLE_TOKEN_ID, response.tokenId);
            setSubmitted(false)

            props.login(response.tokenId)
        }
    }

    const onRequest = () => {
        setSubmitted(true)
    }

    const handleLoginFailure = (response) => {
        setSubmitted(false)
        if(response.error === "idpiframe_initialization_failed") {
            message.error(response.details).then(r => "")
        }
    }

    // https://zoejoyuliao.medium.com/add-google-sign-in-and-sign-out-to-your-react-app-and-get-the-accesstoken-2ee16bfd8297
    return (
        <Layout style={{background: "#585980"}}>

            <Row style={{height: '100vh'}}>
                <Col flex={"auto"} style={{height: '100vh'}}>
                    <Row>
                    </Row>
                </Col>
                <Col xs={20} sm={16} md={12} lg={8} className={"login-container"}>
                    <Row align={"center"}>
                        <div className={"login-holder"}>
                            <div className={"login-logo"}>
                            <Image src="https://hashtagopen.com/assets/images/nav-logo.png"/>
                            </div>
                            <div className={"login-button-holder"}>
                            {
                                submitted ? <Spin size="large"/> :
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        buttonText='Support Sign In'
                                        onSuccess={login}
                                        onRequest={onRequest}
                                        onFailure={handleLoginFailure}
                                        cookiePolicy={'single_host_origin'}
                                        responseType='code,token'/>
                            }
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col flex={"auto"} style={{height: '100vh'}}>
                </Col>
            </Row>
        </Layout>
    );
}

const actionCreators = {
    login: authenticationActions.login,
    logout: authenticationActions.logout
};

function mapStateToProps(state) {
    const { loggedIn, sessionData } = state.authenticationReducer;
    return { loggedIn, sessionData };
}


export default connect(mapStateToProps, actionCreators)(Login);
