import React from "react";
import _ from "lodash"
import {Redirect} from "@gatsbyjs/reach-router";
import {storageService} from "../appRedux/Storage";
import {chatActions, chatonlyActions} from "../appRedux/Actions";
import {useDispatch} from "react-redux";

function AppComponent(props) {
    let {component, layout, route, auth} = props;
    const {path} = route;
    const dispatcher = useDispatch()

    if(_.trim(route.path) !== "/") {
        if(storageService.requiredToLogin()) {
            return <Redirect from={path} to='/' noThrow />;
        } else {
            if(_.trim(route.path) !== "/chat") {
                console.log("App Component opening chat websocket")
                dispatcher(chatActions.loginToWebSocket())
            } else {
                console.log("App Component opening chat only websocket")
                dispatcher(chatonlyActions.loginToWebSocket())
            }
            if(_.trim(route.path) !== "/chat" && _.trim(route.path) !== "/chats" ) {
                // console.log("Starting chat server")
                // dispatcher(chatActions?.dispatchSubscribeToMessageCountsAction())
            }
            }
        }

    const data = {};
    component = (typeof layout != "undefined") ? layout(component, {data}) : component;

    return React.createElement(component, {
        path: path,
        props: props,
        ...props,
    });
}

export default AppComponent;