import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Form, Input, Button, Select, message, Popover, Tooltip, Typography} from 'antd';
import {macroActions} from "../../appRedux/Actions";
import 'antd/dist/antd.css';
import _ from "lodash";
import uuid from "react-uuid";
import {QuestionCircleOutlined, RightCircleOutlined} from "@ant-design/icons";

const {Text} = Typography;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24
    }
};

const validateMessages = {
    required: '${label} is required!'
};


function EditMacroForm(props) {

    const [form] = Form.useForm();

    const handleFinish = (values) => {
        console.log(values);
        if (!_.isEmpty(values)) {
            props.editMacro(props.eMacro.id, values);
            props.setType(values.type + ' Macros');
        }
        message.success('Success! Macro added.', 3);
        form.resetFields(['title', 'type', 'content']);
        props.setMenuKey(1);
    };

    const macroHelp = (<div className={"help-popover"}>
        <p>
            <Text strong>Report Macro Replacements</Text><br/>
            Some special tags are replaced with relevant values when sent <Text italic>in response to a
            report</Text>:<br/>
            <Text code>{'{reported_profile}'}</Text> is replaced with the name of reported profile<br/>
            <Text code>{'{reported_reason}'}</Text> is replaced with a description of the reason selected (e.x. "their
            profile content" on a report with the reason "Content")
        </p>
    </div>)

    return <>
        <Form
            form={form}
            {...layout}
            layout="vertical"
            className="macro-form"
            onFinish={handleFinish}
            scrollToFirstError
            validateMessages={validateMessages}
            initialValues={{
                title: props.eMacro.title,
                type: props.eMacro.type,
                content: props.eMacro.content
            }}>
            <Form.Item
                name="title"
                label="Title"
                wrapperCol={{span: 14}}

                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="type"
                label="Type"
                wrapperCol={{span: 8}}
                rules={[
                    {
                        required: true,
                    },
                ]}>
                <Select placeholder="Select Type ...">
                    <Select.Option value="Help">Help</Select.Option>
                    <Select.Option value="Bug">Bug</Select.Option>
                    <Select.Option value="Moderation">Moderation</Select.Option>
                    <Select.Option value="Outreach">Outreach</Select.Option>
                    <Select.Option value="Report">Report</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="content"
                label="Content"
                labelCol={{span: 24}}
                rules={[
                    {
                        required: true,
                    },
                ]}>
                <Input.TextArea autoSize={{minRows: 6}}/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={props.creatingMacroInProgress}>
                    Submit
                </Button>
                <Popover placement="topRight" title={"Macro help"} content={macroHelp} trigger="click">
                    <Tooltip placement={"bottom"} title={"Macro help"}>
                        <Button key={uuid()} icon={<QuestionCircleOutlined/>} style={{"marginLeft": "10px"}}></Button>
                    </Tooltip>
                </Popover>
            </Form.Item>
        </Form>
    </>
}

const actionCreators = {
    editMacro: macroActions.editMacro
};

function mapStateToProps(state) {
    const {fetching, updating} = state.macroReducer
    return {fetching, updating};
}

export default connect(mapStateToProps, actionCreators)(EditMacroForm);