import {authHeader} from "../Helpers";
import {config} from "../Config";
import {handleResponse} from "./authentication.service";

export const hashtagService = {
    searchHashtag,
    updateBanState
}

/**
 *
 banned	<bool>	Optional. If included, only tags with the provided banned state will be returned.One of banned and hashtag must be included.
 hashtag	<string>	Optional. If included, only tags matching the provided string will be returned.One of banned and hashtag must be included.
 partial	<bool>	Optional. Should hashtag be exact matched (case insensitively), or as a partial match (with the string appearing anywhere in the tag).If hashtag is provided and partial is not, matching is exact.Does nothing if hashtag is not provided.
 include_preferences	<bool>	Required. Should preference tags be returned?At least one of include_preferences and include_boundaries must be true.
 include_boundaries	<bool>	Required. Should boundary tags be returned?At least one of include_preferences and include_boundaries must be true.
 * @searchOptions {
 *  banned
 *  partial
 *  hashtag
 *  includePreferences
 *  includeBoundaries
 * * }

 * @returns {Promise<*>}
 */
function searchHashtag(searchOptions) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(searchOptions)
    };
    return fetch(`${config.baseURL}/admin/hashtags`, requestOptions).then(handleResponse);
}

function updateBanState(params) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/hashtags/ban`, requestOptions).then(handleResponse);
}