import {ProfileTableRenderer} from "../../components";
import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {profileActions} from "../../appRedux/Actions";
import {Button} from "antd";
import {connect} from "react-redux";

function SearchResult(props) {

    let searchProfileResult = useSelector(state => state.profileReducer.searchProfileResult) ?? []
    const reportedProfileSelected = (profileId) => {
    }

    const profileDispatch = useDispatch();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const username = urlParams.get('username')
    const facebook = urlParams.get('fb')
    const appsflyer = urlParams.get('af')
    const subscribers = urlParams.get('sub')
    const fmx = urlParams.get('fmx')

    useEffect(() => {
        if(urlParams.has('username')){
            profileDispatch(profileActions.searchProfileAction(
                urlParams.get('username') ?? "",
                true,
                true,
                urlParams.get('fmx') ?? "",
                urlParams.get('sub') ?? false,
                urlParams.get('fb') ?? false,
                urlParams.get('af') ?? false
            ));
        }
    }, []);

    const getMoreProfiles = () => {
        let idArray = [];
        props.searchProfileResult.map((searchProf) => {
            idArray.push(searchProf.id);
        });
        props.searchMore(props.searchedText, idArray, true, true, fmx, subscribers, facebook, appsflyer);
    }

    return <>

        <ProfileTableRenderer profiles={searchProfileResult}
                                     fetched={true}
                                     profileSelectedAction={reportedProfileSelected}
                                     enableRowSelection={false}/>
                                     <br/>
                                     { props.searchResultCount > searchProfileResult.length ? <Button onClick={getMoreProfiles}>More</Button> : null }
    </>
}

const actionCreators = {
    searchMore: profileActions.searchProfileActionMore
};

function mapStateToProps(state) {
    const { searchProfileResult, searchedText, searchResultCount } = state.profileReducer
    return { searchProfileResult, searchedText, searchResultCount };
}

export default connect(mapStateToProps, actionCreators)(SearchResult);