import React, {useState, useEffect} from 'react';
import {message} from "antd";
import {ProfileTableRenderer} from "../../components";
import {profileActions, chatActions, macroActions} from "../../appRedux/Actions";
import {connect} from "react-redux";

function Reviewed(props) {

    useEffect(() => {
        props.getReviewedProfiles()
    }, [])

    function showProgress(status, content, key) {
        if(status){
            message.loading({ content, key }).then(r => "")
        } else {
            message.destroy(key)
        }
    }

    const profileSelected = (profileId) => {
        props.setProfilePhotosAsReviewed(profileId)
    }

    return (
        <>
            <ProfileTableRenderer profiles={props.reviewedProfiles}
                                  fetched={props.reviewedProfilesFetched}
                                  profileSelectedAction={profileSelected}
                                  enableRowSelection={true}/>
        </>
    );
}

const actionCreators = {
    getReviewedProfiles: profileActions.getReviewedProfilesFromServerAction,
    setProfilePhotosAsReviewed: profileActions.markProfilePhotoAsReviewed,
    authenticateWebSocket: chatActions.loginToWebSocket,
    getAllMacros: macroActions.getAllMacros
};

function mapStateToProps(state) {
    const {reviewedProfilesFetched, deletingProfilePhoto, markingProfileAsReviewed, reviewedProfiles } = state.profileReducer
    return {reviewedProfilesFetched, deletingProfilePhoto, markingProfileAsReviewed, reviewedProfiles };
}

export default connect(mapStateToProps, actionCreators)(Reviewed);
