import React, {useEffect} from 'react';
import {message} from "antd";
import {ProfileTableRenderer} from "../../components";
import {NewTableRenderer} from "../../components";
import {chatActions, moderationActions, profileActions} from "../../appRedux/Actions";
import {connect} from "react-redux";


function NewProfile(props) {

    useEffect(() => {
        props.getNewProfiles()
        props.loadRooms(false)
        props.getModerationTags()
    }, [])

    showProgress(props.markingNewProfileAsReviewed, 'Marking new profile as reviewed', 'markingNewProfileAsReviewed')

    function showProgress(status, content, key) {
        if(status){
            message.loading({ content, key }).then(r => "")
        } else {
            message.destroy(key)
        }
    }

    const profileSelected = (profileId) => {
        props.markNewProfileAsReViewed(profileId)
    }

    return (
        <>
            <ProfileTableRenderer profiles={props.newProfiles}
                                         fetched={props.newProfilesFetched}
                                         profileSelectedAction={profileSelected}
                                         enableRowSelection={true}/>
        </>
    );
}

const actionCreators = {
    getNewProfiles: profileActions.getNewProfilesFromServerAction,
    markNewProfileAsReViewed: profileActions.markNewProfileAsReviewedAction,
    loadRooms: chatActions.fetchAllRooms,
    getModerationTags: moderationActions.getModerationTagAction
};

function mapStateToProps(state) {
    const { newProfiles, newProfilesFetched, markingNewProfileAsReviewed } = state.profileReducer
    return { newProfiles, newProfilesFetched, markingNewProfileAsReviewed };
}

export default connect(mapStateToProps, actionCreators)(NewProfile);
