import React, {useState, useEffect} from 'react';
import {message} from "antd";
import {ProfileTableRenderer} from "../../components";
import {profileActions, chatActions, macroActions} from "../../appRedux/Actions";
import {connect} from "react-redux";

function Unreviewed(props) {

    function showProgress(status, content, key) {
        if(status){
            message.loading({ content, key }).then(r => "")
        } else {
            message.destroy(key)
        }
    }

    const profileSelected = (profileId) => {
        props.setProfilePhotosAsReviewed(profileId)
    }
    /**
     * @ref https://codesandbox.io/s/m4zv6yornj?file=/index.js:2330-2346
     */

    // console.log("props.profiles", props.profiles)

    return (
        <>
            <ProfileTableRenderer profiles={props.profiles}
                                         fetched={props.fetched}
                                         profileSelectedAction={profileSelected}
                                         enableRowSelection={true}/>
        </>
    );
}

const actionCreators = {
    getProfileWithUnreviewedPhoto: profileActions.getProfileWithUnreviewedPhotoAction,
    setProfilePhotosAsReviewed: profileActions.markProfilePhotoAsReviewed,
    authenticateWebSocket: chatActions.loginToWebSocket,
    getAllMacros: macroActions.getAllMacros
};

function mapStateToProps(state) {
    const { profiles, fetched, deletingProfilePhoto, markingProfileAsReviewed } = state.profileReducer
    return { profiles, fetched, deletingProfilePhoto, markingProfileAsReviewed };
}

export default connect(mapStateToProps, actionCreators)(Unreviewed);
