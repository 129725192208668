import React from 'react';
import {
    Image,
    Card,
    Divider,
    Typography,
} from "antd";
import pairedIcon from "../../assets/paired.png";
import openLogo from "../../assets/logo.svg";


function TabTech(props) {
    const {profile} = props
    const {Paragraph} = Typography;
    const {Meta} = Card;

    const solo =  <Image src={openLogo} alt={"soloIcon"} preview={false}/>
    const paired = <Image src={pairedIcon} alt={"partneredIcon"} className="table-icon" preview={false}/>

    const member1 = profile.members[0] ?? [];
    const member2 = profile.members[1] ?? [];

    return (
        <div style={{height: 474, overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none', width: "100%"}}>
            <Card className="member-premium-card">
            <Meta
                avatar={profile.members.length > 1 ? paired : solo}
                title={"@" + profile.username.value + " (" +  profile.id + ")"}
            />
                {member1.id ?
                    <Divider orientation="left" orientationMargin="0.5em">{member1.display_name + " (" + member1.id + ")"}</Divider>
                    : null}
                {member1.account ?
                    <Paragraph>
                        {member1.account.phone_number + " (" + member1.account.id + ")"}
                    </Paragraph>
                    : null}
                {member2.id ?
                    <Divider orientation="left" orientationMargin="0.5em">{member2.display_name + " (" + member2.id + ")"}</Divider>
                    : null}
                {member2.account ?
                    <Paragraph>
                        {member2.account.phone_number + " (" + member2.account.id + ")"}
                    </Paragraph>
                    : null}
            </Card>
            <br/>
        </div>
    );
}

export default TabTech;