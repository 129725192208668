import {authHeader} from '../Helpers';
import {config} from "../Config";
import {handleResponse} from "./authentication.service";

export const macroService = {
    createMacro,
    getAllMacros,
    editMacro,
    deleteMacro
};

function getAllMacros() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/macro`, requestOptions).then(handleResponse);
}

function createMacro(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/macro`, requestOptions).then(handleResponse);
}

function editMacro(macroIndex, params) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.baseURL}/admin/macro/${macroIndex}`, requestOptions).then(handleResponse);
}

function deleteMacro(macroIndex) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${config.baseURL}/admin/macro/${macroIndex}`, requestOptions).then(handleResponse);
}