import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Divider, Menu, Typography, Row, Col} from "antd";
import {
    PlusSquareOutlined,
    FlagOutlined,
    BugOutlined,
    MessageOutlined,
    ProfileOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons'
import Macro from "../../components/Macro";
import {macroActions} from "../../appRedux/Actions";
import AddMacroForm from "../../components/AddMacroForm";
import EditMacroForm from "../../components/EditMacroForm";
import "./Settings.css";

const {Title} = Typography;

function Settings(props) {

    const [type, setType] = useState('Help Macros');
    const [newMacro, setNewMacro] = useState({});
    const [menuKey, setMenuKey] = useState(1);
    const [fetched, setFetched] = useState(false);
    const [editMacro, setEditMacro] = useState({});

    const {macroList, getAllMacros, helpMacros, outreachMacros, moderationMacros, bugMacros, reportMacros} = props;

    useEffect(() => {
        getAllMacros();
    }, [getAllMacros]);

    const menuChoices = () => {
        if (type === 'Help Macros') {
            return helpMacros.map((m) => {
                return <Macro setType={setType} macro={m} setEditMacro={setEditMacro}/>
            });
        } else if (type === 'Bug Macros') {
            return bugMacros.map((m) => {
                return <Macro setType={setType} macro={m} setEditMacro={setEditMacro}/>
            });
        } else if (type === 'Moderation Macros') {
            return moderationMacros.map((m) => {
                return <Macro setType={setType} macro={m} setEditMacro={setEditMacro}/>
            });
        } else if (type === 'Outreach Macros') {
            return outreachMacros.map((m) => {
                return <Macro setType={setType} macro={m} setEditMacro={setEditMacro}/>
            });
        } else if (type === 'Report Macros') {
            return reportMacros.map((m) => {
                return <Macro setType={setType} macro={m} setEditMacro={setEditMacro}/>
            });
        } else if (type === 'Add New Macro') {
            return <AddMacroForm setType={setType} setMenuKey={setMenuKey} setNewMacro={setNewMacro}/>
        } else if (type === 'Edit Macro') {
            return <EditMacroForm eMacro={editMacro} setType={setType}/>
        }
    }

    return <div className="settings">
        <Divider orientation="left" className={"divider-header"} orientationMargin={8}>Settings</Divider>
        <Row wrap={false}>
            <Col flex={"200px"}>
                <Menu
                    className="menu"
                    defaultSelectedKeys={['1']}
                    mode="inline"
                >
                    <Menu.ItemGroup
                        key="macros"
                        title="Macros"
                        className="menu-ig">
                        <Menu.Item key="1" icon={<QuestionCircleOutlined/>} onClick={() => setType('Help Macros')}>Help
                            Macros</Menu.Item>
                        <Menu.Item key="2" icon={<BugOutlined/>} onClick={() => setType('Bug Macros')}>Bug Macros</Menu.Item>
                        <Menu.Item key="3" icon={<ProfileOutlined/>} onClick={() => setType('Moderation Macros')}>Moderation
                            Macros</Menu.Item>
                        <Menu.Item key="4" icon={<MessageOutlined/>} onClick={() => setType('Outreach Macros')}>Outreach
                            Macros</Menu.Item>
                        <Menu.Item key="5" icon={<FlagOutlined/>} onClick={() => setType('Report Macros')}>Report
                            Macros</Menu.Item>
                        <Menu.Item key="6" icon={<PlusSquareOutlined/>} onClick={() => setType('Add New Macro')}>Add New
                            Macro</Menu.Item>
                    </Menu.ItemGroup>
                </Menu>
            </Col>
            <Col flex={"auto"}>        <div className="macro-list">
                <p>{type}</p>
                {menuChoices()}
            </div></Col>
        </Row>
    </div>
}

const actionCreators = {
    getAllMacros: macroActions.getAllMacros
};

function mapStateToProps(state) {
    const {
        macroList,
        helpMacros,
        outreachMacros,
        moderationMacros,
        bugMacros,
        reportMacros,
        macro,
        creatingMacroInProgress,
        fetching,
        updating
    } = state.macroReducer
    return {
        macroList,
        helpMacros,
        outreachMacros,
        moderationMacros,
        bugMacros,
        reportMacros,
        macro,
        creatingMacroInProgress,
        fetching,
        updating
    };
}

export default connect(mapStateToProps, actionCreators)(Settings);