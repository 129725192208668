import {chatConstants} from '../Constants';
import moment from "moment";

const INIT_STATE = {
    deletedRooms: [],
    updatedRooms: [],
    roomMessages: new Map(),
    errorFetchingRooms: false,
    sendingMessageError: "",
    chatServerWorking: false,
    recentlyCreatedChatRoom: 0,
}

export function chatReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case chatConstants.FETCH_ROOM_REQUEST:
            return {
                ...state
            }
        case chatConstants.CHAT_SERVER_STATUS:
            return {
                ...state,
                chatServerWorking: action.status
            }
        case chatConstants.FETCH_ROOM_SUCCESSFUL:
            return {
                ...state,
                deletedRooms: action.deletedRooms,
                updatedRooms: action.updatedRooms
            }
        case chatConstants.ROOM_CHANGE_SUCCESSFUL:
            const updatedRoom = action.updatedRoom

            let cachedRooms = [ ...state.updatedRooms ] ?? []

            if(!cachedRooms.map(room => room._id).includes(updatedRoom._id)) {
                cachedRooms.push(updatedRoom)
                console.log("RoomChangedInCache", "Added")
            } else {
                for (const index in cachedRooms) {
                    if(updatedRoom._id === cachedRooms[index]._id) {
                        cachedRooms[index] = updatedRoom
                        console.log("RoomChangedInCache", "YES")
                    }
                }
            }

            return {
                ...state,
                updatedRooms: cachedRooms
            }
        case chatConstants.FETCH_ROOM_MESSAGE_REQUEST:
            return {
                ...state
            }
        case chatConstants.SEND_CHAT_MESSAGE_SUCCESSFUL:

            const {message, roomId} = action

            console.log("SEND_CHAT_MESSAGE_SUCCESSFUL")
            console.log(message)
            console.log(roomId)

            const newMessagesMap = new Map(state.roomMessages);

            let roomMessages = state.roomMessages.get(roomId) ?? []

            console.log("roomMessages")
            console.log(roomMessages)

            roomMessages = roomMessages.filter((oldMessage) => oldMessage._id !== message._id)

            console.log("roomMessages.filter")
            console.log(roomMessages)

            roomMessages.push(message)

            console.log("roomMessages.push")
            console.log(roomMessages)
            roomMessages.sort(function(a, b) { return moment.utc(a.ts).local().unix() - moment.utc(b.ts).local().unix()})

            console.log("roomMessages.sort")
            console.log(roomMessages)
            newMessagesMap.set(roomId, roomMessages)

            return {
                ...state,
                roomMessages: newMessagesMap,
            }
        case chatConstants.CREATE_CHAT_SUCCESSFUL:

            const recentlyCreatedChatRoom = action.roomId

            return {
                ...state,
                recentlyCreatedChatRoom: recentlyCreatedChatRoom,
            }
        case chatConstants.NEW_CHAT_MESSAGE_RECEIVED:

            const {newMessage, messageRoomId} = action
            let newRoomMessage = state.roomMessages.get(messageRoomId) ?? []

            newRoomMessage = newRoomMessage.filter((message) => message._id !== newMessage._id)

            newMessage.ts = moment.unix(newMessage.ts.$date / 1000).utc().local().toISOString()
            newRoomMessage.push(newMessage)
            newRoomMessage.sort(function(a, b) { return moment.utc(a.ts).local().unix() - moment.utc(b.ts).local().unix()})

            const updatedMessagesMap = new Map();
            updatedMessagesMap.set(messageRoomId, newRoomMessage)

            return {
                ...state,
                roomMessages: updatedMessagesMap,
            }
        case chatConstants.SEND_CHAT_MESSAGE_ERROR:
            return {
                ...state,
                sendingMessageError: action.error.error
            }
        case chatConstants.FETCH_ROOM_MESSAGE_SUCCESSFUL:
            const messages = action.messages

            messages.sort(function(a, b) { return moment.utc(a.ts).local().unix() - moment.utc(b.ts).local().unix()})

            const newMessages = new Map();
            newMessages.set(action.roomId, messages)
            return {
                ...state,
                roomMessages: newMessages,
            }
        case chatConstants.FETCH_ROOM_ERROR:
            return {
                ...state,
                errorFetchingRooms: true,
            }
        case chatConstants.CLEAR_CREATED_ROOM:
            return {
                ...state,
                recentlyCreatedChatRoom: 0,
            }
        default:
            return state
    }
}