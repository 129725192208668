import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Select, Input, Button, Form, Cascader} from "antd";
import {
    alertActions,
    chatActions,
    macroActions,
    createNewRoomAndSendChat,
    streamRoomMessages
} from "../../appRedux/Actions";
import {connect, useDispatch, useSelector} from "react-redux";
import _, {values} from "lodash";
import moment from "moment";
import supportOptions from '../../data/chat-data.json'
import {uuid} from "uuidv4";
import {Image} from 'antd';
import {config} from "../../appRedux/Config";
import {chatConstants} from "../../appRedux/Constants";

const {Option} = Select;
const {TextArea} = Input;

function Chat(props) {

    let [messageList, setMessageList] = useState([])
    const {profile} = props
    const [form] = Form.useForm();
    let roomId = "NO_ROOM"
    const updatedRooms = props.updatedRooms ?? [];
    const roomName = `_support_${profile.id}_`
    const roomName1 = `-support-${profile.id}-`
    const chatDispatch = useDispatch()
    const myRoom = updatedRooms.filter(item => (item.name === roomName || item.name === roomName1));
    roomId = myRoom[0]?._id ?? "NO_ROOM"
    const roomChanges = props.roomMessages.get(roomId)
    let messages = [...(props.roomMessages.get(roomId) ?? [])]

    useEffect(() => {
        if (isValidRoom(roomId)) {
            scrollChatToBottom()
        }
    }, [])

    useEffect(scrollChatToBottom, [messages]);
    useEffect(() => {
        setMessageList([...(props.roomMessages.get(roomId) ?? [])])
    }, [roomChanges]);

    function scrollChatToBottom() {
        if (document.getElementById(roomId) !== null) {
            document.getElementById(roomId).scrollTop = document.getElementById(roomId)?.scrollHeight ?? 0
        }
    }

    const markMessageAsRead = () => {
        if (isValidRoom(roomId)) {
            props.markMessagesAsRead(profile, roomId)
        }
    }

    function isValidRoom(roomId) {
        return (roomId !== "NO_ROOM" && roomId.length > 7)
    }

    const onSubmitChat = (values) => {
        if (!_.isEmpty(values.chatMessage)) {
            console.log(`onSubmitChatUpdated`)
            console.log(`roomId`, roomId)

            if (isValidRoom(roomId)) {
                console.log(`RoomId ${roomId} exist Sending chat`)
                const message = values.chatMessage
                props.postChatMessage(roomId, message)
            } else {
                createNewRoomAndSendChat(profile, values.chatMessage)
                    .then((response) => {
                        console.log("New Message Response")
                        console.log(response)
                        roomId = response.roomId
                        chatDispatch(successNew(response.message, roomId))
                        console.log("setMessageList")
                        setMessageList([response.message])
                    }).catch((error) => {
                    chatDispatch(alertActions.error(error.toString()))
                })
            }

            form.resetFields(['chatMessage'])
        }
    }

    function successNew(message, roomId) {
        return {type: chatConstants.SEND_CHAT_MESSAGE_SUCCESSFUL, message, roomId}
    }

    function onSupportOptionChanged(value) {
        let currentVal = document.getElementById('chatMessage').value;
        if (currentVal === "" || currentVal === " ") {
            form.setFieldsValue({
                'chatMessage': value[1]
            });
        } else if (currentVal !== "" || currentVal !== " ") {
            form.setFieldsValue({
                'chatMessage': document.getElementById('chatMessage').value + "\n\n" + value[1]
            });
        }

        form.resetFields(['macrosCascader'])

        form.resetFields(props.helpMacros.map(option => option.title));
        form.resetFields(props.bugMacros.map(option => option.title));
        form.resetFields(props.moderationMacros.map(option => option.title));
        form.resetFields(props.outreachMacros.map(option => option.title));
        form.resetFields(props.reportMacros.map(option => option.title));
    }

    function getCascaderOptions() {
        return [
            {
                "value": "Help",
                "label": "Help",
                "children":
                    props.helpMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Bug",
                "label": "Bug",
                "children":
                    props.bugMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Moderation",
                "label": "Moderation",
                "children":
                    props.moderationMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Report",
                "label": "Report",
                "children":
                    props.reportMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            },
            {
                "value": "Outreach",
                "label": "Outreach",
                "children":
                    props.outreachMacros.map((selectOption) => (
                        {"value": selectOption.content, "label": selectOption.title}))
            }]
    }

    function checkCascadeFilter(inputValue, path, option) {
        console.log(inputValue)
        console.log(option)
        return true
    }

    const macroFilter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)

    const cascader_options = getCascaderOptions()

    console.log("Rendering...", messageList.length)
    return (
        <div style={{height: "100%", padding: "4px", backgroundColor: "#fff"}}>
            <div className={"c-h"} style={{height: 458}}>
                <div className={"c-h-c gx-chat-main-content"} id={roomId}>
                    {
                        messageList?.map((message, index) => {
                            const className = process.env.REACT_APP_CHAT_ID === message.u._id ? "gx-chat-item gx-flex-row-reverse" : "gx-chat-item"
                            return <div key={uuid()} className={className}>
                                <div className="gx-bubble-block">
                                    <div className="gx-bubble">
                                        {message.attachments && message.attachments?.length > 0 &&
                                            <div className="gx-message">
                                                <Image
                                                    width={200}
                                                    src={config.chatBaseUrl + message.attachments[0].title_link}
                                                />
                                            </div>}
                                        {message.msg && <div className="gx-message">{message.msg}</div>}

                                    </div>
                                    <div className="gx-name">{(message.u?.username == 'openteam' || message.u?.username == 'openadmin') ? '' : message.u?.name}</div>
                                    <div className={"gx-time gx-text-muted" + ((message.u?.username == 'openteam' || message.u?.username == 'openadmin') ? ' gx-text-right' : '')}>{moment.utc(message.ts).local().fromNow()}</div>
                                </div>
                            </div>
                        })
                    }

                </div>
                <div className={'over-c'}>
                    <Form
                        form={form}
                        onFinish={onSubmitChat}
                        initialValues={{
                            chatMessage: ''
                        }}>
                        <Row className={'dd'}>
                            <Col span={24}>
                                <Form.Item name="macrosCascader" style={{marginBottom: '6px'}}>
                                    <Cascader options={cascader_options} onChange={onSupportOptionChanged} showSearch={{
                                        filter: (inputValue, path) => {
                                            return (path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1))
                                        }
                                    }} placeholder="Select Macro"/>
                                </Form.Item>
                            </Col>

                        </Row>
                        <div>
                            <Form.Item name='chatMessage' style={{marginBottom: '6px'}}>
                                <TextArea rows={3} value={""}/>
                            </Form.Item>
                            <Button type="primary" htmlType="button" onClick={markMessageAsRead}>Mark Messages as
                                Read</Button>
                            <Button type="primary" style={{float: "right"}} htmlType="submit">Send</Button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );


}

const actionCreators = {
    startGetRoomMessages: chatActions.getRoomMessages,
    postChatMessage: chatActions.sendChatMessage,
    createChat: chatActions.createChatAction,
    markMessagesAsRead: chatActions.markAllRoomMessageAsReadAction
};

function mapStateToProps(state) {
    const {updatedRooms, deletedRooms, roomMessages} = state.chatReducer;
    const {helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros} = state.macroReducer;
    return {updatedRooms, deletedRooms, roomMessages, helpMacros, bugMacros, moderationMacros, outreachMacros, reportMacros};
}

export default connect(mapStateToProps, actionCreators)(Chat);
