import {FETCH_AUTH_FAILED, FETCH_AUTH_START, FETCH_AUTH_END} from '../Constants';

const INIT_STATE = {
    loading: false,
    auth: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_AUTH_START:
        case FETCH_AUTH_FAILED: {
            return {...state, loading: true};
        }
        case FETCH_AUTH_END: {
            return {...state, loading: false, auth: state.payload};
        }
        default:
            return state;
    }
};
