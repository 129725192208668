import {useSelector} from "react-redux";
import {Button, Tooltip} from "antd";
import {navigateTo, redirectToLogin} from "../../route/Redirect";
import {storageService} from "../../appRedux/Storage";
import 'antd/dist/antd.css';
import {SettingOutlined, LogoutOutlined, MessageTwoTone, MessageFilled} from "@ant-design/icons";

function ChatServerInfo(props) {
    // const chatDispatch = useDispatch()
    const chatServerWorking = useSelector(state => state.chatReducer.chatServerWorking)

    const logUserOut = () => {
        storageService.clearStore()
        redirectToLogin().then(r => "")
    }

    const openSettings = () => {
        navigateTo('/settings');
    }

    return <>

        {chatServerWorking && <Tooltip title={"Chat server connected"} color={"#307c41"} placement={"leftBottom"}>
            <Button disabled={false}
                    icon={<MessageFilled
                        style={{color: "#77e78e"}}/>}
                    style={{backgroundColor: "#307c41"}}></Button></Tooltip>}
        {!chatServerWorking && <Tooltip title={"Chat server not connected"} color={"#7c3a3a"} placement={"leftBottom"}>
            <Button danger icon={<MessageFilled style={{color: "#f3b3b3"}} disabled={false}/>}
                    style={{backgroundColor: "#7c3a3a"}}>
            </Button></Tooltip>}

        <Tooltip placement={"leftBottom"} title={"Settings"}>
            <Button type="primary" icon={<SettingOutlined/>} disabled={false} onClick={openSettings}></Button>
        </Tooltip>
        <Tooltip placement={"leftBottom"} title={"Log out"}>
            <Button type="primary" icon={<LogoutOutlined/>} onClick={logUserOut}
                    disabled={false}></Button>
        </Tooltip>
    </>
}

export default ChatServerInfo