import {useState} from "react";
import {Card, Col, Row, Tabs, Form} from "antd";
import {connect, useDispatch} from "react-redux";
import {profileActions, macroActions, moderationActions, streamRoomMessages, chatActions} from "../../appRedux/Actions";
import {
    ProfileConnect,
    ProfilePhotos,
    TabProfile,
    TabBoundaries,
    TabPreferences,
    ProfileModeration,
    ProfileReports,
    Notes
} from "../index";
import {Divider} from 'antd';
import ProfileMembers from "../ProfileMembers";
import uuid from "react-uuid";
import React, {useEffect} from "react";
import Chat from "../Chat/Chat";
import _, {capitalize} from "lodash";

const {TabPane} = Tabs;


function TableRender(props) {
    const {profile, getNotes, getExpandedProfile, expandedProfile, getModerations, currentPhoto} = props;

    useEffect(() => {
        getExpandedProfile(profile.id);
        getModerations(expandedProfile);
        getNotes(expandedProfile);
    }, []);

    const member1Location = () => {
        if (!_.isEmpty(expandedProfile.members[0]?.location_text) && !_.isNull(expandedProfile.members[0]?.location_text)) {
            let loc = expandedProfile.members[0]?.location_text?.locality === "" || expandedProfile.members[0]?.location_text?.locality === "Unknown" || expandedProfile.members[0]?.location_text?.locality === null ? "??" : expandedProfile.members[0]?.location_text.locality;
            let ter = expandedProfile.members[0]?.location_text?.territory === "" || expandedProfile.members[0]?.location_text?.territory === "Unknown" || expandedProfile.members[0]?.location_text?.territory === null ? "??" : expandedProfile.members[0]?.location_text.territory;
            let coun = expandedProfile.members[0]?.location_text?.country === "" || expandedProfile.members[0]?.location_text?.country === "Unknown" || expandedProfile.members[0]?.location_text?.country === null ? "??" : expandedProfile.members[0]?.location_text.country;
            return <span>
            📍 {loc}, {ter} ({coun})
            </span>
        } else {
            return <span>??</span>
        }
    }

    const member2Location = () => {
        if (!_.isEmpty(expandedProfile.members[1]?.location_text) && !_.isNull(expandedProfile.members[1]?.location_text) && expandedProfile.members.length > 1) {
            let loc = expandedProfile.members[1]?.location_text?.locality === "" || expandedProfile.members[1]?.location_text?.locality === "Unknown" || expandedProfile.members[1]?.location_text?.locality === null ? "??" : expandedProfile.members[1]?.location_text.locality;
            let ter = _.isNull(expandedProfile.members[1]?.location_text?.territory) ? "??" : expandedProfile.members[1]?.location_text.territory;
            let coun = _.isNull(expandedProfile.members[1]?.location_text?.country) || expandedProfile.members[1]?.location_text?.country ? "??" : expandedProfile.members[1]?.location_text.country;
            return <span>
            📍 {loc}, {ter} ({coun})
            </span>
        } else {
            return <span>??</span>
        }
    }

    return (
            <div key={expandedProfile.id} style={{backgroundColor: '#FFFFFF', marginLeft: "0px"}}>
                <ProfileMembers profile={profile} expandedProfile={expandedProfile}/>
                <Divider/>
                <Row gutter={4}>
                    <Col span={4}>
                        <Card>
                            <h6>@{profile.username.value}</h6>
                            <small>{member1Location()} {expandedProfile.members.length > 1 ? "| " : null} {expandedProfile.members.length > 1 ? member2Location() : null}</small>
                            <small>{expandedProfile.members.map((account) => {
                                if (account.travelling) {
                                    return <><br />✈ {account.travel_locations[0].location_text}</>
                                }
                            })}</small>
                            <Row>
                                <ProfilePhotos bigPhoto={props.bigPhoto} currentPhoto={currentPhoto} key={uuid()} profile={profile}
                                               expandedProfile={expandedProfile}/>
                            </Row>
                        </Card>
                    </Col>
                    <Col key={"tabProfile"} span={4}>
                        <Card style={{height: "100%"}}>
                            <TabProfile profile={profile} expandedProfile={expandedProfile}/>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card style={{height: "100%"}}>
                            <TabPreferences profile={profile} expandedProfile={expandedProfile}/>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card style={{height: "100%"}} className={"inner-card"}>
                            <TabBoundaries profile={profile} expandedProfile={expandedProfile}/>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <ProfileConnect profile={expandedProfile}/>
                    </Col>
                </Row>
            </div>
    );
}

const actionCreators = {
    getNotes: profileActions.getNotesFromProfileAction,
    getExpandedProfile: profileActions.getExpandedProfile,
    getModerations: moderationActions.getModerationOnProfile
}

function mapStateToProps(state) {
    const {creatingNoteInProgress, expandedProfile} = state.profileReducer
    return {creatingNoteInProgress, expandedProfile}
}

export default connect(mapStateToProps, actionCreators)(TableRender);
